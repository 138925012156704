import { Navigate, Outlet, useLocation } from "react-router-dom";

const GuestRoute = (props: any) => {
  const auth = !!localStorage.getItem("ekam-user")
    ? JSON.parse(localStorage.getItem("ekam-user") || "")
    : null;
  const token = !!localStorage.getItem("ekam-token")
    ? JSON.parse(localStorage.getItem("ekam-user") || "")
    : null;
  let location = useLocation();

  return props?.roles?.includes(auth?.role) && !!token ? (
    <Navigate
      to={auth?.role === "candidate" ? `/candidate/home` : `/employer/home`}
      state={{ from: location }}
      replace
    />
  ) : (
    <Outlet />
  );
};

export default GuestRoute;
