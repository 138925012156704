import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";

interface IButton {
  size: "small" | "medium" | "large";
  label?: string;
  icon?: any;
  type?: "submit" | "button" | "reset";
  handleClick?: () => void;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isBold?: boolean;
  isInverse?: boolean;
  brandColor?: string;
}

export function ContainedButton({
  size,
  label,
  type,
  handleClick,
  isDisabled = false,
  isFullWidth = false,
  brandColor,
}: IButton) {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      color="primary"
      size={size}
      disabled={isDisabled}
      type={type ? type : "button"}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
      sx={{
        width: isFullWidth ? "100%" : "auto",
        backgroundColor: brandColor
          ? brandColor
          : theme?.palette?.primary?.buttonOrange,
        padding:
          size === "small"
            ? ".5rem 1.5rem .5rem 1.5rem"
            : ".75rem 1.5rem .75rem 1.5rem",
      }}
    >
      {label}
    </Button>
  );
}

export function OutlinedButton({
  size,
  label,
  type,
  handleClick,
  isDisabled = false,
  isFullWidth = false,
  isBold = false,
  isInverse = false,
  brandColor,
}: IButton) {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      color="primary"
      size={size}
      disabled={isDisabled}
      type={type ? type : "button"}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
      sx={{
        width: isFullWidth ? "100%" : "auto",
        backgroundColor: "transparent !important",
        borderWidth: type === "reset" ? "1px" : "2px",
        borderColor:
          type === "reset"
            ? "rgba(0, 0, 0, 0.12)"
            : brandColor
            ? brandColor
            : theme?.palette?.primary?.buttonOrange,
        padding:
          size === "small"
            ? ".5rem 1.5rem .5rem 1.5rem"
            : ".75rem 1.5rem .75rem 1.5rem",
        fontWeight: isBold ? "600" : "400",
        color: isInverse
          ? theme?.palette?.primary?.textInverse
          : theme?.palette?.primary?.textBtn,
        "&:hover": {
          borderWidth: type === "reset" ? "1px" : "2px",
          borderColor:
            type === "reset"
              ? "rgba(0, 0, 0, 0.12)"
              : brandColor
              ? brandColor
              : theme?.palette?.primary?.buttonOrange,
        },
      }}
    >
      {label}
    </Button>
  );
}

export function OutlinedIconButton({
  size,
  icon,
  type,
  handleClick,
  isDisabled = false,
  isFullWidth = false,
  isBold = false,
  label,
  brandColor,
}: IButton) {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      color="primary"
      size={size}
      disabled={isDisabled}
      type={type ? type : "button"}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
      sx={{
        width: isFullWidth ? "100%" : "auto",
        backgroundColor: "transparent !important",
        borderWidth: "2px",
        borderColor: brandColor
          ? brandColor
          : theme?.palette?.primary?.iconOrange,
        padding: size === "small" ? "8px 16px 8px 16px" : "12px 24px 12px 24px",
        fontWeight: isBold ? "600" : "400",
      }}
    >
      {icon}
      {label}
    </Button>
  );
}

export function TextButton({
  size,
  label,
  type,
  handleClick,
  isDisabled = false,
  isFullWidth = false,
  isBold = false,
}: IButton) {
  const theme = useTheme();
  return (
    <Button
      color="primary"
      size={size}
      disabled={isDisabled}
      type={type ? type : "button"}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
      sx={{
        width: isFullWidth ? "100%" : "auto",
        backgroundColor: "transparent rtant",
        borderWidth: "2px",
        color: theme?.palette?.primary?.iconOrange,
        borderColor: theme?.palette?.primary?.iconOrange,
        padding: size === "small" ? "8px 16px 8px 16px" : "12px 24px 12px 24px",
        fontWeight: isBold ? "600" : "400",
      }}
    >
      {label}
    </Button>
  );
}
