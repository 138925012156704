export interface Option {
  name: string;
  value: string;
}

export const EXPERIENCE_LEVEL: Option[] = [
  /*{
    name: "Less than 2 years",
    value: "0-2",
  },*/
  {
    name: "2 - 5 years",
    value: "2-5",
  },
  {
    name: "5 - 10 years",
    value: "5-10",
  },
  {
    name: "10 - 20 years",
    value: "10-20",
  },
  {
    name: "20 - 25 years",
    value: "20-25",
  },
  {
    name:"25+ years",
    value:"25"
  },
];

export const SALARY_RANGE: Option[] = [
  {
    name: "0-5 LPA",
    value: "0-500000",
  },
  {
    name: "5-10 LPA",
    value: "500000-1000000",
  },
  {
    name: "10-20 LPA",
    value: "1000000-2000000",
  },
  {
    name: "20-40 LPA",
    value: "2000000-4000000",
  },
  {
    name: "40+ LPA",
    value: "4000000",
  },
];

export const COMPANY_SIZE: Option[] = [
  {
    name: "100",
    value: "100",
  },
  {
    name: "200",
    value: "200",
  },
  {
    name: "300",
    value: "300",
  },
  {
    name: "400",
    value: "400",
  },
];
export const APPLICATION_STATUS: Option[] = [
  {
    name: "Applied",
    value: "applied",
  },
  {
    name: "Screened",
    value: "screened",
  },
  {
    name: "Interviewed",
    value: "interviewed",
  },
  {
    name: "Shortlisted",
    value: "shortlisted",
  },
  {
    name: "Hired",
    value: "hired",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
];

export const IS_FEATURED: Option[] = [
  {
    name: "Yes",
    value: "yes",
  },
  {
    name: "No",
    value: "no",
  },
];
