import { useState, useRef, useEffect, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/Transitions";

// assets
import { IconLogout, IconSettings, IconUser, IconHelp } from "@tabler/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import deleteAlert from "component/HOC/deleteAlert";
import { useAppSelector } from "store/store";
import { clearStore } from "utils/redux.utils";
import Assets from "helpers/assets.helper";
import { commonService } from "forms/helpers/common.service";
import { useAuth } from "routes/auth.provider";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationIcon from "asset/icons/notification.svg";
import AccountIcon from "asset/icons/account_active.svg";
import ExperienceIcon from "asset/icons/experience-color.svg";
import ChevronDownIcon from "asset/icons/chevron-down.svg";
import { Logout } from "@mui/icons-material";
import { CandidateProfileMenu } from "layout/menu-items/candidate-menu";
import { EmployerProfileMenu } from "layout/menu-items/employer-menu";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";

import MessageIcon from "asset/icons/messaging.svg";
import JobAppliedIcon from "asset/icons/experience-color.svg";
import PaidIcon from "@mui/icons-material/Paid";
import ApplicationStatusIcon from "asset/icons/application-status.svg";
import Feedback from "component/feedback";

// ==============================|| PROFILE MENU ||====================== ======== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state: any) => state.customization);
  const user = useSelector((state: any) => state.user?.user);
 
  const navigate = useNavigate();
  const userDetail = useAppSelector((state) => state.user.user);
  const popupItems = useAppSelector((state) => state.user.popupItems);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useAppSelector((state) => state.user.user);
  const [profileMenu, setProfileMenu] = useState<any>([]);
  const [modalopen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState<any>([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  let path: any;
  let routePath: any;
  if (pathSegments[0] === "profile_details") {
    path = pathSegments[0];
  } else if (pathSegments[2] === "verification") {
    routePath = pathSegments[2];
  } else if (pathSegments[2] === "mobile_user_verification") {
    routePath = pathSegments[2];
  }

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  const logout = async () => {
    if (auth.role === "candidate") {
      clearStore();
      localStorage.clear();
      sessionStorage.clear();
      navigate("/candidate/login");
    } else {
      clearStore();
      localStorage.clear();
      sessionStorage.clear();
      navigate("/employer/login");
    }
  };

  const handleLogout = async () => {
  ;
    logout();
  };

  /* handle change password  */
  const handleChangePassword = async () => {
    try {
      navigate("/coming_soon");
    } catch (error) {
    
    }
  };

  /* handle my portal route */
  const handleProtalRoute = () => {
    try {
      window.open("https://ehub-dl2.blockchaincloudapps.com/login");
    } catch (error) {

    }
  };

  const changePassword = () => {
    navigate(`/${userDetail?.role}/resetpassword`);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const getProfile = async () => {
    setLoading(true);
    try {
      const profileReponse: any = await commonService.getServices(
        "/v2/profile/my_profile",
        false
      );
      if (!!profileReponse) {
        /* create user badges */
        setSelectedImage(
          `${process.env.REACT_APP_DC_URL}api/static${profileReponse?.data?.data?.profile_pic}`
        );
      }
    } catch (err) {
  
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!!user.profile_pic) {
      getProfile();
    }
    if (user.role === "candidate") {
      setProfileMenu(CandidateProfileMenu);
    }
    if (user.role === "employer") {
      setProfileMenu(EmployerProfileMenu);
    }
  
  }, [user]);



  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handlePathClick = (path: string) => {
    setOpen(false);
  
    navigate(path);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openProfileMenu = Boolean(anchorEl);

  const [anchorNotificationEl, setAnchorNotificationEl] =
    useState<null | HTMLElement>(null);
  const openNotificationMenu = Boolean(anchorNotificationEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (
    event:
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (auth?.id) {
      getNotifications();
      const interval = setInterval(
        () => {
          getNotifications();
        },
        process.env.REACT_APP_NOTIFICATION_INTERVAL
          ? parseInt(process.env.REACT_APP_NOTIFICATION_INTERVAL)
          : 60000
      );
      return () => clearInterval(interval);
    }
  }, []);

  const getNotifications = () => {
    commonService
      .getServices(
        `notification?page=1&per_page=40&sort_order=DESC&sort_by=created_at`
      )
      .then((response: any) => {
        setNotifications(response?.data?.data?.results);
        setNotificationCount(response?.data?.data?.notification_count);
      })
      .catch((error: any) => {
        Failed(errorMessage(error, "Something went wrong!"));
      });
  };

  const handleNotificationClick = (notification: any) => {
    setAnchorNotificationEl(null);
    if (notification?.status === "unread") {
      commonService
        .patchService(`notification/${notification?._id}`, {
          status: "read",
        })
        .then((response: any) => {
          getNotifications();
          if (notification?.action?.type === "chat") {
            navigate(`/${auth?.role}/chat`, {
              state: {
                userId: user?.id,
                otherUserId: notification?.action?.meta?.senderId,
                job_card: "job_card",
              
              },
            });
          }
          if (notification?.action?.type === "plan upgrade") {
            navigate(`/${auth?.role}/subscription-details`);
          }
          if (
            notification?.action?.type === "job applied" ||
            notification?.action?.type === "application status"
          ) {
            navigate(
              auth?.role === "employer"
                ? `/employer/my-jobs/${notification?.action?.action_id}`
                : "/candidate/applied-jobs"
            );
          }
        })
        .catch((error: any) => {
          Failed(errorMessage(error, "Something went wrong!"));
        });
    } else {
      if (notification?.action?.type === "chat") {
        navigate(`/${auth?.role}/chat`, {
          state: {
            userId: user?.id,
            otherUserId: notification?.action?.meta?.senderId,
            job_card: "job_card",
       
          },
        });
      }
      if (notification?.action?.type === "plan upgrade") {
        navigate(`/${auth?.role}/subscription-details`);
      }
      if (
        notification?.action?.type === "job applied" ||
        notification?.action?.type === "application status"
      ) {
        navigate(
          auth?.role === "employer"
            ? `/employer/my-jobs/${notification?.action?.action_id}`
            : "/candidate/applied-jobs"
        );
      }
    }
  };

  return (
    <>
      {Object.keys(auth).length > 0 ? (
        <Box display={"flex"} gap={{ xs: 1, sm: 2, md: 3 }}>
          <Badge
            badgeContent={notificationCount}
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                top: "8px !important",
              },
            }}
          >
            <IconButton
              id={"notifications-button"}
              aria-controls={open ? "notifications-list" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (notifications.length > 0) {
                  setAnchorNotificationEl(event.currentTarget);
                }
              }}
            >
              <img src={NotificationIcon} alt="notification" />
            </IconButton>
          </Badge>

          <Menu
            id="notifications-list"
            anchorEl={anchorNotificationEl}
            open={openNotificationMenu}
            onClose={() => {
              setAnchorNotificationEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "notifications-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "250px",
                maxWidth: "350px",
              },
            }}
          >
            <Typography variant="h4" p={2}>
              Notifications({notificationCount})
            </Typography>
            <Divider sx={{ mb: 1 }} />
            {notifications?.map((notification: any, index: number) => {
              return (
                <Box px={1}>
                  <MenuItem
                    onClick={() => {
                      handleNotificationClick(notification);
                    }}
                    sx={{
                      pl: "0px !important",
                      backgroundColor:
                        notification?.status === "unread"
                          ? "rgba(0, 0, 0, 0.04)"
                          : "transparent",
                      py: 1,
                      alignItems: "start",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                       
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {notification?.action?.type === "chat" && (
                        <Box
                          component={"img"}
                          src={MessageIcon}
                          alt={"Message"}
                          mr={1}
                        />
                      )}
                      {notification?.action?.type === "application status" && (
                        <Box
                          component={"img"}
                          src={ApplicationStatusIcon}
                          alt={"Message"}
                          mr={1}
                        />
                      )}

                      {notification?.action?.type === "job applied" && (
                        <Box
                          component={"img"}
                          src={JobAppliedIcon}
                          alt={"JobAppliedIcon"}
                        />
                      )}
                      {(notification?.action?.type === "plan upgrade" ||
                        notification?.action?.type === "plan downgrade") && (
                        <PaidIcon
                          sx={{ color: theme?.palette?.primary?.iconOrange }}
                        />
                      )}

                      {notification?.status === "unread" ? (
                        <Box
                          sx={{
                            height: "7px",
                            width: "7px",
                            backgroundColor: "#ff7e00",
                            borderRadius: "15px",
                          }}
                        ></Box>
                      ) : (
                        <Box
                          sx={{
                            height: "7px",
                            width: "7px",
                            backgroundColor: "transparent",
                            borderRadius: "15px",
                          }}
                        ></Box>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        textWrap: "wrap",
                        ".MuiTypography-root": {
                          fontSize: "12px !important",
                        },
                      }}
                    >
                      {notification?.text}
                    </ListItemText>
                  </MenuItem>
                  {index !== notifications?.length - 1 && (
                    <Divider sx={{ my: "0px !important" }} />
                  )}
                </Box>
              );
            })}
          </Menu>
          <Divider orientation="vertical" flexItem></Divider>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              onClick={handleClick}
              id={"profile-button"}
              aria-controls={open ? "profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <img src={AccountIcon} alt="account" />
            </IconButton>
            <Box
              component={"img"}
              src={ChevronDownIcon}
              sx={{ cursor: "pointer" }}
              alt={"Chevron down"}
              onClick={handleClick}
            />
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={openProfileMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "profile-button",
              }}
            >
              {profileMenu.map((item: any) => {
                return (
                  <>
                    {(item.id !== "my-plans" ||
                      (item.id === "my-plans" && auth?.is_primary)) && (
                      <MenuItem
                        onClick={($e: any) => {
                          handleClose($e);
                          if (item?.id === "feedback") {
                            setFeedbackOpen(true);
                          } else {
                            if (item?.id === "logout") {
                              handleLogout();
                            }

                            setTimeout(() => {
                              navigate(item?.url);
                            }, 300);
                          }
                        }}
                        sx={{ mb: { sm: 2 } }}
                      >
                        <ListItemIcon>
                          <img
                            height={20}
                            width={20}
                            src={item?.icon}
                            alt="account"
                          />
                        </ListItemIcon>
                        <ListItemText>{item?.title}</ListItemText>
                      </MenuItem>
                    )}

                    {item?.hasDivider && <Divider />}
                  </>
                );
              })}
            </Menu>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Candidate
            </Typography>
            <Button
              variant="outlined"
              sx={{
                background: "transparent",
                padding: "8px 24px",
                border: `2px solid ${theme.palette.primary.buttonOrange}`,
                "&:hover": {
                  borderWidth: "2px",
                },
              }}
              onClick={() => navigate(`/candidate/register`)}
            >
              <Typography sx={{ fontWeight: 600, fontSize: ".875rem" }}>
                Login/Register
              </Typography>
            </Button>
          </Box>

          <Divider orientation="vertical" flexItem></Divider>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Employer
            </Typography>
            <Button
              variant="outlined"
              sx={{
                background: "transparent",
                padding: "8px 24px",
                border: `2px solid ${theme.palette.primary.buttonOrange}`,
                "&:hover": {
                  borderWidth: "2px",
                },
              }}
              onClick={() => navigate(`/employer/login`)}
            >
              <Typography sx={{ fontWeight: 600, fontSize: ".875rem" }}>
                {" "}
                Post a job
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
      <Feedback
        open={feedbackOpen}
        userType={auth?.role}
        handleClose={() => {
          setFeedbackOpen(false);
        }}
      />

      {/* )} */}
    </>
  );
};

export default ProfileSection;
