import {
  Box,
  Divider,
  TextField,
  Typography,
  Modal,
  IconButton,
  useTheme,
} from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import React, { useRef, useState } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import CloseIcon from "@mui/icons-material/Close";
import IconBack from "asset/icons/backIcon.svg";
import UploadIcon from "asset/icons/upload.svg";
import FileUpload from "component/HOC/file-upload";
import ImageUploadWithCrop from "component/HOC/image-upload";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { useAppSelector } from "store/store";

const SetCoverImage = ({
  handleClose,
  getData,
}: {
  handleClose: () => void;
  getData: () => void;
}) => {
  const theme = useTheme();
  const [color, setColor] = useState("#fff");
  const [open, setOpen] = useState(false);
  const [modalopen, setModalOpen] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [resumearr, setResumearr] = useState<any>([]);
  const [coverImage, setCoverImage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector((state) => state?.user?.user);
  const childRef: any = useRef();
  const handleChangeComplete = (color: {
    hex: React.SetStateAction<string>;
  }) => {
    setColor(color.hex);
    setPrimaryColor(color.hex);
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  const handleApplyClick = async () => {
    if (childRef?.current?.image) {
      const result = await childRef.current.showCroppedImage();
 
      let coverData: FormData = new FormData();
      coverData.append("cover", result);
      commonService
        .postService(`/employer/files/${user?.user?.id}`, coverData)
        .then((response: any) => {
          Success("Cover image changed successfully!");
          getData();
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(
            errorMessage(error, "Something went wrong, Please try again later")
          );
        });
     
    } else {
      Failed("No cover image selected");
    }
  };

  return (
    <Box >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "600" }}>
          Change cover image
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />

      <ImageUploadWithCrop ref={childRef} />

      <Box
        sx={{
          display: "flex",
          gap: 3,
          alignItems: "center",
          mt: 3,
          justifyContent: "space-between",
        }}
      >
        <OutlinedButton
          label="Cancel"
          size="small"
          isBold={true}
          handleClick={handleClose}
        />

        <ContainedButton
          label="Apply"
          size="small"
          isBold={true}
          handleClick={handleApplyClick}
        />
      </Box>
    </Box>
  );
};

export default SetCoverImage;
