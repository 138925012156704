import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "interface/common.interface";
import { IEmployerSlice } from "interface/redux.interface";

const initialState: IEmployerSlice = {
  postJobPreview: null,
  subscribedPlan: null,
};

export const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setPostJobPreviewRedux(state, action: PayloadAction<any>) {
      state.postJobPreview = action.payload;
    },
    setSubscribedPlanRedux(state, action: PayloadAction<any>) {
      state.subscribedPlan = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

// Action creators are generated for each case reducer function
export const { setPostJobPreviewRedux, setSubscribedPlanRedux } =
  employerSlice.actions;

export default employerSlice.reducer;
