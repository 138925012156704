import "./App.scss";
import { useAppSelector } from "./store/store";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "asset/themes";
import NavigationScroll from "container/layout/NavigationScroll";
import { SetAxiosDefauls } from "helpers/interceptor";
import { Suspense, useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import RoutePath from "routes";
import Loader from "ui-component/Loader";
import { clearStore } from "utils/redux.utils";

function App() {
  SetAxiosDefauls();
  const customization = useAppSelector((state: any) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline>
          
            <Suspense fallback={<Loader />}>
              <RouterProvider router={RoutePath} />
            </Suspense>
          
          </CssBaseline>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
