import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import EmptyImage from "asset/icons/empty.svg";
import { ContainedButton, OutlinedButton } from "component/button";
import { leaderValidationSchema } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import Spotlight from "./spotlight";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "asset/icons/add-icon.svg";
import MindCard from "pages/pre-launch/home/mind-card";
import ImageUploadWithCrop from "component/HOC/image-upload";
import { commonService } from "forms/helpers/common.service";
import { useAppSelector } from "store/store";
import RemoveIcon from "asset/icons/remove.svg";
import ReactPlayer from "react-player";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";
import Leader from "./leader";

const EmployerLife = ({ data, handleUpdate, getProfileData }: any) => {
  const [openLeadersDialog, setOpenLeadersDialog] = useState(false);
  const [openSpotlightsDialog, setOpenSpotlightsDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [newLeaderImage, setNewLeaderImage] = useState<any>(null);
  const [profileImageModalOpen, setProfileImageModalOpen] = useState(false);
  const [spotlightAction, setSpotlightAction] = useState("");
  const [spotlightData, setSpotlightData] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [leaderAction, setLeaderAction] = useState("");
  const [leaderData, setLeaderData] = useState<any>(null);
  const user = useAppSelector((state) => state?.user?.user);
  const childRef: any = useRef();
  const theme = useTheme();
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);

  const handleEditLeaders = () => {
    setOpenLeadersDialog(true);
  };

  const handleCloseLeaders = () => {
    setOpenLeadersDialog(false);
  };

  const handleCloseSpotlights = () => {
    setOpenSpotlightsDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenCompanyDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenCompanyDialog(false);
  };

  const handleAddLeader = () => {
    commonService
      .postService(`employer/${user?.user?.id}/leaders`, values)
      .then((response) => {
        setIsLoading(false);
        Success(response?.data?.message);
        getProfileData();
        resetForm();
        handleCloseLeaders();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(errorMessage("Something went wrong!", error));
      });
  };

  const handleDeleteCompanyPhoto = (url: string) => {
    setIsLoading(true);
    commonService
      .deleteService(`/employer/${user?.user?.id}/photo?photo=${url}`)
      .then((response: any) => {
        setIsLoading(false);
        Success("Company photo deleted successfully!");
        getProfileData();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleDeleteSpotlight = (id: string) => {
    setIsLoading(true);
    commonService
      .deleteService(`/employer/${user?.user?.id}/spotlight/${id}`)
      .then((response: any) => {
        setIsLoading(false);
        Success("Spotlight deleted successfully!");
        getProfileData();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleDeleteLeader = (id: string) => {
    setIsLoading(true);
    commonService
      .deleteService(`/employer/${user?.user?.id}/leaders/${id}`)
      .then((response: any) => {
        setIsLoading(false);
        Success("Leader deleted successfully!");
        getProfileData();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleAddCompanyPhoto = async () => {
    if (childRef.current) {
      const result = await childRef.current.showCroppedImage();
      let photoData: FormData = new FormData();
      photoData.append("photos", result);
      commonService
        .postService(`/employer/files/${user?.user?.id}`, photoData)
        .then((response: any) => {
          const resumes = response?.data?.data?.resume;
          Success("Company photo added successfully!");
          getProfileData();
          handleCloseDialog();
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(
            errorMessage(error, "Something went wrong, Please try again later")
          );
        });
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    width: "300px",
  };

  const leaderValues = {
    profile: null,
    first_name: null,
    last_name: null,
    designation: null,
    linkedIn: null,
    intro: null,
  };

  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    setPatchValue,
    resetForm,
    handleSubmit,
  } = useForm(leaderValues, handleAddLeader, leaderValidationSchema);

  const profileApplyClick = async () => {
    if (childRef.current) {
      const result = await childRef.current.showCroppedImage();
      setNewLeaderImage(result);
      setProfileImageModalOpen(false);
      setUpdateValue("profile", result);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <CardContent
          sx={{ color: "#616B73", fontWeight: 500, fontSize: "14px", p: 0 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 3,
            }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={600}
              color={theme?.palette?.primary?.textBtn}
            >
              Meet Our Leaders
            </Typography>
            {data?.employer?.leaders?.length < 4 && user?.is_primary && (
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#AB5B0B" }}
                onClick={() => {
                  handleEditLeaders();
                  setLeaderAction("add");
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={AddIcon} alt="edit" style={{ paddingLeft: "30px" }} />
                <Typography variant="body2" sx={{ pl: 1, fontWeight: 600 }}>
                  Add
                </Typography>
              </Box>
            )}
          </Box>
          <Dialog open={openLeadersDialog} maxWidth="md">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: 2,
              }}
            >
              <DialogTitle
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  color: theme?.palette?.primary?.textBtn,
                  flexGrow: 1,
                }}
              >
                Add Leader
              </DialogTitle>
              <IconButton
                onClick={handleCloseLeaders}
                aria-label="close"
                sx={{ color: "#AB5B0B" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent sx={{ p: "20px" }}>
              <Leader
                action={leaderAction}
                openSpotlightsDialog={openLeadersDialog}
                handleCloseLeaders={handleCloseLeaders}
                leadersData={leaderData}
                getData={getProfileData}
              />
            </DialogContent>
          </Dialog>
          {profileImageModalOpen && (
            <Modal open={profileImageModalOpen}>
              <Box sx={style}>
                <ImageUploadWithCrop
                  ref={childRef}
                  imageUrl={imageUrl}
                  aspect={4 / 3}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    alignItems: "center",
                    mt: 3,
                    justifyContent: "space-between",
                  }}
                >
                  <OutlinedButton
                    label="Cancel"
                    size="small"
                    isBold={true}
                    handleClick={() => {
                      setProfileImageModalOpen(false);
                    }}
                  />

                  <ContainedButton
                    label="Apply"
                    size="small"
                    isBold={true}
                    handleClick={profileApplyClick}
                  />
                </Box>
              </Box>
            </Modal>
          )}
          <Divider sx={{ mb: 2, width: "100%" }} />
          {data?.employer?.leaders?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                p: 3,
              }}
            >
              <img src={EmptyImage} alt="empty" />
              <Typography
                variant="body1"
                fontWeight={600}
                color={theme?.palette?.primary?.textBtn}
              >
                No Leaders have been added
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container p={3} gap={{ xs: 2, sm: 0 }}>
                {data?.employer?.leaders?.map((leader: any) => {
                  const mind = {
                    name: leader?.first_name + " " + leader?.last_name,
                    position: leader?.designation,
                    image: leader?.profile,
                    linkedIn: leader?.linkedIn,
                    description: leader?.intro,
                  };
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box display={"flex"} alignItems={"start"}>
                        <MindCard
                          mind={mind}
                          isEdit={true}
                          handleEditMindCard={(mindValues: any) => {
                            setPatchValue({ ...leader });
                            setLeaderData({ ...leader });
                            handleEditLeaders();
                            setLeaderAction("edit");
                          }}
                        />
                        <Box
                          component={"img"}
                          src={RemoveIcon}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDeleteLeader(leader?._id);
                          }}
                          alt=""
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", mt: 3 }}>
        <CardContent
          sx={{
            color: "#616B73",
            fontWeight: 500,
            fontSize: "14px",
            p: "0px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 3,
            }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={600}
              color={theme?.palette?.primary?.textBtn}
            >
              Spotlights
            </Typography>
            {data?.employer?.spotlight?.length < 2 && user?.is_primary && (
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#AB5B0B" }}
                onClick={() => {
                  setSpotlightData({});
                  setSpotlightAction("add");
                  setOpenSpotlightsDialog(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={AddIcon} alt="edit" style={{ paddingLeft: "30px" }} />
                <Typography variant="body2" sx={{ pl: 1, fontWeight: 600 }}>
                  Add
                </Typography>
              </Box>
            )}
          </Box>

          <Spotlight
            action={spotlightAction}
            openSpotlightsDialog={openSpotlightsDialog}
            handleCloseSpotlights={handleCloseSpotlights}
            spotlightData={spotlightData}
            getData={getProfileData}
          />
          <Divider sx={{ mb: 2, width: "100%" }} />

          {data?.employer?.spotlight?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                p: 3,
              }}
            >
              <img src={EmptyImage} alt="empty" />
              <Typography
                variant="body1"
                fontWeight={600}
                color={theme?.palette?.primary?.textBtn}
              >
                No details available
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#F0F8FF",
                p: { xs: "16px", sm: "40px" },
                m: 3,
              }}
            >
              {data?.employer?.spotlight?.map((light: any) => {
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography
                          fontWeight={600}
                          fontSize={{ xs: "1.5rem", sm: "2.28rem" }}
                          color={"#15171E"}
                          mt={{ xs: 0, sm: 3 }}
                        >
                          {light?.heading}
                        </Typography>
                        <Typography
                          fontWeight={500}
                          fontSize={{ xs: "0.875rem", sm: "1.28rem" }}
                          color={"#616B73"}
                          mt={2}
                        >
                          {light?.description}
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                          <OutlinedButton
                            label="Edit"
                            size="small"
                            isBold={true}
                            handleClick={() => {
                              setSpotlightData(light);
                              setSpotlightAction("edit");
                              setOpenSpotlightsDialog(true);
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} position="relative">
                      <Box
                        my={2}
                        borderRadius={"16px"}
                        display={"flex"}
                        alignItems={"start"}
                      >
                        <ReactPlayer
                          height={"100%"}
                          width={"100%"}
                          url={light?.video}
                        />
                        <Box
                          component={"img"}
                          src={RemoveIcon}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDeleteSpotlight(light?._id);
                          }}
                          alt=""
                        />
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          )}
        </CardContent>
      </Card>

      <>
        <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", mt: 3 }}>
          <CardContent
            sx={{ color: "#616B73", fontWeight: 500, fontSize: "14px", p: 0 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 3,
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={600}
                color={theme?.palette?.primary?.textBtn}
              >
                Company Photos
              </Typography>
              {user?.is_primary && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#AB5B0B",
                  }}
                  onClick={handleOpenDialog}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={AddIcon}
                    alt="edit"
                    style={{ paddingLeft: "30px" }}
                  />
                  <Typography variant="body2" sx={{ pl: 1, fontWeight: 600 }}>
                    Add
                  </Typography>
                </Box>
              )}
            </Box>

            <Divider sx={{ mb: 2, width: "100%" }} />
            {data?.employer?.photos?.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                  p: 3,
                }}
              >
                <img src={EmptyImage} alt="empty" />
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color={theme?.palette?.primary?.textBtn}
                >
                  No Photos available
                </Typography>
              </Box>
            ) : (
              <Box
                p={{ xs: 1, md: 3, sm: 3 }}
                display={"flex"}
                gap={3}
                flexWrap={"wrap"}
              >
                <Grid container spacing={3}>
                  {data?.employer?.photos?.map((photo: any) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box display={"flex"} alignItems={"start"}>
                        <Box
                          component={"img"}
                          src={photo}
                          alt="Company Photo"
                          sx={{
                            width: { lg: "230px", xl: "248px" },
                            height: { lg: "230px", xl: "248px" },
                            borderRadius: "8px",
                          }}
                        />
                        <Box
                          component={"img"}
                          src={RemoveIcon}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDeleteCompanyPhoto(photo);
                          }}
                          alt=""
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </CardContent>
        </Card>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: { xs: "90%", sm: "auto" },
              margin: 0,
            },
          }}
          open={openCompanyDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: " #000000",
              marginTop: 0,
            }}
          >
            Add Company Photos
          </DialogTitle>
          <DialogContent>
            <ImageUploadWithCrop ref={childRef} aspect={4 / 4} />
          </DialogContent>
          <Box
            display="flex"
            justifyContent="end"
            marginRight={2}
            paddingBottom={2.5}
          >
            <DialogActions>
              <OutlinedButton
                size={"small"}
                label={"cancel"}
                isBold={true}
                handleClick={handleCloseDialog}
              />
              <ContainedButton
                size="small"
                label="Add"
                type="submit"
                handleClick={handleAddCompanyPhoto}
              />
            </DialogActions>
          </Box>
        </Dialog>
      </>
    </>
  );
};

export default EmployerLife;
