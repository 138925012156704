import Swal from "sweetalert2";

type sweetalertProps = {
  status?: number;
  message: string;
  title: string;
  onSuccess?: Function;
  onError?: Function;
  confirmButtonText?: string;
  showCancelButton?: boolean;
  allowOutsideClick?: boolean;
};

const SweetAlertComponent = ({
  status,
  title,
  onSuccess,
  message,
  onError,
  confirmButtonText,
  showCancelButton,
  allowOutsideClick = false,
}: sweetalertProps) => {
  return Swal.fire({
    icon: status === 200 ? "success" : status === 400 ? "error" : "warning",
    title: title,
    text: message,
    confirmButtonText: confirmButtonText,
    allowOutsideClick: allowOutsideClick,
    showCancelButton: showCancelButton,
    showConfirmButton: confirmButtonText ? true : false,
  }).then(($e: any) => {
   
    if ($e?.isConfirmed && onSuccess) {
      onSuccess();
    } else if (onError) {
      onError();
    }
  });
};

export default SweetAlertComponent;
