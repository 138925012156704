import { Box, Typography, Grid, Stack, useTheme } from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import JobCard from "forms/hoc/job-card";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFoundImage from "asset/images/no-search-results.svg";
import Slider from "react-slick";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import NoJobs from "component/no-jobs";

const Jobs = ({ searchText, branding, onUpdateJobsCount }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [similarJobs, setSimilarJobs] = useState<any>([]);
 
  const { employer_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  useEffect(() => {
    getEmployerJobs();
  }, [searchText]);

  useEffect(() => {
    getSimilarJobs();
  }, []);

  const getEmployerJobs = () => {
    setIsLoading(true);
    const params = {
      page: 1,
      per_page: 10,
      sort_order: "DESC",
      sort_by: "title",
      search_by: searchText
        ? "employer_id.organization_name,title,category.name,skills.name"
        : "",
      search: searchText,
      employerId: employer_id,
    };
    commonService
      .postServiceWithParams(`job/jobs-list`, null, params)
      .then((response) => {
        setIsLoading(false);
        setFeaturedJobs(response?.data?.data?.results);
        onUpdateJobsCount(response?.data?.data?.pagination?.total);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getSimilarJobs = () => {
    setIsLoading(true);
    commonService
      .getServices(`job/similar-jobs/${employer_id}`)

      .then((response) => {
        setIsLoading(false);
        setSimilarJobs(response?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Stack spacing={3} pr={1}>
        {featuredJobs.map((job: any) => {
          return (
            <JobCard
              isHomeFeatured={true}
              handleJobClick={() => {
                navigate(`/candidate/job-details/${job["_id"]}`);
              }}
              job={job}
              hasLocationChip={true}
              branding={branding}
              handleJobSave={() => {
                getEmployerJobs();
                getSimilarJobs();
              }}
            ></JobCard>
          );
        })}
      </Stack>

      <Box>
        {featuredJobs.length === 0 && !isLoading ? (
          <NoJobs />
        ) : (
          <Box
            mt={2}
            mb={5}
            className="slider-container"
            maxWidth={{ xs: "90vw", sm: "96vw", md: "75vw" }}
          >
            {similarJobs?.length > 0 && (
              <Typography
                fontWeight={600}
                fontSize={"24px"}
                marginTop={3}
                marginBottom={3}
                color={"#15171E"}
              >
                Similar job recommendations
              </Typography>
            )}
            {similarJobs?.length > 1 && (
              <Slider {...testimonialsSettings}>
                {similarJobs.map((job: any) => {
                  return (
                    <Box pr={1}>
                      <JobCard
                        isHomeFeatured={true}
                        job={job}
                        branding={branding}
                        handleJobSave={() => {
                          getEmployerJobs();
                          getSimilarJobs();
                        }}
                        handleJobClick={() => {
                          navigate(`/candidate/job-details/${job?._id}`);
                        }}
                      ></JobCard>
                    </Box>
                  );
                })}
              </Slider>
            )}

            {similarJobs?.length === 1 && (
              <Box pr={2} maxWidth={"300px"}>
                <JobCard
                  isHomeFeatured={true}
                  job={similarJobs[0]}
                  branding={branding}
                  handleJobSave={() => {
                    getEmployerJobs();
                    getSimilarJobs();
                  }}
                  handleJobClick={() => {
                    navigate(`/candidate/job-details/${similarJobs[0]?._id}`);
                  }}
                ></JobCard>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Jobs;
