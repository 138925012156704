import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { SocialValidationSchema } from "forms/hooks/validateRules";
import React, { useEffect } from "react";

const SocialMedia = ({
  socialMediaEditing,
  socialMediaClose,
  data,
  handleUpdate,
}: any) => {
  const handleUpdateSocialMedia = () => {
    handleUpdate(values);
    socialMediaClose();
  };

  const socialMediaValues = {
    linkedin: null,
    facebook: null,
    instagram: null,
  };

  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(socialMediaValues, handleUpdateSocialMedia, SocialValidationSchema);

  useEffect(() => {
    if (data) {
      setUpdateValue("linkedin", data?.linkedin || "");
      setUpdateValue("facebook", data?.facebook || "");
      setUpdateValue("instagram", data?.instagram || "");
    }
  }, [data, setUpdateValue]);

  return (
    <Dialog
      open={socialMediaEditing}
      sx={{
        "& .MuiDialog-paper": {
          width: {xs:"90%",sm:"50%"},
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, fontSize: "20px", color: "#000000" }}>
        Edit Social Media Link
      </DialogTitle>
      <Divider sx={{ mb: 1 }} />
      <DialogContent  sx={{p:"20px"}}>
        <form onSubmit={handleSubmit}>
          <InputField
            fieldName="Linkedin"
            values={values.linkedin}
            name="linkedin"
            errors={errors.linkedin}
            handleChange={handleChange}
            isRequired={false}
            id="linkedin"
          />

          <InputField
            fieldName="Facebook"
            values={values.facebook}
            name="facebook"
            errors={errors.facebook}
            handleChange={handleChange}
            isRequired={false}
            id="facebook"
          />

          <InputField
            fieldName="Instagram"
            values={values.instagram}
            name="instagram"
            errors={errors.instagram}
            handleChange={handleChange}
            isRequired={false}
            id="instagram"
          />

          <Box display={"flex"} justifyContent={"end"} mt={3}>
            <OutlinedButton
              size={"small"}
              label={"cancel"}
              isBold={true}
              handleClick={socialMediaClose}
            />
            <Box sx={{ mx: 1 }} />
            <ContainedButton
              size="large"
              isBold={true}
              label={"Update"}
              type="submit"
            />
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SocialMedia;
