import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./form.scss";
import { FormHelperText } from "@mui/material";

const QuillInput = ({ value, handleChange, errors }: any) => {
  return (
    <>
      <ReactQuill                                                 
        theme="snow"
        value={value || ""}
        onChange={($e: any) => {
          handleChange($e);
        }}
      />
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </>
  );
};     

export default QuillInput;
