import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Modal,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import useScrollspy from "forms/hooks/useScrollspy";
import { useNavigate } from "react-router-dom";
import EditIcon from "asset/icons/edit.svg";
import EmptyImage from "asset/icons/empty.svg";
import SweetAlertComponent from "../../../../component/HOC/Alert";
import AccountSettings from "./de-activate-account";
import DeActivateAccount from "./account-confirmation";
import { InputField } from "forms/hoc/formfield";
import RightIcon from "asset/images/right-icon.svg";
import TrashIcon from "asset/icons/trash.svg";
import ChangePassword from "./change-password";
import DeleteAccount from "./delete-account";
import BlockIcon from "@mui/icons-material/Block";
import DeActivateAccountConfirmation from "./account-confirmation";
import DeactivateAccount from "./de-activate-account";
import AccountConfirmation from "./account-confirmation";
import { clearStore } from "utils/redux.utils";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "store/store";
import NoCompanyImage from "asset/images/no-company.svg";
import Loader from "ui-component/Loader";
import { setUserRedux } from "store/slice/user.slice";
import Rsystem from "asset/images/r_system.svg";
import { daysToWeeks } from "date-fns";
import SetNewPassword from "component/modalComponent/ForgotPassword";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "asset/icons/add-icon.svg";

const CandidateSettings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [actionId, setActionId] = useState("");
  const user = useAppSelector((state) => state?.user?.user);
  const dispatch = useAppDispatch();
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [newPasswordOpen, setNewPasswordOpen] = useState(false);
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);
  const [deactivateAccountOpen, setDeactivateAccountOpen] = useState(false);
  const [accountConfirmationOpen, setAccountConfirmationOpen] = useState(false);
  const [accountActivityType, setAccountActivityType] = useState("");
  const [settingDetails, setSettingDetails] = useState<any>(null);
  const [blockDetails, setBlockDetails] = useState<any>();
  const [unBlockDetails, setUnBlockDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [jobSearchStatus, setJobSearchStatus] = useState(
    "not-actively-looking"
  );
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = () => {
    Unblockrecuirter("");
  };
  const auth = useAppSelector((state: any) => state?.user?.user);
  useEffect(() => {
    getCandidateSettingDetails();
  }, []);
  useEffect(() => {
    getBlockDetails();
  }, []);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: matchDownMD ? 330 : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  const getCandidateSettingDetails = () => {
    setIsLoading(true);
    commonService
      .getServices(`candidate/password`)
      .then(async (response) => {
        setSettingDetails(response?.data?.candidate);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const getBlockDetails = () => {
    setIsLoading(true);
    commonService
      .getServices(`/candidate/report-recruiter`)
      .then(async (response) => {
 
        setBlockDetails(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const Unblockrecuirter = (id: any) => {
    setIsLoading(true);
    commonService
      .patchService(`/candidate/report-recruiter/${id}`, {})
      .then((response: any) => {
        setIsLoading(false);
        getBlockDetails();
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(errorMessage(error, "Something went wrong!"));
      });
  };

  const updateCandidateProfile = (isActive: boolean) => {
    setIsLoading(true);
    const payload = {
      actively_looking_for_job: isActive,
    };
    commonService
      .patchService(`candidate?candidateId=${auth?.user?.id}`, payload)
      .then((response: any) => {
        setIsLoading(false);
        const candidate = {
          ...user,
          actively_looking_for_job: isActive,
        };
        dispatch(setUserRedux(candidate));
        Success("Profile updated successfully");
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(errorMessage(error, "Something went wrong!"));
      });
  };

  const ids = [
    "password-settings",
    "blocked-recruiter",
    "job-search-personalisation",
    "account-management",
  ];
  const formSections = [
    {
      id: "password-settings",
      label: "Password settings",
    },
    {
      id: "blocked-recruiter",
      label: "Blocked recruiter",
    },
    {
      id: "job-search-personalisation",
      label: "My status",
    },
    {
      id: "account-management",
      label: "Account management",
    },
  ];

  const activeId = useScrollspy(ids, 250);

  const logout = () => {
    clearStore();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/candidate/login");
  };
  

  const handlePasswordSubmit = (password: string) => {
    setIsLoading(true);
    const param = {
      currentPassword: password,
      newPassword: password,
    };
    commonService
      .putService(`candidate/password`, param)
      .then(async (response) => {
        setIsLoading(false);
        Success("New password updated successfully!");
        setNewPasswordOpen(false);
        getCandidateSettingDetails();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const showDeactivateAlert = (): void => {
    SweetAlertComponent({
      title: "Warning",
      message:
        "If you deactivate your account, you'll not be able to login or register into Ekam platform again. You will have to contact our admin through email to activate your account in the future.",
      confirmButtonText: "Continue",
      showCancelButton: true,
      onSuccess: () => {
        setDeactivateAccountOpen(true);
      },
      onError: () => {
        return;
      },
    });
  };
  const showDeleteAlert = (): void => {
    SweetAlertComponent({
      status: 400,
      title: "Danger",
      message:
        "If you delete your account, we will wipe your data from our database and you will permanently lose access to all of your data stored in our servers. Our admins will not be able to restore your data in any way. You will have to register again in the future if you want to use our platform.",
      confirmButtonText: "Continue",
      showCancelButton: true,
      onSuccess: () => {
        setDeleteAccountOpen(true);
      },
      onError: () => {
        return;
      },
    });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ pt: 2, display: { sm: "block", xs: "none" } }}
      >
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/candidate/home");
          }}
        >
          Home
        </Link>
        <Typography fontWeight={500} color={theme?.palette?.primary?.textMid}>
          Settings
        </Typography>
      </Breadcrumbs>
      <Typography
        variant="h2"
        sx={{
          color: theme.palette.primary.textBtn,
          mt: "1rem",
        }}
      >
        Settings
      </Typography>
      <Box display={"flex"} mt={4}>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        >
          <List
            dense={true}
            sx={{
              width: "250px",
              position: "sticky",
              top: "17%",
            }}
          >
            {formSections.map((section) => (
              <ListItem
                key={section.id}
                className={section.id === activeId ? "active" : "none"}
                sx={{
                  height: "44px",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      component={"a"}
                      href={`#${section.id}`}
                      variant="h4"
                      fontWeight={500}
                      color={theme?.palette?.primary?.textGrey}
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      {section.label}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            width: "1px",
            backgroundColor: theme.palette.primary.borderLinelight,
            height: "50vh",
            mx: 3,
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        ></Box>
        <Box flex={1}>
          <Card
            id="password-settings"
            sx={{
              boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              mb: 2,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.primary.textBtn}
                  >
                    Password settings
                  </Typography>
                  {user?.updatedAt && (
                    <Typography
                      variant="h4"
                      color={theme.palette.primary.textMid}
                      mt={1}
                      display={{ xs: "block", sm: "none" }}
                    >
                      Profile last updated:
                      <strong style={{ color: "#15171E" }}>
                        {formatDate(user?.updatedAt)}
                      </strong>
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {user?.updatedAt && (
                      <Typography
                        variant="h4"
                        color={theme.palette.primary.textMid}
                        mt={1}
                        display={{ xs: "none", sm: "block" }}
                      >
                        Profile last updated:
                        <strong style={{ color: "#15171E" }}>
                          {formatDate(user?.updatedAt)}
                        </strong>
                      </Typography>
                    )}
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        mx: 1,
                        height: "24px",
                        display: { xs: "none", sm: "block" },
                      }}
                    />
                  </Box>

                  {settingDetails?.passwordPresent ? (
                    <Box
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setPasswordOpen(true);
                      }}
                    >
                      <img src={EditIcon} alt="edit" />
                      <Typography
                        sx={{
                          pl: 1,
                          fontWeight: 600,
                          fontSize: ".875rem",
                          color: theme.palette.primary.textOrange,
                        }}
                      >
                        Edit
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setNewPasswordOpen(true);
                      }}
                    >
                      <img src={AddIcon} alt="Add" />
                      <Typography
                        sx={{
                          pl: 1,
                          fontWeight: 600,
                          fontSize: ".875rem",
                          color: theme.palette.primary.textOrange,
                        }}
                      >
                        Add
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider sx={{ my: 2, width: "100%" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {settingDetails?.passwordPresent && (
                  <Typography
                    variant="h5"
                    color={theme.palette.primary.textMid}
                  >
                    Current password:
                  </Typography>
                )}

                {settingDetails?.password_last_update && (
                  <Typography
                    ml={1}
                    variant="h6"
                    color={theme.palette.primary.textMid}
                  >
                    Password updated{" "}
                    {getPasswordUpdatedDateTime(
                      settingDetails?.password_last_update
                    )}
                  </Typography>
                )}
              </Box>
              {settingDetails?.passwordPresent && (
                <Typography
                  variant="h4"
                  color={theme.palette.primary.textBtn}
                  marginTop={1}
                >
                  ********
                </Typography>
              )}
            </CardContent>
          </Card>

          <Card
            id="blocked-recruiter"
            sx={{
              boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              mb: 2,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary.textBtn}
                >
                  Blocked recruiter
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "100%" }} />
              {!blockDetails || blockDetails?.length <= 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    p: 3,
                  }}
                >
                  <img src={EmptyImage} alt="empty" />
                  <Typography
                    variant="h4"
                    color={theme?.palette?.primary?.textBtn}
                  >
                    No companies added
                  </Typography>
                </Box>
              ) : (
                blockDetails?.map((blockItem: any, index: number) => {
                  return (
                    <Box key={blockItem._id} sx={{ mt: index > 0 ? 2 : 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 3 }}>
                          <Box>
                            <Typography
                              variant="body2"
                              color={theme?.palette?.primary?.textMid}
                              fontWeight={500}
                            >
                              {
                                blockItem?.recruiter?.employer
                                  ?.organization_name
                              }
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "400", mt: 1 }}
                            >
                              Blocked on {formatDate(blockItem?.created_at)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "start" }}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "600",
                              color: "#1253E0",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              Unblockrecuirter(blockItem?._id);
                            }}
                          >
                            Unblock
                          </Typography>
                        </Box>
                      </Box>
                      {index < blockDetails.length - 1 && (
                        <Divider sx={{ mt: 2 }} />
                      )}
                    </Box>
                  );
                })
              )}
            </CardContent>
          </Card>

          <Card
            id="job-search-personalisation"
            sx={{
              boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={theme?.palette?.primary?.textBtn}
                >
                  My status
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "100%" }} />
              <Box>
                <FormControl>
                  <RadioGroup
                    aria-label="job-search-status"
                    name="job-search-status"
                    value={
                      user?.actively_looking_for_job ? "active" : "inactive"
                    }
                    onChange={($e) => {
                    
                      updateCandidateProfile(
                        $e.target.value === "active" ? true : false
                      );
                    }}
                  >
                    <FormControlLabel
                      value="active"
                      control={<Radio />}
                      label="Actively seeking"
                    />
                    <FormControlLabel
                      value="inactive"
                      control={<Radio />}
                      label="Open to opportunities"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </CardContent>
          </Card>

          <Box sx={{ height: "20px" }} />

          <Card
            id="account-management"
            sx={{
              boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={theme?.palette?.primary?.textBtn}
                >
                  Account management
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "100%" }} />
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  maxWidth: "415px",
                  border: "1px solid #E3E8EF",
                  borderRadius: "4px",
                  padding: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  showDeactivateAlert();
                }}
              >
                <Box display={"flex"} gap={1} alignItems={"center"}>
                

                  <BlockIcon
                    sx={{ color: theme?.palette?.primary?.iconOrange }}
                  />
                  <Typography
                    fontWeight={500}
                    color={theme?.palette?.primary?.textBtn}
                  >
                    De-activate Account
                  </Typography>
                </Box>
                <Box component="img" src={RightIcon} alt="Right Icon" />
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  maxWidth: "415px",
                  border: "1px solid #E3E8EF",
                  borderRadius: "4px",
                  padding: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mt: 1,
                }}
                onClick={() => {
                  showDeleteAlert();
                }}
              >
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Box
                    component="img"
                    src={TrashIcon}
                    alt="Delete Icon"
                    sx={{}}
                  />
                  <Typography
                    fontWeight={500}
                    color={theme?.palette?.primary?.textBtn}
                  >
                    Delete Account
                  </Typography>
                </Box>
                <Box component="img" src={RightIcon} alt="Right Icon" />
              </Box>
            </CardContent>
          </Card>
          <ChangePassword
            passwordOpen={passwordOpen}
            handlePasswordChange={logout}
            handlePasswordClose={() => {
              setPasswordOpen(false);
            }}
          />
          <DeleteAccount
            deleteAccountOpen={deleteAccountOpen}
            handleDeleteAccountClose={() => {
              setDeleteAccountOpen(false);
            }}
            handleDeleteAccountSubmit={(id: string) => {
              setAccountActivityType("delete");
              setDeleteAccountOpen(false);
              setAccountConfirmationOpen(true);
              setActionId(id);
            }}
          />
          <DeactivateAccount
            deactivateAccountOpen={deactivateAccountOpen}
            handleDeactivateAccountClose={() => {
              setDeactivateAccountOpen(false);
            }}
            handleDeactivateAccountSubmit={(id: string) => {
              setAccountActivityType("de-activate");
              setDeactivateAccountOpen(false);
              setAccountConfirmationOpen(true);
              setActionId(id);
            }}
          />
          <AccountConfirmation
            type={accountActivityType}
            accountConfirmationOpen={accountConfirmationOpen}
            handleAccountConfirmationClose={() => {
              setAccountConfirmationOpen(false);
            }}
            actionId={actionId}
          />
          <Modal open={newPasswordOpen}>
            <Box sx={style}>
              <IconButton
                sx={{
                  position: "absolute",
                  right: "2px",
                  top: "1px",
                }}
                onClick={() => {
                  setNewPasswordOpen(false);
                }}
              >
                <CloseIcon
                  sx={{
                    fontSize: "20px",
                    color: theme.palette.primary.iconOrange,
                  }}
                />
              </IconButton>

              <SetNewPassword handlePasswordSubmit={handlePasswordSubmit} />
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default CandidateSettings;

const formatDate = (dateString: any) => {
  const date = new Date(dateString);

  // Options for toLocaleString
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit", // Include day with 2 digits
  };

  return date.toLocaleString("en-US", options);
};

function getPasswordUpdatedDateTime(updatedDateTime: string): string {
  const updatedAt = dayjs(updatedDateTime);
  const currentDate = dayjs();

  // Calculate the difference in days
  const diffDays = currentDate.diff(updatedAt, "day");

  if (diffDays === 0) {
    return "today";
  } else if (diffDays === 1) {
    return "yesterday";
  } else if (diffDays < 1) {
    return "now";
  } else {
    // Return the date in the specified format
    return updatedAt.format("MMMM DD, YYYY");
  }
}
