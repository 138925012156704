import React, { forwardRef, useImperativeHandle, useState } from "react";

import Cropper from "react-easy-crop";

import { Box, Button, Slider, Typography } from "@mui/material";

import getCroppedImg from "./crop-image";
import { styles } from "./styles";
import { withStyles } from "@mui/styles";
import FileUpload from "../file-upload";

const dogImg =
  "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";

const ImageUpload = forwardRef(({ classes, imageUrl, aspect }: any, ref) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropApply, setCropApply] = useState(false);
  const [image, setImage] = useState<any>(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useImperativeHandle(ref, () => ({
    showCroppedImage,
    image,
    setImage,
  }));

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageUrl ? imageUrl : image,
        croppedAreaPixels,
        rotation
      );
      
      return croppedImage;
    } catch (e) {
     
      return null;
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const onFileChange = async (file: File) => {
   
    try {
      let imageDataUrl = await readFile(file);
      
      setImage(imageDataUrl); // Assuming setImage is a function to set the image URL in your state or component
    } catch (error) {
     
    }
  };

 

  return (
    <div>
      {!image && !imageUrl && (
        <FileUpload
          name="resume"
          fieldName=""
          type={"image"}
          types={["jpg", "png", "jpeg"]}
          onChange={(e: any) => {
          
            onFileChange(e);
          }}
          required
          values={image}
        />
      )}

      {(image || (imageUrl && !cropApply)) && (
        <Box>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageUrl ? imageUrl : image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspect ? aspect : 4 / 2}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation: any) => setRotation(rotation)}
              />
            </div>
          </div>
        </Box>
      )}
      {/* {cropApply && <ImgDialog img={croppedImage} onClose={onClose} />} */}
    </div>
  );
});

const ImageUploadWithCrop = withStyles(styles)(ImageUpload);

export default ImageUploadWithCrop;



const readFile = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to read file as data URL"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(file);
  });
};
