import { Navigate, Outlet, useLocation } from "react-router-dom";

const RoleRoute = (props: any) => {
  const auth = !!localStorage.getItem("ekam-user")
    ? JSON.parse(localStorage.getItem("ekam-user") || "")
    : null;
  const token = !!localStorage.getItem("ekam-token")
    ? JSON.parse(localStorage.getItem("ekam-user") || "")
    : null;
  let location = useLocation();

  if ((!auth && !token) || props?.roles?.includes(auth?.role)) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={auth?.role === "candidate" ? `/candidate/home` : `/employer/home`}
        state={{ from: location }}
        replace
      />
    );
  }
};

export default RoleRoute;
