import { lazy } from "react";
import {
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import { appRoles } from "utils/constant.utils";
import MainLayout from "../layout/layout/MainLayout";
import GuestRoute from "./guestRoute.guard";
import ProtectedRoute from "./protectedRoute.guard";
import RoleRoute from "./roleRoute.guard";
import Loader from "ui-component/Loader";
import config from "constant/config";
import AboutUs from "pages/post-launch/about-us";
import FindCandidate from "pages/post-launch/employer/find-candidate";
import AllCandidates from "pages/post-launch/employer/candidate";
import NavigationScroll from "container/layout/NavigationScroll";
import EmployerProfile from "pages/post-launch/employer/profile";
import MyJobs from "pages/post-launch/employer/my-jobs";
import PostJob from "pages/post-launch/employer/post-job";
import JobPreview from "pages/post-launch/employer/post-job/preview";
import EmployerDetails from "pages/post-launch/candidate/employer-details";
import Blogs from "pages/post-launch/blogs";
import BlogDetails from "pages/post-launch/blogs/blog-details";
import MyJobDetails from "pages/post-launch/employer/my-jobs/details";

import CandidateSettings from "pages/post-launch/candidate/settings";
import ContactUs from "pages/post-launch/employer/contact-us";

const PageNotFound = lazy(() => import("../pages/pagenotfound"));
const CandidateLogin = lazy(
  () => import("../pages/authentication/post-launch/candidate")
);
const CandidateRegister = lazy(
  () => import("../pages/authentication/post-launch/candidate")
);
const EmployerLogin = lazy(
  () => import("../pages/authentication/post-launch/employer")
);
const EmployerRegister = lazy(
  () => import("../pages/authentication/post-launch/employer")
);

const PrivacyPolicy = lazy(() => import("../pages/pre-launch/privacy-policy"));
const TermsAndConditions = lazy(
  () => import("../pages/pre-launch/home/terms-and-conditions")
);
const RefundAndCancellation = lazy(
  () => import("../pages/post-launch/refund-and-cancellation")
);

const CandidateHome = lazy(() => import("../pages/post-launch/candidate/home"));
const EmployerHome = lazy(() => import("../pages/post-launch/employer/home"));
const ExploreEmployer = lazy(
  () =>
    import(
      "../pages/post-launch/candidate/employer/top-employer/explore-employer"
    )
);
const EmployerOverview = lazy(
  () => import("../pages/post-launch/candidate/employer/employer-overview")
);
const AllJobs = lazy(() => import("../pages/post-launch/candidate/job"));
const UpdateProfile = lazy(
  () => import("../pages/post-launch/candidate/profile/update-profile")
);
const { CANDIDATE, EMPLOYER } = appRoles;
const EmployerDashboard = lazy(
  () => import("../pages/post-launch/employer/dashboard")
);
const AllApplicantsList = lazy(
  () => import("../pages/post-launch/employer/applicants")
);
const CandidateDashboard = lazy(
  () => import("../pages/post-launch/candidate/dashboard")
);
const AllTopEmployers = lazy(
  () =>
    import(
      "../pages/post-launch/candidate/employer/top-employer/top-employers-list"
    )
);
const CreateProfile = lazy(
  () => import("../pages/post-launch/candidate/profile/create-profile")
);
const Chat = lazy(() => import("../pages/chat"));
const NewsAndEvent = lazy(
  () => import("../pages/post-launch/new-and-events/index")
);
const NewsDetailsPage = lazy(
  () => import("../pages/post-launch/new-and-events/news-details-page")
);
const ApplicantDetails = lazy(
  () =>
    import("../pages/post-launch/employer/my-jobs/details/applicant-details")
);

const RaiseQuery = lazy(() => import("../pages/post-launch/raise-a-query"));
const AppliedJobs = lazy(
  () => import("../pages/post-launch/candidate/job/applied-jobs/index")
);
const SavedJobs = lazy(
  () => import("../pages/post-launch/candidate/job/saved-jobs/index")
);
const EmployerSetting = lazy(
  () => import("../pages/post-launch/employer/setting")
);

const ReferVeteran = lazy(
  () => import("../pages/post-launch/candidate/refer-veteran/index")
);

const SubscriptionDetails = lazy(
  () =>
    import(
      "../pages/post-launch/employer/subscription-plans/subscription-details"
    )
);



const appLoader = () => {
  return <Loader />;
};

const RoutePath = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <NavigationScroll>
          <MainLayout />
        </NavigationScroll>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="home" replace />,
        },
        {
          path: "home",
          element: <CandidateHome />,
        },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "terms-and-conditions", element: <TermsAndConditions /> },
        { path: "refund-and-cancellation", element: <RefundAndCancellation /> },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "news-and-events",
          element: <NewsAndEvent />,
        },
        {
          path: "news-details/:id",
          element: <NewsDetailsPage />,
        },
        {
          path: "blogs",
          element: <Blogs />,
        },
        {
          path: "blog-details/:id",
          element: <BlogDetails />,
        },
        {
          path:"contact-us",
          element:<ContactUs/>
        },
       
      ],
    },
    {
      path: "/candidate",
      element: (
        <NavigationScroll>
          <RoleRoute roles={[CANDIDATE]} />
        </NavigationScroll>
      ),
      children: [
        {
          path: "",
          element: <MainLayout />,
          children: [
            {
              path: "",
              element: <Navigate to="home" replace />,
            },
            {
              path: "home",
              element: <CandidateHome />,
            },
            {
              path: "all-jobs",
              element: <AllJobs />,
            },
            {
              path: "top-employers/:industry_id",
              element: <AllTopEmployers />,
            },
            {
              path: "employer-details/:employer_id",
              element: <EmployerDetails />,
            },
            { path: "job-details/:job_id", element: <AllJobs /> },
            {
              path: "explore-employers",
              element: <ExploreEmployer />,
            },
            {
              path: "employer-overview",
              element: <EmployerOverview />,
            },
          ],
        },
      ],
    },
    {
      path: "/employer",
      element: (
        <NavigationScroll>
          <RoleRoute roles={[EMPLOYER]} />
        </NavigationScroll>
      ),
      children: [
        {
          path: "",
          element: <MainLayout />,
          children: [
            {
              path: "",
              element: <Navigate to="home" replace />,
            },
            {
              path: "home",
              element: <EmployerHome />,
            },
            {
              path: "find-candidate",
              element: <FindCandidate />,
            },
            {
              path: "all-candidates",
              element: <AllCandidates />,
            },
         
            {
              path: "candidate-details/:id",
              element: <AllCandidates />,
            },
            {
              path: "raise-query",
              element: <RaiseQuery />,
            },
            {
              path: "Employer-setting",
              element: <EmployerSetting />,
            },
               
          ],
        },
      ],
    },
    {
      path: "/",
      element: (
        <NavigationScroll>
          <GuestRoute roles={[CANDIDATE, EMPLOYER]} />
        </NavigationScroll>
      ),
      loader: appLoader,
      children: [
        {
          path: "",
          element: <Navigate to="candidate/home" replace />,
        },
        {
          path: "/candidate",
          element: <MainLayout />,
          children: [
            {
              path: "login",
              element: <CandidateLogin />,
            },
            {
              path: "register",
              element: <CandidateRegister />,
            },
          ],
        },
        {
          path: "/employer",
          element: <MainLayout />,
          children: [
            {
              path: "",
              element: <Navigate to="employer/home" replace />,
            },
            {
              path: "login",
              element: <EmployerLogin />,
            },
            {
              path: "register",
              element: <EmployerRegister />,
            },
          ],
        },
      ],
    },
    {
      path: "/",
      element: (
        <NavigationScroll>
          <ProtectedRoute roles={[CANDIDATE]} />
        </NavigationScroll>
      ),
      children: [
        {
          path: "/candidate",
          element: <MainLayout />,
          children: [
            {
              path: "",
              element: <Navigate to="home" replace />,
            },
            { path: "dashboard", element: <CandidateDashboard /> },
            { path: "profile", element: <CreateProfile /> },
            { path: "chat", element: <Chat /> },
            {
              path: "create-profile",
              element: <CreateProfile />,
            },
            {
              path: "update-profile",
              element: <UpdateProfile />,
            },
            {
              path: "settings",
              element: <CandidateSettings />,
            },
            {
              path: "refer-veteran",
              element: <ReferVeteran />,
            },
            {
              path: "applied-jobs",
              element: <AppliedJobs />,
            },
            {
              path: "saved-jobs",
              element: <SavedJobs />,
            },
          ],
        },
      ],
    },
    {
      path: "/",
      element: (
        <NavigationScroll>
          <ProtectedRoute roles={[EMPLOYER]} />
        </NavigationScroll>
      ),
      children: [
        {
          path: "/employer",
          element: <MainLayout />,
          children: [
            { path: "dashboard", element: <EmployerDashboard /> },
            { path: "profile", element: <EmployerProfile /> },
            { path: "my-jobs", element: <MyJobs /> },
            { path: "my-jobs/:id", element: <MyJobDetails /> },
            { path: "my-jobs/applicant/:id", element: <ApplicantDetails /> },
            { path: "post-job", element: <PostJob /> },
            { path: "update-job/:id", element: <PostJob /> },
            { path: "job-preview", element: <JobPreview /> },
            { path: "chat", element: <Chat /> },
            { path: "all-applicants", element: <AllApplicantsList /> },
            { path: "subscription-details", element: <SubscriptionDetails /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "404",
      element: <PageNotFound />,
    },
  ],
  {
    basename: config.basename,
  }
);

export default RoutePath;
