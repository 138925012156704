import JobsIcon from "asset/images/all-jobs.svg";
import ExploreEmployerIcon from "asset/images/explore-employer.svg";
import DashboardIcon from "asset/images/candidate-dashboard.svg";
import FindCandidateIcon from "asset/images/find-candidate.svg";

const Menu = [
  {
    id: `dashboard`,
    title: `Dashboard`,
    type: `group`,
    children: [
      {
        id: `home`,
        title: `Home`,
        type: `item`,
        url: `/home`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [`/home`],
      },
      {
        id: `candidate`,
        title: `Candidate`,
        type: `item`,
        url: `/candidate/home`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [
          "/candidate/home",
          "/candidate/all-jobs",
          "/candidate/explore-employers",
          "/candidate/dashboard",
          "/candidate/job-details",
          "/candidate/chat",
        ],
        children: [
          {
            id: "all-jobs",
            title: "All Jobs",
            type: "child",
            url: "/candidate/all-jobs",
            icon: JobsIcon,
            breadcrumbs: false,
          },
          {
            id: "explore-employer",
            title: "Explore Employer",
            type: "child",
            url: "/candidate/explore-employers",
            icon: ExploreEmployerIcon,
            breadcrumbs: false,
          },
          {
            id: "candidate-dashboard",
            title: "Candidate Dashboard",
            type: "child",
            url: "/candidate/dashboard",
            icon: DashboardIcon,
            breadcrumbs: false,
          },
        ],
      },
      {
        id: `employer`,
        title: `Employer`,
        type: `item`,
        url: `/employer/home`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [
          "/employer/home",
          "/employer/ekam-for-employer",
          "/employer/find-candidate",
          "/employer/all-candidates",
          "/employer/dashboard",
          "/employer/candidate-details",
          
        ],
        children: [
          {
            id: "ekam-for-employer",
            title: "Ekam for employer",
            type: "child",
            url: "/employer/home",
            icon: ExploreEmployerIcon,
            breadcrumbs: false,
          },
          {
            id: "find-candidate",
            title: "Find Candidate",
            type: "child",
            url: "/employer/find-candidate",
            icon: FindCandidateIcon,
            breadcrumbs: false,
          },
          {
            id: "employer-dashboard",
            title: "Employer Dashboard",
            type: "child",
            url: "/employer/dashboard",
            icon: DashboardIcon,
            breadcrumbs: false,
          },
        ],
      },
      {
        id: `contact-us`,
        title: `Contact Us`,
        type: `item`,
        url: `/contact-us`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [`/contact-us`],
      },
    ],
  },
];

export default Menu;
