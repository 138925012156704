import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  useTheme,
  Typography,
  Tooltip,
} from "@mui/material";
import { InputField } from "forms/hoc/formfield";
import SearchIcon from "asset/icons/search.svg";
import { useState } from "react";
import PaginationComponent from "component/pagination";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import PlaceholderImage from "asset/images/no-image-available.png";

const AllBlogs = ({
  allBlogs,
  handleSelectBlogs,
  paginationData,
  handleChangePage,
  handleSearch,
}: {
  allBlogs: any;
  paginationData: any;
  handleSelectBlogs: (id: string) => void;
  handleChangePage: (event: any, newPage: any) => void;
  handleSearch: (val: string) => void;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <Typography
          variant="h4"
          color={theme.palette.primary.textMid}
          display={"flex"}
          justifyContent={{ xs: "flex-end", sm: "flex-start" }}
          order={{ xs: 2, sm: 1 }}
          marginTop={{ xs: 2, sm: 0 }}
        >
          Showing {paginationData.total} Results
        </Typography>
        <Box
          flexGrow={1}
          display="flex"
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems="center"
          ml={{ xs: 0, sm: 2 }}
          order={{ xs: 1, sm: 2 }}
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 2, sm: 0 }}
        >
          <InputField
            values={search}
            name="search"
            errors={""}
            handleChange={(e) => {
              setSearch(e.target.value);
              handleSearch(e.target.value);
            }}
            placeholder="Search"
            id="search"
            adorament="startAdornment"
            position="start"
            Icon={<img src={SearchIcon} alt="Search" />}
          />
        </Box>
      </Box>
      <Grid container spacing={4} marginTop={1}>
        {allBlogs?.map((blog: any) => {
          return (
            <Grid item xs={12} sm={3}>
              <Card
                sx={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/blog-details/${blog?.id}`);
                }}
              >
                <CardContent
                  sx={{
                    padding: "0px",
                  }}
                >
                  <Box sx={{ height: "162px", overflowY: "hidden" }}>
                    <img
                      src={blog?.fimg_url || PlaceholderImage}
                      alt="Job Application Email"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>

                  <Box sx={{ padding: "15px" }}>
                    <Typography
                      variant="h4"
                      color={theme.palette.primary.textBtn}
                      marginTop={"5px"}
                      sx={{
                        cursor: "pointer",
                        overflow: "hidden",
                        display: "-webkit-box",
                        textOverflow: "ellipsis",
                        "-webkit-line-clamp": "1",
                        "-webkit-box-orient": "vertical",
                        height: "20px",
                      }}
                      onClick={() => {
                        navigate(`/blog-details/${blog?.id}`);
                      }}
                    >
                      {parse(blog?.title?.rendered || "")}
                    </Typography>

                    <Typography
                      variant="h5"
                      color={theme.palette.primary.textMid}
                      marginTop={"10px"}
                      sx={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        textOverflow: "ellipsis",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                        height: "60px",
                      }}
                    >
                      {parse(blog?.excerpt?.rendered || "")}
                    </Typography>

                    <Box display="flex" alignItems="center" marginTop={"15px"}>
                      <Typography>
                        By <b>{blog?._embedded?.author[0]?.name}</b>
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ marginLeft: 1, marginRight: 1 }}
                      />
                      <Typography
                        variant="h6"
                        color={theme.palette.primary.textMid}
                      >
                        {formatDateSring(blog?.date)}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Box width="100%">
        <PaginationComponent
          page={paginationData.page}
          rowsPerPage={paginationData.perPage}
          total={paginationData.total}
          onChange={($e: any, newPage: any) => {
            handleChangePage($e, newPage);
          }}
          hasShowing={true}
        />
      </Box>
    </>
  );
};

export default AllBlogs;

const formatDateSring = (dateString: any) => {
  const date = new Date(dateString);

  // Options for toLocaleString
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit", // Include day with 2 digits
  };

  return date.toLocaleString("en-US", options);
};
