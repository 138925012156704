import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Breadcrumbs, Link, Tab, Typography } from "@mui/material";
import ActiveJobs from "./active-jobs";
import { Drafts } from "@mui/icons-material";
import Archives from "./archives";
import { useEffect, useState } from "react";
import { ContainedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import SearchIcon from "asset/icons/search.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ArchivedJobs from "./archives";
import DraftJobs from "./drafts";
import { useDebounce } from "forms/hooks/useDebounce";
import { commonService } from "forms/helpers/common.service";
import { useAppSelector } from "store/store";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";

const MyJobs = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("active-jobs");
  const [isLoading, setIsLoading] = useState(false);
  const [activeCount, setActiveCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);
  const [archiveCount, setArchiveCount] = useState(0);
  const [jobsCount, setJobsCount] = useState<any>({
    active: 0,
    draft: 0,
    archive: 0,
  });
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { state } = useLocation();
  const user = useAppSelector((state: any) => state?.user?.user);

  useEffect(() => {
    getJobsCount();
  }, []);

  useEffect(() => {
    if (state?.isDraft) {
      setValue("drafts");
    }
  }, state);

  const getJobsCount = () => {
    commonService
      .getServices(`job/${user?.user?.id}/count`)
      .then((response) => {
        setJobsCount(response?.data?.data);
        setActiveCount(response?.data?.data?.active);
        setDraftCount(response?.data?.data?.draft);
        setArchiveCount(response?.data?.data?.archived || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ m: 3, mb: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/employer/home");
          }}
        >
          Home
        </Link>
        <Typography fontWeight={500} color="text.primary">
          My Jobs
        </Typography>
      </Breadcrumbs>
      <TabContext value={value}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box
            sx={{
              flex: 1,
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TabList
              variant="scrollable"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  height: "3px",
                },
              }}
            >
              <Tab label={`Active Jobs(${activeCount})`} value="active-jobs" />
              <Tab label={`Drafts(${draftCount})`} value="drafts" />
              <Tab label={`Archives(${archiveCount})`} value="archives" />
            </TabList>
          </Box>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <InputField
              values={search}
              name="search"
              errors={""}
              handleChange={($e) => {
                setSearch($e.target.value);
              }}
              placeholder="Search jobs"
              id="search"
              adorament="startAdornment"
              position="start"
              Icon={<img src={SearchIcon} alt="Search" />}
            />
            <Box minWidth={"100px"}>
              <ContainedButton
                size="large"
                label="Add Job"
                handleClick={() => {
                  navigate("/employer/post-job");
                }}
              />
            </Box>
          </Box>
        </Box>
        <TabPanel value="active-jobs" sx={{ py: 2.8, px: 0 }}>
          <ActiveJobs
            searchText={debouncedSearchTerm}
            onSearchJobs={(count: number) => {
              const myJobsCount = jobsCount;
              setJobsCount({ ...myJobsCount, active: count });
              setActiveCount(count);
            }}
          />
        </TabPanel>
        <TabPanel value="drafts" sx={{ py: 2.8, px: 0 }}>
          <DraftJobs
            searchText={debouncedSearchTerm}
            onSearchJobs={(count: number) => {
              const myJobsCount = jobsCount;
              setJobsCount({ ...myJobsCount, draft: count });
              setDraftCount(count);
            }}
          />
        </TabPanel>
        <TabPanel value="archives" sx={{ py: 2.8, px: 0 }}>
          <ArchivedJobs
            searchText={debouncedSearchTerm}
            onSearchJobs={(count: number) => {
              const myJobsCount = jobsCount;
              setJobsCount({ ...myJobsCount, archive: count });
              setArchiveCount(count);
            }}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default MyJobs;
