// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import { useSelector } from "react-redux";
import { role } from "features/theme/constant";
import { useAppSelector } from "store/store";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  // const userDetail = useAppSelector((state) => state.user.user);
  // const menuRole = role.find(r=> r === userDetail?.role);
  const menuList = useAppSelector((state) => state.user.menuItems);
  const navItems = menuList?.map((item: any) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
