import { differenceInDays } from "date-fns";
import dayjs from "dayjs";

export const getSelectedValue = (array: any[], value: string) => {
  return array.find((data) => data.value === value);
};

export const getSalaryDetail = (low: number, high: number) => {
  const formattedLow = formatNumber(low);
  const formattedHigh = formatNumber(high);
  return `${formattedLow}-${formattedHigh} lakhs PA`;
};

const formatNumber = (num: number): string => {
  return (num / 100000).toFixed(0); // Divide by 100,000 to convert to lakhs
};

export const getFormattedDateString = (dateString: string) => {
  const originalDate = dayjs(dateString);
  const formattedDate = originalDate
    .subtract(1, "year")
    .format("MMMM DD, YYYY");

  return formattedDate;
};

export const getPostedDateTime = (postedAt: string): string => {
  const postedDate = dayjs(postedAt);
  const currentDate = dayjs();

  // Calculate the difference in days
  const diffDays = currentDate.diff(postedDate, "day");

  if (diffDays === 0) {
    return "Today";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else if (diffDays < 1) {
    return "Now";
  } else {
    // Return the date in the specified format
    return postedDate.format("MMMM DD, YYYY");
  }
};

export const Capitalize = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const getDuration = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearsDiff = end.getFullYear() - start.getFullYear();
  const monthsDiff = end.getMonth() - start.getMonth();

  const totalMonths = yearsDiff * 12 + monthsDiff;

  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  let duration = "";
  if (years > 0) {
    duration += `${years} ${years === 1 ? "year" : "years"}`;
  }
  if (months > 0) {
    duration += ` ${months} ${months === 1 ? "month" : "months"}`;
  }

  return duration.trim();
};

export const formatDate = (dateString: any, onlyYear?: boolean) => {
  const date = new Date(dateString);
  let options: any;

  // Options for toLocaleString
  if (onlyYear) {
    options = {
      year: "numeric",
    };
  } else {
    options = {
      year: "numeric",
      month: "short",
    };
  }

  return date.toLocaleString("en-US", options);
};

export const getCountryCode = (phoneNumber: string) => {
  return `+${phoneNumber.match(/^\+(\d+)/)![1]}`;
};

export const getPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/^\+\d+\s|\-/g, "");
};

export const readFile = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const getCookie = (name: string) => {
  const cookies = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));

  return cookies ? cookies.split("=")[1] : null;
};

export const setCookie = (cname: any, cvalue: any, minutes: any) => {
  var d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
};

export function getPlanExpiryDate(createdDate: string, validDays: number) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const startDate = new Date(createdDate);
  const expiryDate = new Date(startDate);
  expiryDate.setDate(startDate.getDate() + validDays);
  const day = expiryDate.getDate();
  const month = months[expiryDate.getMonth()];
  const year = expiryDate.getFullYear();
  return `${day} ${month}, ${year}`;
}

export function getPlanRemainingDays(createdDate: string, validDays: number) {
  const startDate = new Date(createdDate);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - startDate.getTime();
  const daysSpent = Math.ceil(timeDifference / (1000 * 3600 * 24));
  const remainingDays = Math.max(validDays - daysSpent, 0);
  return remainingDays;
}

export function getPlanUtilizedPercentage(createdDate: string, expireDate: string) {
  const startDate = new Date(createdDate);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - startDate.getTime();
  const daysSpent = Math.ceil(timeDifference / (1000 * 3600 * 24));
  const validDays = differenceInDays(expireDate, createdDate);
  const remainingDays = Math.max(validDays - daysSpent, 0);
  const utilizationPercentage = ((validDays - remainingDays) / validDays) * 100;
  return parseFloat(utilizationPercentage.toFixed(2));
}
