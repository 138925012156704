import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  FormHelperText,
  CardContent,
  IconButton,
  Card,
  styled,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import "./index.scss";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import UploadImg from "asset/icons/upload.svg";
import { Failed } from "forms/helpers/toast.helper";

interface IFileUpload {
  onChange: any;
  types?: any;
  required?: boolean;
  fieldName?: string;
  name?: string;
  errors?: any;
  values?: any;
  deleteFile?: any;
  type?: any;
  maxSize?: any;
}

function FileUpload({
  onChange,
  types,
  required,
  name,
  fieldName,
  errors,
  values,
  deleteFile,
  type,
  maxSize = 2,
}: IFileUpload) {
  const theme = useTheme();
  const [fileError, setFileError] = useState<string | null>(null);



  const handleFileChange = (file: any) => {
    if (types && file) {
      const fileType = file.name.split(".").pop();
      if (types.includes(fileType)) {
        onChange(file);
      } else {
        Failed(
          `File type not supported. Please upload a ${types.join(", ")} file.`
        );
      }
    }
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    border: `2px solid #cccfd6 !important`,
    borderRadius: "4px",
    borderStyle: "dashed !important",
  }));

  return values &&
    Object.keys(values).length > 0 &&
    values?.name &&
    values?.value ? (
    <Box>
      {fieldName && (
        <Typography
          py="10px"
          fontWeight={600}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${theme.palette.primary.borderLinelight}`,
          padding: " 2%",
        }}
      >
        <Box className="file-drag-and-drop">
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{
                textOverflow: "ellipsis",
                width: "300px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {values.name}
            </Typography>
            <IconButton onClick={deleteFile}>
              <DeleteTwoToneIcon />
            </IconButton>
          </CardContent>
        </Box>
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
     
    </Box>
  ) : (
    <Box>
      {fieldName && (
        <Typography
          py="10px"
          fontWeight={600}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
        </Typography>
      )}
      <FileUploader
        handleChange={handleFileChange}
        name="file"
        types={types}
        label="Upload a doc, docx, pdf up to 2MB"
        maxSize={10}
        children={
          <Box
            sx={{
              width: "100%",
              border: `1px solid ${theme.palette.primary.borderLinelight}`,
              padding: " 2%",
            }}
          >
            <Box className="file-drag-and-drop">
              <img src={UploadImg} alt="upload" width="30px" />
              {type === "image" ? (
                <Typography>
                  <span
                    style={{
                      color: theme.palette.primary.iconOrange,
                      fontWeight: "600",
                    }}
                  >
                    Upload
                  </span>{" "}
                  a jpg, jpeg, png up to 10MB
                </Typography>
              ) : (
                <Typography>
                  <span
                    style={{
                      color: theme.palette.primary.iconOrange,
                      fontWeight: "600",
                    }}
                  >
                    Upload
                  </span>{" "}
                  a doc, docx, pdf up to 2MB
                </Typography>
              )}
            </Box>
          </Box>
        }
      />
      {errors && <FormHelperText error>{errors}</FormHelperText>}
     
    </Box>
  );
}

export default FileUpload;
