import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import Assets from "helpers/assets.helper";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }: NavCollapseTypes) => {
    const theme = useTheme();
    const customization = useSelector((state: any) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
     
    };

    

    const location = useLocation();
    const { id } = useParams();
    const pathname = id? location.pathname.replace(`/${id}`, ''): location.pathname;
    const checkOpenForParent = (child: any, id: any) => {
        child.forEach((item: any) => {
            if (item.url === `/${pathname}`) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        setOpen(false);
        setSelected(null);
        if (menu.children) {
            menu.children.forEach((item: any) => {
          
                if (item?.children?.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if ((item?.activeItem && item?.activeItem.length && item?.activeItem?.includes(pathname)) || `/${item.url}` === pathname) {
                    setSelected(menu.id);
                    setOpen(true);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children?.map((item: any) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = Assets[menu.icon];
    const menuIcon = <Icon sx={{ fontSize: "28px" }} />;

    return (
        <>
        <ListItemButton
            sx={{
                width: "100%",
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 16}px`
            }}
            
            onClick={handleClick}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                        {menu.title}
                    </Typography>
                }
              
            />
            {open ? (
                <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            ) : (
                <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            )}
        </ListItemButton>
        <Collapse in={open} sx={{ width: "100%" }} timeout="auto" unmountOnExit>
            <List
                component="div"
                disablePadding
                sx={{
                    position: 'relative',
                    marginLeft: '32px',
                    '&:after': {
                        content: "''",
                        position: 'absolute',
                        left: '32px',
                        top: 0,
                        height: '100%',
                        width: '1px',
                        opacity: 1,
                        // background: theme.palette.primary.light
                    }
                }}
            >
                {menus}
            </List>
        </Collapse>
    </>
    );
};

type NavCollapseTypes = {
    menu: any,
    level: number
};

export default NavCollapse;
