import { AddBoxSharp } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CompanySize from "asset/icons/company-size.svg";
import Headquaters from "asset/icons/headquarters.svg";
import Facebook from "asset/icons/facebook-color.svg";
import Instagram from "asset/icons/instagram-color.svg";
import YouTube from "asset/icons/youtube-color.svg";
import LinkedIn from "asset/icons/linkedin-color.svg";
import HealthInsurance from "asset/images/health-insurance.svg";
import RetirementBenefits from "asset/images/retirement-benefits.svg";
import PaidTimeoff from "asset/images/paid-time-off.svg";
import RemoteWork from "asset/images/remote-work-flexibility.svg";
import GymMembership from "asset/images/gym-membership.svg";
import EmployeeDiscount from "asset/images/employee-discounts.svg";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import EllipseImage from "asset/images/ellipse-image.svg";

const EmployerOverview = ({ details, branding }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { employer_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const blogSettings = {
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    getEmployerJobs();
  }, []);

  const getEmployerJobs = () => {
    setIsLoading(true);
    const params = {
      page: 1,
      per_page: 3,
      sort_order: "ASC",
      sort_by: "title",
      employerId: employer_id,
    };
    commonService
      .postServiceWithParams(`job/jobs-list`, null, params)
      .then((response) => {
        setIsLoading(false);
        setRoles(response?.data?.data?.results);
       
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "600",
                color: theme?.palette?.primary?.textBtn,
              }}
              mt="1rem"
            >
              About organisation
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme?.palette?.primary?.textGrey,
                fontWeight: "500",
                marginTop: 2,
                maxWidth: "846px",
              }}
            >
              {details?.organization?.about}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
               
                pr: 1,
              }}
            >
              <Card
                sx={{
                  display: { xs: "block", sm: "none" },
                  boxShadow: `0px 0px 4px 0px ${theme?.palette?.primary?.boxShadowLight}`,
                  maxWidth: { sm: "282px" },
               
                }}
              >
                <CardContent
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: { xs: "row", sm: "column" },
                    marginLeft: { xs: 0, sm: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        marginleft: 1,
                      }}
                    >
                      <img src={CompanySize} alt={"CompanySize"} />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: theme?.palette?.primary?.textGrey,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Company Size
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: theme?.palette?.primary?.textBtn,
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        mt: 1,
                      }}
                    >
                      {details?.organization?.size} employees
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  <Box
                    sx={{
                      mt: { xs: 0, sm: 1 },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <img src={Headquaters} alt={"Headquaters"} />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: theme?.palette?.primary?.textGrey,
                        }}
                      >
                        Headquarters
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: theme?.palette?.primary?.textBtn,
                        fontWeight: "600",
                        mt: 1,
                      }}
                    >
                      {details?.location?.city}, {details?.location?.state}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  display: { xs: "none", sm: "block" },
                  boxShadow: `0px 0px 4px 0px ${theme?.palette?.primary?.boxShadowLight}`,
                  maxWidth: { sm: "282px" },
                 
                }}
              >
                <CardContent
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: { xs: "row", sm: "column" },
                    marginLeft: { xs: 0, sm: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        marginleft: 1,
                      }}
                    >
                      <img src={CompanySize} alt={"CompanySize"} />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: theme?.palette?.primary?.textGrey,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Company Size
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: theme?.palette?.primary?.textBtn,
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        mt: 1,
                      }}
                    >
                      {details?.organization?.size} employees
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  <Box
                    sx={{
                      mt: { xs: 0, sm: 2 },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <img src={Headquaters} alt={"Headquaters"} />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: theme?.palette?.primary?.textGrey,
                        }}
                      >
                        Headquarters
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: theme?.palette?.primary?.textBtn,
                        fontWeight: "600",
                        mt: 1,
                      }}
                    >
                      {details?.location?.city}, {details?.location?.state}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  boxShadow: `0px 0px 4px 0px ${theme?.palette?.primary?.boxShadowLight}`,
                  maxWidth: "282px",
                  mt: 3,
                  display: { xs: "none", sm: "block" },
                }}
              >
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "600",
                      color: theme?.palette?.primary?.textBtn,
                    }}
                  >
                    Social Media
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {details?.organization?.facebook && (
                      <img
                        src={Facebook}
                        alt={"Facebook"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            details?.organization?.facebook,
                            "_blank"
                          );
                        }}
                      />
                    )}
                    {details?.organization?.instagram && (
                      <img
                        src={Instagram}
                        alt={"Instagram"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            details?.organization?.instagram,
                            "_blank"
                          );
                        }}
                      />
                    )}
                    {details?.organization?.youtube && (
                      <img
                        src={YouTube}
                        alt={"youtube"}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    {details?.organization?.linkedin && (
                      <img
                        src={LinkedIn}
                        alt={"Linkedin"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            details?.organization?.linkedin,
                            "_blank"
                          );
                        }}
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          mt: 8,
          backgroundColor: theme.palette.primary.backgroundOrangeLight,
          padding: "1rem",
          borderRadius: "8px",
          mb: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "600",
            color: theme?.palette?.primary?.textBtn,
            paddingLeft: "0.5rem",
          }}
        >
          {details?.organization?.organization_name} is hiring for the below
          roles
        </Typography>
        <Box
          mt={3}
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            gap: "2rem",
          }}
        >
          {roles?.map((role: any) => {
            const skills = role?.skills
              ?.map((skill: any) => skill.name)
              .join(" / ");
            return (
              <Card sx={{ flex: 1 }}>
                <CardContent
                  sx={{ backgroundColor: theme.palette.background.paper }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: theme?.palette?.primary?.textBtn,
                    }}
                  >
                    {role?.title}
                  </Typography>

                  <Typography sx={{ fontWeight: 500, mt: 2 }}>
                    {skills}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      mt: 2,
                      color: branding
                        ? branding
                        : theme?.palette?.primary?.textOrange,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/candidate/all-jobs", {
                        state: {
                          search: details?.organization?.organization_name,
                        },
                      });
                    }}
                  >
                    View {role?.opening} openings
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        <Box
         

          className="slider-container"
          gap={2}
          mt={3}
          maxWidth={{ xs: "80vw", sm: "96vw", md: "73vw" }}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <Slider {...blogSettings}>
            <Card sx={{ flex: 1 }}>
              <CardContent
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  Engineering - Software & QA
                </Typography>
                <Typography sx={{ fontWeight: 500, mt: 2 }}>
                  C# / .Net / JAVA / Software Testing
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    mt: 2,
                    color: theme?.palette?.primary?.textOrange,
                  }}
                >
                  View 2 openings
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  Finance & Accounting
                </Typography>
                <Typography sx={{ fontWeight: 500, mt: 2 }}>
                  Report Generation / Accounts Handling / Tally
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    mt: 2,
                    color: theme?.palette?.primary.textOrange,
                  }}
                >
                  View 1 opening
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  Human Resources
                </Typography>
                <Typography sx={{ fontWeight: 500, mt: 2 }}>
                  Report Generation / Accounts Handling / Tally
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    mt: 2,
                    color: theme?.palette?.primary.textOrange,
                  }}
                >
                  View 1 opening
                </Typography>
              </CardContent>
            </Card>
          </Slider>
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid",
          borderColor: theme?.palette?.primary?.borderLineMid,
          mt: "4rem",
          mb: 2,
          borderRadius: "8px",
          padding: "1rem",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "600" }}>
          Benefits reported by employee
        </Typography>

        <Box
          sx={{
            mt: 1,
            px: { xs: 2, sm: 3 },
          }}
        >
          {details?.organization?.benefits?.map((item: any) => {
            return (
             
              <Box display={"flex"} gap={1}>
                <img src={EllipseImage} alt="ellipse" />
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme?.palette?.primary?.textGrey,
                    mt: { xs: 0, sm: 0 },
                  }}
                >
                  {item?.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Card
        sx={{
          boxShadow: `0px 4px 12px 0px #33414D1A`,
          mt: { xs: 2, sm: 8 },
          mb: "3rem",
        }}
      >
        <CardContent
          sx={{ p: { xs: 2, sm: 3 }, paddingBottom: { xs: "16px !important" } }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "500",
              color: theme?.palette?.primary?.textGrey,
              mt: 0.85,
              mb: { sm: 3 },
            }}
          >
            {details?.organization?.organization_name} is an equal opportunity
            employer. We do not discriminate on the basis of race, color,
            religion, sex, sexual orientation, gender identity, national origin,
            age, disability, genetic information, marital status, or veteran
            status. We are committed to fostering an inclusive workplace where
            all individuals are valued and respected. Join us in our commitment
            to diversity, equity, and inclusion.
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default EmployerOverview;
