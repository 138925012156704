import React, { useEffect, useState } from "react";
import EmployerProfileImage from "asset/images/employer-profile-image.svg";
import RImage from "asset/images/r-image.svg";
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import EllipseImage from "asset/images/ellipse-image.svg";
import LocationImage from "asset/images/location.svg";
import Notification from "asset/images/notification.svg";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Jobs from "./jobs";

import Life from "./life";
import { InputField } from "forms/hoc/formfield";
import SearchIcon from "asset/icons/search.svg";
import { useNavigate, useParams } from "react-router-dom";

import EmployerOverview from "./overview";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import NoCompanyImage from "asset/images/no-company.svg";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import { useDebounce } from "forms/hooks/useDebounce";

const EmployerDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = useState("jobs");
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector((state) => state?.user?.user);
  const [branding, setBranding] = useState("");
  const [jobsCount, setJobsCount] = useState(0);

  const [profileDetails, setProfileDetails] = useState<any>(null);
  const [employerDetails, setEmployerDetails] = useState<any>(null);
  const { employer_id } = useParams();
  const handleClick = () => {
    setIsLoading(true);
    const payload = {
      employerId: employer_id,
      candidateId: user?.id,
    };
    commonService
      .postService("/job/jobAlert", payload)
      .then((response) => {
        setIsLoading(false);
        Success(response?.data?.message);
        getEmployerDetails();
      })
      .catch((error) => {
        setIsLoading(false);
       
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleChange = () => {};
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getEmployerDetails();
  }, []);

  const getEmployerDetails = () => {
    setIsLoading(true);
    let params: any = {
      page: 1,
      per_page: 10,
      sort_order: "ASC",
      sort_by: "created_at",
    };

    const payload = {
      company: [employer_id],
    };

    commonService
      .postServiceWithParams(`employer/employers`, payload, params)
      .then((response) => {
        setIsLoading(false);
        setEmployerDetails(response?.data?.data?.results[0]);
        setBranding(response?.data?.data?.results[0]?.organization?.branding);
      
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ py: 2, display: { sm: "none", xs: "block" } }}
      >
        <Link
          underline="hover"
          color="inherit"
          sx={{
            cursor: "pointer",
            color: `${
              branding ? branding : theme?.palette?.primary?.main
            } !important`,
          }}
          onClick={() => {
            navigate("/candidate/home");
          }}
        >
          Home
        </Link>
        <Typography fontWeight={500} color={theme?.palette?.primary?.textMid}>
          Candidate - explore employer
        </Typography>
        <Typography fontWeight={500} color={theme?.palette?.primary?.textMid}>
          {employerDetails?.organization?.organization_name}
        </Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mt: "-3px",
          mx: { xs: "-2%", sm: "-7.5rem" },
          px: { xs: "2%", sm: "7.5rem" },
          backgroundImage:
            employerDetails?.organization?.is_featured &&
            employerDetails?.organization?.cover_image
              ? `url(${employerDetails?.organization?.cover_image})`
              : "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: { xs: "120px", sm: "210px" },
          position: "relative",
          backgroundColor: "#E2F2FF",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ pt: 2, display: { sm: "block", xs: "none" } }}
        >
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: "pointer",
              color: `${
                branding ? branding : theme?.palette?.primary?.main
              } !important`,
            }}
            onClick={() => {
              navigate("/candidate/home");
            }}
          >
            Home
          </Link>
          <Typography
            fontWeight={500}
            color={theme.palette.primary.textInverse}
          >
            Candidate - explore employer
          </Typography>
          <Typography
            fontWeight={500}
            color={theme.palette.primary.textInverse}
          >
            {employerDetails?.organization?.organization_name}
          </Typography>
        </Breadcrumbs>
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-150px", sm: "-140px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: { sm: "calc(100vw - 15rem)" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", sm: "center" },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", sm: "flex" },
                minWidth: { xs: "90vw", md: "0", sm: "0" },
                justifyContent: { xs: "space-between" },
              }}
            >
              <Box
                component="img"
                src={
                  employerDetails?.organization?.profile
                    ? employerDetails?.organization?.profile
                    : NoCompanyImage
                }
                alt="R image"
                sx={{
                  height: { xs: 80, sm: 180 },
                  width: { sm: 180 },
                  borderRadius: "50%",
                }}
              />
              <Box mt={6} sx={{ display: { sm: "none", xs: "block" } }}>
                {user?.id && (
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={
                      <img
                        src={Notification}
                        alt="Notification"
                        style={{ width: "24px", height: "24px" }}
                      />
                    }
                    disabled={employerDetails?.has_applied_job_alert}
                    onClick={handleClick}
                    sx={{
                      backgroundColor: branding
                        ? branding
                        : theme?.palette?.primary?.main,
                      "&:hover": {
                        backgroundColor: branding
                          ? branding
                          : theme?.palette?.primary?.main,
                      },
                    }}
                  >
                    Create a job alert
                  </Button>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: { xs: 2, sm: 6 },
                ml: 1,
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  color: theme?.palette?.primary?.textBtn,
                }}
              >
                {employerDetails?.organization?.organization_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: { xs: 1, sm: 2 },
                  gap: 1,
                }}
              >
                {employerDetails?.organization?.location?.name && (
                  <Box>
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <img src={LocationImage} alt="Location" />

                      <Typography
                        fontWeight={500}
                        fontSize={"1rem"}
                        color={theme?.palette?.primary?.textGrey}
                      >
                        {employerDetails?.organization?.location?.name}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              {employerDetails?.organization?.industries?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    mt: { xs: 1, sm: 2 },
                    gap: 2,
                  }}
                >
                  {employerDetails?.organization?.industries?.map(
                    (industry: any, index: number) => {
                      return (
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          {index > 0 && (
                            <img src={EllipseImage} alt="ellipse" />
                          )}
                          <Typography
                            fontWeight={500}
                            fontSize={"1rem"}
                            color={theme?.palette?.primary?.textGrey}
                          >
                            {industry?.name}
                          </Typography>
                        </Box>
                      );
                    }
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box mt={2} sx={{ display: { sm: "block", xs: "none" } }}>
            {user?.id && (
              <Button
                size="small"
                variant="contained"
                endIcon={
                  <img
                    src={Notification}
                    alt="Notification"
                    style={{ width: "24px", height: "24px" }}
                  />
                }
                disabled={employerDetails?.has_applied_job_alert}
                sx={{
                  backgroundColor: branding
                    ? branding
                    : theme?.palette?.primary?.main,
                  "&:hover": {
                    backgroundColor: branding
                      ? branding
                      : theme?.palette?.primary?.main,
                  },
                }}
                onClick={handleClick}
              >
                Create a job alert
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: { xs: "160px", sm: "130px" } }}>
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mt: 1.87,
                flex: "10px",
                "& .MuiTabs-indicator": {
                  backgroundColor: branding
                    ? branding
                    : theme?.palette?.primary?.main,
                },
              }}
            >
              <Tab label={`Jobs(${jobsCount})`} value="jobs" />
              <Tab label="Overview" value="overview" />
              {employerDetails?.organization?.is_featured && (
                <Tab label="Life" value="life" />
              )}
            </TabList>
            {value === "jobs" ? (
              <Box sx={{ display: { sm: "block", xs: "none" } }}>
                <InputField
                  values={search}
                  name="search"
                  errors={""}
                  handleChange={($e) => {
                    setSearch($e?.target?.value);
                  }}
                  placeholder="Search"
                  id="search"
                  adorament="startAdornment"
                  position="start"
                  Icon={<img src={SearchIcon} alt="Search" />}
                />
              </Box>
            ) : null}
          </Box>
          <TabPanel value="jobs" sx={{ p: { xs: 1, sm: 2, lg: 3 } }}>
            <Jobs
              searchText={debouncedSearchTerm}
              branding={branding}
              onUpdateJobsCount={(count: number) => {
                setJobsCount(count);
              }}
            />
          </TabPanel>
          <TabPanel value="overview" sx={{ p: { xs: 1, sm: 2, lg: 3 } }}>
            <EmployerOverview details={employerDetails} branding={branding} />
          </TabPanel>
          <TabPanel value="life" sx={{ p: { xs: 1, sm: 2, lg: 3 } }}>
            <Life details={employerDetails} branding={branding} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default EmployerDetails;
