// theme constant
export const gridSpacing = 3;
export const drawerWidth = 270;
export const appDrawerWidth = 320;

export const role = ["admin", "candidate", "employer"];
export const roles = {
  admin: "admin",
  candidate: "candidate",
  employer: "employer",
};


