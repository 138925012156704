import {
  Box,
  Divider,
  TextField,
  Typography,
  Modal,
  IconButton,
  useTheme,
} from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import React, { useState } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import CloseIcon from "@mui/icons-material/Close";
import IconBack from "asset/icons/backIcon.svg";
import { commonService } from "forms/helpers/common.service";
import { useAppSelector } from "store/store";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";

const SetTheme = ({ handleClose }: { handleClose: () => void }) => {
  const theme = useTheme();
  const [color, setColor] = useState("#fff");
  const [open, setOpen] = useState(false);
  const [modalopen, setModalOpen] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector((state) => state?.user?.user);
  const handleChangeComplete = (color: {
    hex: React.SetStateAction<string>;
  }) => {
    setColor(color.hex);
    setPrimaryColor(color.hex);
    setSelectedColor(color.hex);
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };
  const handleCancel = () => {
 
  };
  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleColorClick = () => {
    setModalOpen(true);
  };
  const handleSelectColor = (color: { hex: React.SetStateAction<string> }) => {
    setSelectedColor(color.hex);
  };

  const onApplyColor = () => {
    setIsLoading(true);
    const payload = {
      branding: selectedColor,
    };
    commonService
      .patchService(`/employer/${user?.user?.id}/branding`, payload)
      .then((response) => {
        setIsLoading(false);
        Success("Branding color updated successfully!");
        handleClose();
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        {primaryColor && (
          <IconButton
            sx={{
              position: "absolute",
           
              left: 0,
              top: -6,
            }}
            onClick={() => {
              setPrimaryColor("");
              setSelectedColor("");
            }}
          >
            <img src={IconBack} alt="Back" />
          </IconButton>
        )}
        <Typography variant="h3" sx={{ fontWeight: "600" }}>
          Change colour
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      {!primaryColor && (
        <Box>
          <Box sx={{ marginTop: "20px" }}>
            <CirclePicker onChange={handleChangeComplete} />
          </Box>
        
        </Box>
      )}

      {primaryColor && (
        <SketchPicker
          color={selectedColor}
          onChangeComplete={handleSelectColor}
        />
      )}

      <Box
        sx={{
          display: "flex",
          gap: 3,
          alignItems: "center",
          mt: 3,
          justifyContent: "space-between",
        }}
      >
        <OutlinedButton
          label="Cancel"
          size="small"
          isBold={true}
          handleClick={handleClose}
        />

        <ContainedButton
          label="Apply"
          size="small"
          isBold={true}
          handleClick={onApplyColor}
          isDisabled={selectedColor === "" ? true : false}
        />
      </Box>
    </Box>
  );
};

export default SetTheme;
