import { Box, Grid, Stack } from "@mui/material";
import PaginationComponent from "component/pagination";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import JobCard from "forms/hoc/job-card";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";

const ArchivedJobs = ({ searchText, onSearchJobs }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobsList, setJobsList] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(9);
  const [sortBy, setSortBy] = useState("");
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const user = useAppSelector((state) => state?.user?.user);

  useEffect(() => {
    getAllJobs();
  }, [page, searchText]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getAllJobs = () => {
    setIsLoading(true);
    let params: any = {
      page: page + 1,
      per_page: perPage,
      sort_order: "DESC",
      search_by: searchText
        ? "employer_id.organization_name,title,category.name,skills.name"
        : "",
      search: searchText,
      employerId: user?.user?.id,
    };

    const payload: any = {
      status: ["archived"],
    };

    commonService
      .postServiceWithParams(`job/jobs-list`, payload, params)
      .then((response) => {
        setIsLoading(false);
        setJobsList(response?.data?.data?.results);
        setTotal(response?.data?.data?.pagination?.total);
        onSearchJobs(response?.data?.data?.pagination?.total);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Box mb={{ sm: 3 }}>
        <Stack spacing={3}>
          {jobsList.map((job: any) => {
            return (
              <JobCard
                isHomeFeatured={true}
                isFullWidth={true}
                job={{ ...job, application_status: "archived" }}
                isSavedJob={true}
                isMyJob={true}
              />
            );
          })}
          <Box display={"flex"} justifyContent={"end"}>
            <PaginationComponent
              page={page}
              rowsPerPage={perPage}
              total={total}
              onChange={($e: any, newPage: any) => {
                handleChangePage($e, newPage);
              }}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ArchivedJobs;
