import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { InputField } from "forms/hoc/formfield";
import { ContainedButton, OutlinedButton } from "component/button";

import { DeleteAccountValidation } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";

const DeleteAccount = ({
  deleteAccountOpen,
  handleDeleteAccountClose,
  handleDeleteAccountSubmit,
}: {
  deleteAccountOpen: boolean;
  handleDeleteAccountClose: () => void;
  handleDeleteAccountSubmit: (id: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const initialValues = {
    reason: null,
    feedback_content: null,
  };

  const handleSubmitReason = () => {
    const payload = {
      ...values,
      action: "delete",
    };

    commonService
      .postService(`candidate/deactivate`, payload)
      .then(async (response) => {
        setIsLoading(false);
        resetForm();
    
        handleDeleteAccountSubmit(response?.data?.data?._id);
        handleDeleteAccountClose();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    handleSubmit,
    setPatchValue,
    resetForm,
  } = useForm(initialValues, handleSubmitReason, DeleteAccountValidation);



  return (
    <>
      <Box sx={{ mt: 2 }}>
       
        <Dialog open={deleteAccountOpen}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <DialogTitle
              variant="h3"
              sx={{
                textAlign: "center",
                flexGrow: 1,
                color: theme.palette.primary.textBtn,
              }}
            >
              Delete account
            </DialogTitle>
            <IconButton
              onClick={handleDeleteAccountClose}
              sx={{
                fontSize: "20px",
                color: theme.palette.primary.iconOrange,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography variant="h5" color={theme.palette.primary.textMid}>
                We are sorry to see you go. Deleting your account will lose all
                the data and profiles created.
              </Typography>
              <Typography
                variant="h4"
                color={theme.palette.primary.textBtn}
                marginTop={2}
              >
                Tell us why you want to Delete your account
              </Typography>
              <Box sx={{ marginTop: 1 }}>
                <FormControl>
                  <RadioGroup
                    name="reason"
                    value={values?.reason}
                    onChange={($e) => {
                      setUpdateValue("reason", $e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="account"
                      control={<Radio />}
                      label="I have a duplicate account"
                    />
                    <FormControlLabel
                        value="I got a job through Ekam"
                        control={<Radio />}
                        label="I got a job through Ekam"
                    />
                    <FormControlLabel
                        value="I got a job from other sources"
                        control={<Radio />}
                        label="I got a job from other sources"
                    />
                    <FormControlLabel
                        value="I'm not getting the right jobs"
                        control={<Radio />}
                        label="I'm not getting the right jobs"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors?.reason}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                variant="h4"
                color={theme.palette.primary.textBtn}
                marginTop={1}
                marginBottom={2}
              >
                Your Valuable Feedback Will Help Us to Improve Better.
              </Typography>
              <InputField
                values={values?.feedback_content}
                handleChange={handleChange}
                name="feedback_content"
                errors={errors?.feedback_content}
                multiline={true}
                minRow={3}
                helperText={`${values?.feedback_content?.length || "0"}/150`}
                length={150}
                isRequired={false}
                id=""
              />
            </DialogContent>
            <DialogActions>
              <Box
                display={"flex"}
                justifyContent={"end"}
                mb={2}
                marginRight={2}
              >
                <OutlinedButton
                  size={"small"}
                  label={"cancel"}
                  isBold={true}
                  handleClick={() => {
                    resetForm();
                    handleDeleteAccountClose();
                  }}
                />
                <Box sx={{ mx: 1 }} />
                <ContainedButton
                  size="large"
                  isBold={true}
                  label={"Delete"}
                  type="submit"
                />
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default DeleteAccount;
