import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Sidebar from "./Sidebar";
import { drawerWidth } from "../../../features/theme/constant";
import { SET_MENU } from "../../../features/theme/actions";
import Header from "./Header";
import { useAppSelector } from "store/store";
import { useState } from "react";
import Footer from "./Footer";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({
    theme,
    open,
    subMenuList,
  }: {
    theme: any;
    open: any;
    subMenuList: boolean;
  }) => ({
    ...theme.typography.mainContent,

    minHeight: `calc(100vh - ${118}px)`,
    marginTop: "116px",

    marginRight: "7.5rem",
    marginLeft: "7.5rem",

    [theme.breakpoints.down("md")]: {
      marginTop: "47px",
      marginRight: "2%",
      marginLeft: "2%",
    },
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = useState();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: any) => state.customization.opened
  );
  const subMenuList = useAppSelector((state) => state.user.subMenuItems);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.primary.textInverse,
      }}
    >
      <CssBaseline />
      {/* drawer */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
          bgcolor: theme.palette.primary?.textInverse,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
      </AppBar>

      {matchDownMd && (
        <Sidebar
          window={window}
          drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />
      )}

      <Main theme={theme} open={true} subMenuList={!!subMenuList?.length}>
        <Outlet />
        {pathSegments[1] !== "register" && pathSegments[1] !== "login" && (
          <Footer />
        )}
      </Main>
    </Box>
  );
};

export default MainLayout;
