/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import SearchIcon from "asset/icons/search.svg";
import { commonService } from "forms/helpers/common.service";
import AddIcon from "@mui/icons-material/Add";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";

export default function AutocompleteInput({
  setOptions,
  options,
  setValue,
  value,
  errors,
  placeholder,
  masterType,
  disabled,
}: any) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: any, newInputValue: any) => {
    setInputValue(newInputValue);
  };

  const handleNewSkill = async (newSkillName: any) => {
    try {
      const response = await commonService.postService(
        `/master/${masterType}`,
        {
          name: newSkillName,
        }
      );
      const newSkill = {
        name: response.data.data?.name,
        value: response.data.data?._id,
      };
      setOptions((prevSkills: any) => [...prevSkills, newSkill]);
      const newSkillsSet = [...value, newSkill];
      setValue(newSkillsSet);
    } catch (error) {
      Failed(
        errorMessage(error, "Something went wrong, Please try again later")
      );
    }
  };

  const handleAddClick = () => {
    if (
      inputValue &&
      !options?.find((skill: any) => skill.name === inputValue)
    ) {
      handleNewSkill(inputValue);
    }
  };

  const onDelete = (name: any) => () => {
    const newSkills = value.filter((v: any) => v.name !== name);
    setValue(newSkills);
  };

  return (
    <Box sx={{ maxWidth: 500 }}>
      <Autocomplete
        multiple
        options={options ? options : []}
        getOptionLabel={(option: any) => option?.name}
        value={value}
        defaultValue={value}
        onChange={(e, newValue: any) => setValue(newValue)}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        onInputChange={handleInputChange}
        renderTags={() => null}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={Boolean(errors)}
            placeholder={placeholder}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <img src={SearchIcon} alt="search" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {inputValue &&
                    !options?.some(
                      (skill: any) => skill.name === inputValue
                    ) && (
                      <Tooltip title="add skill">
                        <IconButton onClick={handleAddClick}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                display: "flex",
                alignContent: "center", 
              },
            }}
          />
        )}
      />
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
      <Box
        mt={3}
        sx={{
          "& > :not(:last-child)": { mr: 1 },
          "& > *": { mr: 1 },
        }}
      >
        {value?.map((v: any) => (
          <Chip
            sx={{ mt: 1 }}
            key={v.name}
            label={v.name}
            onDelete={onDelete(v.name)}
            disabled={disabled}
          />
        ))}
      </Box>
    </Box>
  );
}
