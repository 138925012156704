import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "ui-component/Loader";
import Minus from "asset/icons/minus.svg";
import parse from "html-react-parser";
import {
  ContainedButton,
  OutlinedButton,
  OutlinedIconButton,
} from "component/button";
import { useAppSelector } from "store/store";
import ChatUser1 from "asset/images/chat/chat-user-1.svg";
import EnhancedTable from "component/tables/selectableTable";
import { InputField } from "forms/hoc/formfield";
import SearchIcon from "asset/icons/search.svg";
import FilterIcon from "asset/icons/filter.svg";
import { useDebounce } from "forms/hooks/useDebounce";
import StarImage from "asset/icons/star.svg";

const MyJobDetails = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState<any>();
  const { id } = useParams();
  const [value, setValue] = useState("applicants");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  let [order, setOrder] = useState<any>("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState(0);
  let [applicants, setApplicants] = useState<any>();
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const user = useAppSelector((state) => state?.user?.user);
  const [hasResume, setHasResume] = useState(false);
  const [hasCover, setHasCover] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getLocationsString = (locations: any) => {
    return locations?.map((location: any) => location?.name).join(", ");
  };

  useEffect(() => {
    if (id) {
      getJobDetails();
    }
  }, []);

  useEffect(() => {
    getJobApplicants();
  }, [rowsPerPage, page, orderBy, order]);

  const headCell = [
    {
      id: "candidate_id.first_name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      isSort: true,
      shouldRedirect: true,
    },
    {
      id: "candidate_id.work_experience",
      numeric: false,
      disablePadding: false,
      label: "Experience",
      isSort: true,
    },
    {
      id: "candidate_id.service.value",
      numeric: false,
      disablePadding: false,
      label: "Service",
      isSort: true,
    },
    {
      id: "candidate_id.rank.name",
      numeric: false,
      disablePadding: false,
      label: "Rank",
      isSort: true,
    },
    {
      id: "candidate_id.branch.name",
      numeric: false,
      disablePadding: false,
      label: "Branch",
      isSort: true,
    },
    {
      id: "candidate_id.current_location.city",
      numeric: false,
      disablePadding: false,
      label: "Location",
      isSort: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isSort: true,
      styling: {
        applied: {
          background: "#F0F4FA",
          color: "#15171E",
        },
        rejected: {
          background: "#FFEEEE",
          color: "#F40000",
        },
        shortlisted: {
          background: "#E4F3FF",
          color: "#2F80ED",
        },
        screened: {
          background: "#ffff8d",
          color: "#ffab00",
        },
        hired: {
          background: "#E4FFFE",
          color: "#00B066",
        },
        interviewed: {
          background: "#e1bee7",
          color: "#6a1b9a",
        },
      },
    },
  ];
  const actionFunction = (applicant_id: string, actionText: string, i: any) => {
  
    const data: any = applicants.find((res: any) => applicant_id === res?.id);
    const user_id = data?.user_id;

    if (actionText === "candidate_details") {
      navigate(`/employer/my-jobs/applicant/${i?.id}`, {
        state: { application_id: i?.id, status: i?.status, job_id: id },
      });
    }
  };

  /* per page row handle */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getJobDetails = () => {
    setIsLoading(true);
    commonService
      .getServices(`job/${id}`)
      .then((response) => {
        setIsLoading(false);
        setJobDetails(response?.data?.data?.job[0]);
        setHasResume(
          response?.data?.data?.job[0]?.documents?.find(
            (resume: any) => resume?.name === "Resume"
          )
        );
        setHasCover(
          response?.data?.data?.job[0]?.documents?.find(
            (resume: any) => resume?.name === "Cover Letter"
          )
        );
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  function getJobApplicants() {
    setIsLoading(true);
    const params = {
      per_page: rowsPerPage,
      page: page + 1,
      sort_order: order,
      sort_by: orderBy,
      employerId: user?.user?.id,
      search: debouncedSearchTerm,
      search_by:
        "candidate_id.first_name,candidate_id.current_location.city,candidate_id.branch.name,candidate_id.service.value,status",
    };
    commonService
      .getServiceWithParams(`job/${id}/applications`, params)
      .then((res) => {
        setIsLoading(false);
        const data = res?.data?.data?.results.map((item: any) => {
          return {
            id: item?._id,
            "candidate_id.first_name":
              item?.candidate_id?.first_name +
              " " +
              item?.candidate_id?.last_name,
            image: ChatUser1,
            candidate_id: item?.candidate_id?.candidate_id,
            candidate_db_id: item?.candidate_id?._id,
            profile_image: item?.candidate_id?.profile,
            "candidate_id.work_experience":
              item?.candidate_id?.work_experience + " years",
            "candidate_id.service.value": item?.candidate_id?.service?.value,
            "candidate_id.rank.name": item?.candidate_id?.rank?.name,
            "candidate_id.branch.name": item?.candidate_id?.branch?.name,
            "candidate_id.current_location.city":
              item?.candidate_id?.current_location?.city,
            status: item?.status,
          };
        });
        setApplicants(data);
        setCount(res?.data?.data?.pagination?.total);
      })
      .catch((err) => {
        setIsLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, Please try again later")
        );
      });
  }

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ marginBottom: "1rem", marginTop: "2rem", fontWeight: "500" }}
      >
        <Link
          variant="body2"
          underline="hover"
          color="inherit"
          onClick={() => {
            navigate("/employer/home");
          }}
          sx={{ marginRight: 2, fontWeight: "500" }}
        >
          Home
        </Link>
        <Link
          variant="body2"
          underline="hover"
          color="inherit"
          onClick={() => {
            navigate("/employer/my-jobs");
          }}
          sx={{
            marginRight: 2,
            marginLeft: 2,
            fontWeight: "500",
            cursor: "pointer",
          }}
        >
          My Jobs
        </Link>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            marginRight: 2,
            marginLeft: 2,
            fontWeight: "500",
            cursor: "pointer",
          }}
        >
          {jobDetails?.title}{" "}
        </Typography>
      </Breadcrumbs>
      <Card sx={{ boxShadow: "0px 2px 8px 0px #0000000D" }}>
        <CardContent>
          <Typography
            sx={{ fontWeight: "500", color: "#616B73", mb: "0.85rem" }}
          >
            Basic info
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: "600", color: "#15171E" }}>
            {jobDetails?.title}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              mt: "1rem",
            }}
          >
            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Job category
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {jobDetails?.category?.name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Salary package
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {`${jobDetails?.salary_low} - ${jobDetails?.salary_high}`} LPA
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Required experience
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {`${jobDetails?.experience_low} - ${jobDetails?.experience_high}`}{" "}
                years
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Job type
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {jobDetails?.type?.name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Openings
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {jobDetails?.opening}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Apply before
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {formatDateSring(jobDetails?.target_date)}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: "1rem" }}></Divider>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "500", color: "#616B73" }}>
                Locations:
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 500, color: "#15171E", ml: 1 }}
              >
                {jobDetails?.job_location?.length > 0
                  ? getLocationsString(jobDetails?.job_location)
                  : "N/A"}
              </Typography>
            </Box>
            {jobDetails?.is_featured && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: theme.palette.primary.chipyellow,
                  borderRadius: "12px",
                  padding: "4px 8px",
                  gap: 0.75,
                }}
              >
                <img src={StarImage} alt={"StarImage"} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#4A5568",
                    fontSize: "0.875rem",
                  }}
                >
                  Featured
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <TabContext value={value}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={{ xs: "start", sm: "center" }}
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box
            sx={{
              flex: 1,
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
            
              mt: 3,
            }}
          >
            <TabList
              variant="scrollable"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  height: "3px",
                },
              }}
            >
              <Tab label="Job details" value="details" />
              <Tab label={`Job applicants(${count || 0})`} value="applicants" />
            </TabList>
          </Box>
          {value === "applicants" && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: { xs: "flex-end" },
              }}
            >
              <InputField
                values={search}
                name="search"
                errors={""}
                handleChange={($e) => {
                  setPage(0);
                  setSearch($e?.target?.value);
                }}
                placeholder="Search"
                id="search"
                adorament="startAdornment"
                position="start"
                Icon={<img src={SearchIcon} alt="Search" />}
              />
            </Box>
          )}
        </Box>
        <TabPanel value="details" sx={{ py: 2.8, px: 0 }}>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 3,
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "1px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Job description
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  mt: "1rem",
                  ml: "2.75rem",
                }}
              >
                {jobDetails?.description && parse(jobDetails?.description)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "2rem",
                  gap: "1rem",
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Roles and responsibilities
                </Typography>
              </Box>
              <Box sx={{ ml: "2.75rem" }}>
                {jobDetails?.responsibility
                  ? parse(jobDetails?.responsibility || "")
                  : "No roles and responsibilities"}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Required qualifications
                </Typography>
              </Box>
              <Box sx={{ ml: "2.75rem" }}>
                {jobDetails?.qualification
                  ? parse(jobDetails?.qualification || "")
                  : "No qualifications"}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Skills and competencies
                </Typography>
              </Box>
              {jobDetails?.benefits?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "1rem",
                    ml: "2.75rem",
                  }}
                >
                  {jobDetails?.skills.map((skill: any) => {
                    return (
                      <Chip
                        label={skill?.name}
                        sx={{
                          backgroundColor: "#F0F4FA",
                          color: "#000000",
                          fontWeight: "500",
                          margin: "4px",
                        }}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "1rem",
                    ml: "2.75rem",
                  }}
                >
                  No skills
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Benefits & Perks
                </Typography>
              </Box>
              {jobDetails?.benefits?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "1rem",
                    ml: "2.75rem",
                  }}
                >
                  {jobDetails?.benefits.map((benefit: any) => {
                    return (
                      <Chip
                        label={benefit?.name}
                        sx={{
                          backgroundColor: "#F0F4FA",
                          color: "#000000",
                          fontWeight: "500",
                          margin: "4px",
                        }}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "1rem",
                    ml: "2.75rem",
                  }}
                >
                  {" "}
                  No benefits
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Documents required
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "1rem",
                  ml: "2.75rem",
                }}
              >
                {hasResume && (
                  <Chip
                    label="Resume"
                    sx={{
                      backgroundColor: "#F0F4FA",
                      color: "#000000",
                      fontWeight: "500",
                      margin: "4px",
                    }}
                  />
                )}
                {hasCover && (
                  <Chip
                    label="Cover letter"
                    sx={{
                      backgroundColor: "#F0F4FA",
                      color: "#000000",
                      fontWeight: "500",
                      margin: "4px",
                    }}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value="applicants" sx={{ py: 2.8, px: 0 }}>
          <Box>
            <EnhancedTable
              headCells={headCell}
              rows={applicants}
              total={count}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              
              isEdit={false}
              isView={false}
              isSelectable={false}
              isLoading={isLoading}
              actionFunction={actionFunction}
              isMoreVert={true}
             
              isPagination={true}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default MyJobDetails;

const formatDateSring = (dateString: any) => {
  const date = new Date(dateString);

  // Options for toLocaleString
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit", // Include day with 2 digits
  };

  return date.toLocaleString("en-US", options);
};
