import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { spotilightsValidationSchema } from "forms/hooks/validateRules";
import CloseIcon from "@mui/icons-material/Close";
import { commonService } from "forms/helpers/common.service";
import { useAppSelector } from "store/store";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";

const Spotlight = ({
  action,
  openSpotlightsDialog,
  handleCloseSpotlights,
  getData,
  spotlightData,
}: any) => {
  const user = useAppSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(false);
  const handleUpdateLogin = (data: any) => {

    const { id, ...valuesId } = values;
   
    if (action === "add") {
      commonService
        .postService(`employer/${user?.user?.id}/spotlight`, valuesId)
        .then((response) => {
          setIsLoading(false);
          Success(response?.data?.message);
          getData();
          resetForm();
          handleCloseSpotlights();
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(errorMessage("Something went wrong!", error));
        });
    } else if (action === "edit") {
      commonService
        .patchService(
          `employer/${user?.user?.id}/spotlight/${spotlightData._id}`,
          valuesId
        )
        .then((response) => {
          setIsLoading(false);
          Success(response?.data?.message);
          getData();
          resetForm();
          handleCloseSpotlights();
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(errorMessage("Something went wrong!", error));
        });
    }
  };

  useEffect(() => {
 
    if (action === "edit") {
      setPatchValue({
        ...spotlightData,
      });
    } else {
      setPatchValue({});
    }
  }, [spotlightData]);

  const spotlightValues = {
    video: null,
    heading: null,
    description: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setPatchValue,
    resetForm,
  } = useForm(spotlightValues, handleUpdateLogin, spotilightsValidationSchema);

  return (
    <Dialog open={openSpotlightsDialog} maxWidth="sm" fullWidth>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: "24px",
              color: "#15171E",
              flexGrow: 1,
            }}
          >
            {action === "add" ? "Add" : "Edit"} Spotlight
          </DialogTitle>
          <IconButton
            onClick={() => {
              resetForm();
              handleCloseSpotlights();
            }}
            aria-label="close"
            sx={{ color: "#AB5B0B" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ p: "20px" }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <InputField
                fieldName="Video Link (Youtube)"
                values={values.video}
                name="video"
                errors={errors?.video}
                handleChange={handleChange}
                id="video"
                isRequired={false}
              />

              <InputField
                fieldName="Heading"
                values={values.heading}
                name="heading"
                errors={errors?.heading}
                handleChange={handleChange}
                id="heading"
                isRequired={false}
                length={50}
              />
              <InputField
                values={values.description || ""}
                fieldName="Description (150 words)"
                handleChange={handleChange}
                name="description"
                errors={errors.description}
                multiline={true}
                minRow={3}
                length={150}
                isRequired={false}
                id="description"
              />
            </Stack>
            <Box display="flex" justifyContent="end" mt={2}>
              <OutlinedButton
                size="small"
                label="Cancel"
                isBold={true}
                handleClick={handleCloseSpotlights}
              />
              <Box sx={{ mx: 1 }} />

              <ContainedButton
                size="large"
                isBold={true}
                label={action === "add" ? "Submit" : "Update"}
                type="submit"
              />
            </Box>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default Spotlight;
