import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Modal, useTheme } from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { leaderValidationSchema } from "forms/hooks/validateRules";
import CloseIcon from "@mui/icons-material/Close";
import { commonService } from "forms/helpers/common.service";
import { useAppSelector } from "store/store";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import ProfileImage from "asset/icons/no-profile.svg";
import EditIcon from "asset/icons/edit-orange.svg";
import { readFile } from "helpers/function.helper";
import ImageUploadWithCrop from "component/HOC/image-upload";
import Loader from "ui-component/Loader";

const Leader = ({
  action,
  openLeadersDialog,
  handleCloseLeaders,
  getData,
  leadersData,
}: any) => {
  const theme = useTheme();
  const user = useAppSelector((state: any) => state?.user?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [newLeaderImage, setNewLeaderImage] = useState<any>(null);
  const [profileImage, setProfileImage] = useState(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imageUrl, setImageUrl] = useState<any>("");
  const childRef: any = useRef();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    width: "300px",
  };

  const handleCreateIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpdateLeader = (data: any) => {
    if (action === "add") {
      if (profileImage) {
        commonService
          .postService(`employer/${user?.user?.id}/leaders`, values)
          .then((response) => {
            setIsLoading(false);
            Success(response?.data?.message);

            if (profileImage) {
              let profileData: FormData = new FormData();
              profileData.append("profile", profileImage);

              const leaderId =
                response?.data?.data?.leaders[
                  response?.data?.data?.leaders?.length - 1
                ]._id;

              commonService
                .postService(
                  `/employer/${user?.user?.id}/leaders/files?leaderId=${leaderId}`,
                  profileData
                )
                .then((response: any) => {
                  getData();
                  resetForm();
                  setProfileImage(null);
                  setImageUrl("");
                  setNewLeaderImage("");
                  handleCloseLeaders();
                })
                .catch((error) => {
                  setIsLoading(false);
                  Failed(
                    errorMessage(
                      error,
                      "Something went wrong, Please try again later"
                    )
                  );
                });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            Failed(errorMessage("Something went wrong!", error));
          });
      } else {
        Failed("Please upload leader image!");
      }
    } else if (action === "edit") {
      commonService
        .patchService(
          `employer/${user?.user?.id}/leaders/${leadersData._id}`,
          values
        )
        .then((response) => {
          setIsLoading(false);
          Success(response?.data?.message);
          if (profileImage) {
            let profileData: FormData = new FormData();
            profileData.append("profile", profileImage);
            commonService
              .postService(
                `/employer/${user?.user?.id}/leaders/files?leaderId=${leadersData._id}`,
                profileData
              )
              .then((response: any) => {
                getData();
                resetForm();
                setProfileImage(null);
                setImageUrl("");
                setNewLeaderImage("");
                handleCloseLeaders();
              })
              .catch((error) => {
                setIsLoading(false);
                Failed(
                  errorMessage(
                    error,
                    "Something went wrong, Please try again later"
                  )
                );
              });
          } else {
            getData();
            resetForm();
            setProfileImage(null);
            setImageUrl("");
            setNewLeaderImage("");
            handleCloseLeaders();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(errorMessage("Something went wrong!", error));
        });
    }
  };

  useEffect(() => {
    if (action === "edit") {
      setPatchValue({
        ...leadersData,
      });
      setNewLeaderImage(leadersData?.profile);
    } else {
      setPatchValue({});
    }
  }, [leadersData]);

  const leaderValues = {
    first_name: null,
    last_name: null,
    designation: null,
    linkedIn: null,
    intro: null,
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      let imageDataUrl = await readFile(file);
      setImageUrl(imageDataUrl);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleApplyClick = async () => {
    if (childRef.current) {
      const result = await childRef.current.showCroppedImage();
      setImageUrl("");
      setNewLeaderImage(URL.createObjectURL(result));
      setProfileImage(result);
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setUpdateValue,
    setPatchValue,
    resetForm,
  } = useForm(leaderValues, handleUpdateLeader, leaderValidationSchema);

  return (
    <Box>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit}>
        <Box
          display={"flex"}
          gap={{ xs: 0, sm: 3 }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <Box position="relative">
              <Box
                component={"img"}
                height={120}
                width={120}
                src={newLeaderImage ? newLeaderImage : ProfileImage}
                alt="Profile Image"
                sx={{
                  backgroundColor: "#F0F8FF",
                  borderRadius: "50%",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 85,
                  right: 0,
                  backgroundColor: "#192732",
                  borderRadius: "50%",
                  padding: "10px",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" }, // or "row" depending on your needs
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleCreateIconClick}
              >
                <img
                  src={EditIcon}
                  alt="Create Icon"
                  style={{ width: "15px", height: "15px" }}
                />
              </Box>
              <input
                type="file"
                ref={fileInputRef}
                accept="image/gif, image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <Modal
                open={imageUrl !== ""}
                onClose={() => {
                  setImageUrl("");
                }}
              >
                <Box sx={style}>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "2px",
                      top: "1px",
                    }}
                    onClick={() => {
                      setImageUrl("");
                    }}
                  >
                    <CloseIcon
                      sx={{
                        fontSize: "20px",
                        color: theme.palette.primary.iconOrange,
                      }}
                    />
                  </IconButton>
                  <Box>
                    <ImageUploadWithCrop
                      imageUrl={imageUrl}
                      ref={childRef}
                      aspect={4 / 4}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      alignItems: "center",
                      mt: 3,
                      justifyContent: "space-between",
                    }}
                  >
                    <OutlinedButton
                      label="Cancel"
                      size="small"
                      isBold={true}
                      handleClick={() => {
                        setImageUrl("");
                        setNewLeaderImage("");
                        setProfileImage(null);
                      }}
                    />

                    <ContainedButton
                      label="Apply"
                      size="small"
                      isBold={true}
                      handleClick={handleApplyClick}
                    />
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputField
                fieldName="First name"
                values={values.first_name}
                name="first_name"
                errors={errors?.first_name}
                handleChange={handleChange}
                id="first_name"
                isRequired={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fieldName="Last name"
                values={values.last_name}
                name="last_name"
                errors={errors?.last_name}
                handleChange={handleChange}
                id="last_name"
                isRequired={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fieldName="Designation"
                values={values.designation}
                name="designation"
                errors={errors?.designation}
                handleChange={handleChange}
                id="designation"
                isRequired={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fieldName="Link to Linkedin"
                values={values.linkedIn}
                name="linkedIn"
                errors={errors?.linkedIn}
                handleChange={handleChange}
                id="linkedIn"
                isRequired={false}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                values={values.intro || ""}
                fieldName="Short Intro (150 words)"
                handleChange={handleChange}
                name="intro"
                errors={errors.intro}
                multiline={true}
                minRow={3}
                length={150}
                isRequired={false}
                id="intro"
              />
            </Grid>
          </Grid>
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={2}>
          <OutlinedButton
            size={"small"}
            label={"cancel"}
            isBold={true}
            handleClick={handleCloseLeaders}
          />
          <Box sx={{ mx: 1 }} />
          <ContainedButton
            size="large"
            isBold={true}
            label={action === "add" ? "Submit" : "Update"}
            type="submit"
          />
        </Box>
      </form>
    </Box>
  );
};

export default Leader;
