import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import Minus from "asset/icons/minus.svg";
import { ContainedButton, OutlinedButton } from "component/button";
import { formatDate } from "helpers/function.helper";
import React, { useEffect, useState } from "react";
import { unstable_usePrompt, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import parse from "html-react-parser";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";
import SweetAlertComponent from "component/HOC/Alert";
import dayjs from "dayjs";
import StarImage from "asset/icons/star.svg";

const JobPreview = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [saveDraft, setSaveDraft] = useState(false);
  const [posted, setPosted] = useState(false);
  const previewData1 = useAppSelector((state) => state?.employer);
  const previewData = useAppSelector(
    (state) => state?.employer?.postJobPreview
  );
  const navigate = useNavigate();
  const user = useAppSelector((state) => state?.user?.user);
  useEffect(() => {
    if (!previewData?.title) {
      navigate("/employer/post-job");
    }
  });

  unstable_usePrompt({
    message: `Are you sure you want to leave? You haven't saved your changes.`,
    when: ({ currentLocation, nextLocation }) => {
      return (
        !previewData?.preview_only &&
        !previewData?._id &&
        !posted &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
  });

  const handlePostJob = (isDraft?: boolean) => {
    setIsLoading(true);
    let payload = {
      ...previewData,
      type: previewData?.type?.value,
      is_featured: previewData?.is_featured?.value === "yes" ? true : false,
      category: previewData?.category?.value,
      documents: [],
      employer_id: user?.user?.id,
      skills: previewData?.skills?.map((skill: any) => skill?.value),
      benefits: previewData?.benefits?.map((benefit: any) => benefit?.value),
      job_location: previewData?.job_location?.map(
        (location: any) => location?.value
      ),
    };

    if (previewData?.has_resume) {
      payload.documents.push({ name: "Resume", required: true });
    
    }

    if (previewData?.has_cv) {
      payload.documents.push({ name: "Cover Letter", required: true });
      //delete previewData.has_cv;
    }

    if (isDraft) {
      payload.status = "draft";
    }

    const { has_resume, has_cv, is_update, ...newPayload } = payload;

    if (previewData?._id) {
      payload = {
        status: "active",
      };

      if (dayjs(previewData?.target_date).isAfter(dayjs())) {
        commonService
          .patchService(`job?jobId=${previewData?._id}`, payload)
          .then((response: any) => {
            setPosted(true);
            setIsLoading(false);
            SweetAlertComponent({
              status: 200,
              title: "Posted Job Successfully",
              message:
                "Your job has been posted successfully. you can view your job posting in My job list Page.",
              confirmButtonText: "Continue to My Job Listing",
              onSuccess: () => {
                setTimeout(() => {
                  navigate("/employer/my-jobs");
                }, 2000);
              },
            });
          })
          .catch((error: any) => {
            setIsLoading(false);
            Failed(errorMessage(error, "Something went wrong!"));
          });
      } else {
        setIsLoading(false);
        Failed("Apply before date should not be less than current date.");
      }
    } else {
      commonService
        .postService("/job", newPayload)
        .then((response: any) => {
          setPosted(true);
          setIsLoading(false);
          SweetAlertComponent({
            status: 200,
            title: isDraft
              ? "Job saved as draft successfully!"
              : "Job posted successfully!",
            message:
              "Your job has been posted successfully. you can view your job posting in My job list Page.",
            confirmButtonText: "Continue to My Job Listing",
            onSuccess: () => {
              navigate("/employer/my-jobs", { state: { isDraft: isDraft } });
            },
          });
        })
        .catch((error: any) => {
          setIsLoading(false);
          Failed(errorMessage(error, "Something went wrong!"));
        });
    }
  };

  const handleUpdateJob = () => {
    setIsLoading(true);
    let payload = {
      ...previewData,
      type: previewData?.type?.value,
      category: previewData?.category?.value,
      is_featured: previewData?.is_featured?.value === "yes" ? true : false,
      documents: [],
      employer_id: user?.user?.id,
      skills: previewData?.skills?.map((skill: any) => skill?.value),
      benefits: previewData?.benefits?.map((benefit: any) => benefit?.value),
      job_location: previewData?.job_location?.map(
        (location: any) => location?.value
      ),
      status: "active",
    };

    if (previewData?.has_resume) {
      payload.documents.push({ name: "Resume", required: true });
      //delete previewData.has_resume;
    }

    if (previewData?.has_cv) {
      payload.documents.push({ name: "Cover Letter", required: true });
      //delete previewData.has_cv;
    }

    const { has_resume, has_cv, _id, is_update, employer_id, ...newPayload } =
      payload;

    commonService
      .patchService(`job?jobId=${previewData?._id}`, newPayload)
      .then((response: any) => {
        setPosted(true);
        setIsLoading(false);
        SweetAlertComponent({
          status: 200,
          title: "Job Updated Successfully",
          message:
            "Your job has been updated successfully. you can view your job update in My job list Page.",
          confirmButtonText: "Continue to My Job Listing",
          onSuccess: () => {
            setTimeout(() => {
              navigate("/employer/my-jobs");
            }, 2000);
          },
        });
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(errorMessage(error, "Something went wrong!"));
      });
  };

  const getLocationsString = (locations: any) => {
    return locations?.map((location: any) => location?.name).join(", ");
  };

  return (
    <>
      {isLoading && <Loader />}
   
      <Breadcrumbs aria-label="breadcrumb" sx={{ m: 3, mb: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/employer/home");
          }}
        >
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/employer/my-jobs");
          }}
        >
          My Jobs
        </Link>
        <Typography fontWeight={500} color="text.primary">
          {previewData?.title}{" "}
        </Typography>
      </Breadcrumbs>
      <Card sx={{ boxShadow: "0px 2px 8px 0px #0000000D" }}>
        <CardContent>
          <Typography
            sx={{ fontWeight: "500", color: "#616B73", mb: "0.85rem" }}
          >
            Basic info
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: "600", color: "#15171E" }}>
            {previewData?.title}
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{
              mt: "1rem",
            }}
          >
            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Job category
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {previewData?.category?.name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Salary package
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {`${previewData?.salary_low} - ${previewData?.salary_high}`} LPA
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Required experience
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {`${previewData?.experience_low} - ${previewData?.experience_high}`}{" "}
                years
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Job type
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {previewData?.type?.name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Openings
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {previewData?.opening}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  marginBottom: 1,
                }}
              >
                Apply before
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: "#15171E" }}
              >
                {formatDateSring(previewData?.target_date)}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: "1rem" }}></Divider>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "500", color: "#616B73" }}>
                Locations:
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 500, color: "#15171E", ml: 1 }}
              >
                {getLocationsString(previewData?.job_location)}
              </Typography>
            </Box>
            {previewData?.is_featured?.value === "yes" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: theme.palette.primary.chipyellow,
                  borderRadius: "12px",
                  padding: "4px 8px",
                  gap: 0.75,
                }}
              >
                <img src={StarImage} alt={"StarImage"} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#4A5568",
                    fontSize: "0.875rem",
                  }}
                >
                  Featured
                </Typography>
              </Box>
            )}
          </Box>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 3,
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "1px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Job description
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#616B73",
                  mt: "1rem",
                  ml: "2.75rem",
                }}
              >
                {previewData?.description && parse(previewData?.description)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "2rem",
                  gap: "1rem",
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Roles and responsibilities
                </Typography>
              </Box>
              <Box sx={{ ml: "2.75rem" }}>
                {previewData?.responsibility &&
                  parse(previewData?.responsibility || "")}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Required qualifications
                </Typography>
              </Box>
              <Box sx={{ ml: "2.75rem" }}>
                {previewData?.qualification &&
                  parse(previewData?.qualification || "")}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Skills and competencies
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "1rem",
                  ml: "2.75rem",
                }}
              >
                {previewData?.skills.map((skill: any) => {
                  return (
                    <Chip
                      label={skill?.name}
                      sx={{
                        backgroundColor: "#F0F4FA",
                        color: "#000000",
                        fontWeight: "500",
                        margin: "4px",
                      }}
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Benefits & Perks
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "1rem",
                  ml: "2.75rem",
                }}
              >
                {previewData?.benefits.map((benefit: any) => {
                  return (
                    <Chip
                      label={benefit?.name}
                      sx={{
                        backgroundColor: "#F0F4FA",
                        color: "#000000",
                        fontWeight: "500",
                        margin: "4px",
                      }}
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <img src={Minus} alt={"minus"} style={{ marginRight: "5px" }} />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", color: "#33414D" }}
                >
                  Documents required
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "1rem",
                  ml: "2.75rem",
                }}
              >
                {previewData?.has_resume && (
                  <Chip
                    label="Resume"
                    sx={{
                      backgroundColor: "#F0F4FA",
                      color: "#000000",
                      fontWeight: "500",
                      margin: "4px",
                    }}
                  />
                )}
                {previewData?.has_cv && (
                  <Chip
                    label="Cover letter"
                    sx={{
                      backgroundColor: "#F0F4FA",
                      color: "#000000",
                      fontWeight: "500",
                      margin: "4px",
                    }}
                  />
                )}
              </Box>
              {!previewData?.preview_only && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column-reverse",
                      sm: "column-reverse",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: "flex-start",
                      md: "space-between",
                    },
                    marginTop: "4rem",
                    gap: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "0",
                    },
                  }}
                >
                  <OutlinedButton
                    label="Cancel"
                    size="large"
                    isBold={true}
                    handleClick={() => navigate("/employer/my-jobs")}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                      },
                      gap: "1rem",

                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "flex-end",
                      },
                      width: {
                        xs: "small",
                        sm: "small",
                        md: "auto",
                      },

                      flex: 1,
                    }}
                  >
                    {!previewData?._id && (
                      <OutlinedButton
                        label="Save as draft"
                        size="large"
                        isBold={true}
                        handleClick={() => {
                          setSaveDraft(true);
                          handlePostJob(true);
                        }}
                      />
                    )}

                    <ContainedButton
                      label={previewData?.is_update ? "Update job" : "Post job"}
                      size="large"
                      isBold={true}
                      handleClick={() => {
                        if (previewData?.is_update) {
                          handleUpdateJob();
                        } else {
                          handlePostJob();
                        }
                      }}
                    />
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </>
  );
};

export default JobPreview;

const formatDateSring = (dateString: any) => {
  const date = new Date(dateString);

  // Options for toLocaleString
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit", // Include day with 2 digits
  };

  return date.toLocaleString("en-US", options);
};
