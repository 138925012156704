import { useState, useRef, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/Transitions";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { commonService } from "forms/helpers/common.service";
import { getFormatedDisplayTime } from "forms/helpers/function.helper";
import { useAppDispatch, useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import { setNotiCount, setSubMenuItemsRedux } from "store/slice/user.slice";
import Loading from "./loading";
import { appRoles } from "utils/constant.utils";

// ==============================|| PROFILE MENU ||====================== ======== //

const NotificationSection = ({ socket }: any) => {
  const theme = useTheme();

  const { user, notiCount } = useAppSelector((state) => state.user);
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [notificationList, setNotificationList] = useState<any>([]);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  const handleClose = (
    event:
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    function getNotificationCount(count: any) {
    
      dispatch(setNotiCount(count?.count ? count.count : 0));
    }

    if (socket) {
      socket.on("notification-count", getNotificationCount);
    }
  }, []);

  const getNotificationList = () => {
    setLoading(true);
    commonService
      .postService(
        `usernotification/search?page=1&per_page=4&sort_order=desc&sort_by=created_at`,
        {}
      )
      .then((response: any) => {
      
        setNotificationList(response.data?.data?.results);

        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const popupAction = () => {
    setOpen(false);
    dispatch(setSubMenuItemsRedux([]));
  };
  useEffect(() => {
    if (open) {
      getNotificationList();
    }
  }, [open]);

  const hexToRgb = (hex: any) => {
    if (!hex) return;
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, 0.1)`;
  };

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "0px",
          transition: "all .2s ease-in-out",
          borderColor: "transparent",
          backgroundColor: "transparent",
          color: theme.palette.primary?.backgroundBlueLight,
          "&.MuiChip-clickable": {
            ":hover": {
              borderColor: "transparent",
              background: "transparent",
            },
            ":active": {
              boxShadow: "none",
            },
          },
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: "transparent",
            background: "transparent",
          
          },
          "& .MuiChip-label": {
            lineHeight: 0,
            display: "none",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
        icon={
          <IconButton
            sx={{}}
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge  badgeContent={notiCount} color="error" sx={{
              '& .MuiBadge-badge': {
                top: '8px !important',
                backgroundColor: 'lightgreen'
              }
            }}>
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
    
      />
      {open && (
        <Popper
          sx={{ zIndex: "9999" }}
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 500,
                          minWidth: 450,
                          minHeight: 500,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          px: 3,
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                            "& .MuiListItemIcon-root": {
                              color: theme.palette.primary?.backgroundBlueLight,
                            },
                          },
                          "& .MuiListItemText-root": {
                            "& .MuiTypography-root": {
                              color: theme.palette.text.dark,
                              fontSize: "18px",
                            },
                          },
                        }}
                      >
                        {isloading ? (
                          <Loading />
                        ) : (
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                letterSpacing: "0.5px",
                                mb: "1rem",
                                p: "0.7rem",
                                color: theme.palette.grey[700],
                                fontWeight: 700,
                              }}
                            >{`Notifications (${
                              notiCount ? notiCount : 0
                            })`}</Typography>
                            {notificationList.map((item: any) => (
                              <>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={2}
                                  // sx={{ width: "100%"}}
                                >
                                  <Box>
                                    <Avatar
                                      sx={{
                                        mb: 5,
                                        background: item?.data?.status
                                          ?.color_code
                                          ? hexToRgb(
                                              item?.data?.status?.color_code
                                            )
                                          : "rgba(40, 56, 145, 0.1)",
                                      }}
                                    >
                                      {item?.data?.status?.images?.length ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_IMG_URL +
                                            item?.data?.status?.images[0]?.path
                                          }
                                        />
                                      ) : (
                                        item?.user?.full_name
                                      )}
                                    </Avatar>
                                  </Box>
                                  <Stack sx={{ width: "100%" }}>
                                    <Box>
                                      <Typography
                                        onClick={() => {
                                       
                                        }}
                                        sx={{
                                          fontSize: "1rem",
                                          letterSpacing: "0.5px",
                                          mb: "0.8rem",
                                          color: theme.palette.grey[700],
                                          fontWeight: 700,
                                          cursor: "pointer",
                                        }}
                                      >{`${item.title}`}</Typography>
                                      <Box width={"90%"}>
                                        <Typography
                                          sx={{
                                            width: "100%",
                                            wordBreak: "break-word",
                                            fontSize: "0.9rem",
                                            letterSpacing: "0.5px",
                                            mb: "0.8rem",
                                            color: theme.palette.grey[700],
                                            fontWeight: 400,
                                          }}
                                        >{`${item.content}`}</Typography>
                                      </Box>
                                      <Typography
                                        sx={{
                                          fontSize: "0.8rem",
                                          letterSpacing: "0.5px",
                                          mb: "0.8rem",
                                          color: theme.palette.grey[500],
                                          fontWeight: 400,
                                        }}
                                      >{`${getFormatedDisplayTime(
                                        item.created_at
                                      )}`}</Typography>
                                    </Box>
                                  </Stack>
                                </Stack>
                              </>
                            ))}
                          </Box>
                        )}
                      </List>
                      {notificationList?.length ? (
                        <Stack
                          alignItems={"center"}
                          py={1.5}
                          borderTop={`1px solid ${theme.palette.grey[300]}`}
                        >
                          <Button
                            disableElevation
                            disableRipple
                            disableTouchRipple
                            sx={{
                              fontSize: "1rem",
                              color: theme.palette.primary.dark,
                              fontWeight: 700,
                              letterSpacing: "0.5px",
                              "&:hover": { background: "transparent" },
                            }}
                            onClick={popupAction}
                          >
                            View All
                          </Button>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      )}
    </>
  );
};

export default NotificationSection;
