import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";
import { appRoles } from "utils/constant.utils";
const { CANDIDATE, EMPLOYER } = appRoles;

const ProtectedRoute = (props: any) => {
  const auth = !!localStorage.getItem("ekam-user")
    ? JSON.parse(localStorage.getItem("ekam-user") || "")
    : null;
  let location = useLocation();

  return props?.roles?.includes(auth?.role) ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        props?.roles.includes(CANDIDATE)
          ? `/candidate/login`
          : `/employer/login`
      }
      state={{ from: location }}
      replace
    />
  );
};

export default ProtectedRoute;
