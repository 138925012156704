import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PDF from "asset/icons/pdf.svg";
import Doc from "asset/icons/doc.svg";
import RSystem from "asset/images/r_system.svg";
import Profile from "asset/icons/profile.svg";
import { IconTypography } from "@tabler/icons";
import {
  Capitalize,
  formatDate,
  getDuration,
  getFormattedDateString,
} from "helpers/function.helper";
import { useAppSelector } from "store/store";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "forms/helpers/function.helper";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";

const CandiateDetails = ({ details }: { details: any }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userDetail = useAppSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");

  const downLoadFile = (downLoadFile: string) => {
    setIsLoading(true);
    commonService
      .getServices(`candidate/signed-url?key=${downLoadFile}`)
      .then((response: any) => {
        setIsLoading(false);
        window.open(response?.data?.signedUrl, "_blank");
        Success(response?.data?.message);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      <Box>
        <Card
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            mb: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {details?.profile ? (
                  <Box
                    component={"img"}
                    src={details?.profile}
                    height={80}
                    width={80}
                    borderRadius={"5px"}
                    alt={details?.first_name}
                  />
                ) : (
                  <Box
                    sx={{
                      borderRadius: "80px",
                      backgroundColor:
                        theme.palette.primary.backgroundBlueLight,
                      width: "80px",
                      height: "80px",
                      padding: "20px",
                    }}
                  >
                    <img src={Profile} alt={"profile"} />
                  </Box>
                )}
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: "1rem" }}>
                  <Typography variant="h3" sx={{ fontweight: "600" }}>
                    {details?.first_name + " " + details?.last_name}
                  </Typography>
                  <Chip
                    label={
                      details?.actively_looking_for_job
                        ? "Actively seeking"
                        : "Open to opportunities"
                    }
                    size="small"
                    sx={{
                      backgroundColor: details?.actively_looking_for_job
                        ? theme.palette.primary.chipGreen
                        : theme?.palette?.primary?.backgroundDarkGrey,
                      marginLeft: "1rem",
                      fontWeight: 500,
                      fontSize: ".875rem",
                      color: details?.actively_looking_for_job
                        ? theme.palette.primary.textBtn
                        : theme?.palette?.primary?.textInverse,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "start", sm: "center" },
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 1,
                  }}
                >
                  <Typography
                    fontWeight={500}
                    color={theme?.palette?.primary?.textGrey}
                  >
                    Member since {getYearOnly(details?.created_at)}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mx: 1 }}
                  ></Divider>
                  <Box
                    fontWeight={500}
                    color={theme?.palette?.primary?.textGrey}
                  >
                    Profile completion{" "}
                  </Box>
                  <Box sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
                    <LinearProgress
                      variant="determinate"
                      value={details?.profileCompletion}
                      sx={{
                        display: "inline-block",
                        mr: 1,
                        width: "160px",
                        height: "6px",
                        backgroundColor:
                          theme.palette.primary.backgroundBlueMid,
                        borderRadius: "12px",
                        "& .MuiLinearProgress-barColorPrimary": {
                          backgroundColor: `${theme.palette.primary.backgroundLightGreen} !important`,
                          borderRadius: "12px",
                        },
                      }}
                    />
                    <Typography
                      component={"span"}
                      color={theme?.palette?.primary?.textBtn}
                      fontWeight={500}
                    >
                      {details?.profileCompletion || "0"}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }}></Divider>

            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Service
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.service?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Military ID Number
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.military_id || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Relocate
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.willing_to_relocate
                    ? "Open to relocate"
                    : "Not open to relocate"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Date of birth
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {getFormattedDateString(details?.date_of_birth) || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Rank
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.rank?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Email ID
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    color: theme?.palette?.primary?.textBtn,
                    width: "100%",
                    overflow: "hidden",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  mb={1}
                  title={details?.email}
                >
                  {details?.email || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Current location
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.current_location?.city || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Joining type
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.joining_type?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Branch
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.branch?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textGrey }}
                  mb={1}
                >
                  Mobile number
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h4"
                    fontWeight={500}
                    sx={{
                      color: theme?.palette?.primary?.textBtn,
                      display: "inline-block",
                      marginBottom: "10px",
                    }}
                  >
                    {details?.mobile_number}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textMid }}
                  mb={1}
                >
                  Work experience
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.work_experience
                    ? details?.work_experience + " years"
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textMid }}
                  mb={1}
                >
                  Specially abled
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: theme?.palette?.primary?.textBtn }}
                  mb={1}
                >
                  {details?.disability}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              p: { xs: 1, sm: 2 },
            }}
          >
            Uploaded documents
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
              flexWrap: "wrap",
              p: { xs: 1, sm: 2 },
            }}
          >
            {details?.resume?.map((doc: any) => {
              return (
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>
                    {doc?.type === "resume" ? "Resume" : "Cover Letter"}
                  </Typography>
                  <Box
                    component={"a"}
                   
                    onClick={() => {
                      if (userDetail?.user?.id) {
                        downLoadFile(doc?.resume);
                      } else {
                        navigate("/employer/login");
                      }
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "347px",
                      backgroundColor: "#E3E8EF66",
                      padding: "10px 20px",
                      mt: 1,
                      borderRadius: "8px",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    {extractFileNameAndExtension(doc?.resume).extension ===
                    "pdf" ? (
                      <img src={PDF} alt="pdf" style={{ marginRight: "8px" }} />
                    ) : (
                      <img src={Doc} alt="Doc" style={{ marginRight: "8px" }} />
                    )}

                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      color={theme?.palette?.primary?.textBtn}
                    >
                      {extractFileNameAndExtension(doc?.resume).filename}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              p: { xs: 1, sm: 2 },
            }}
          >
            Work experience
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
              p: { xs: 1, sm: 2 },
            }}
          >
            <Grid container spacing={2} sx={{ px: 0 }}>
              {details?.experience?.map((experience: any) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        border: "1px solid #E3E8EF",
                        borderRadius: "4px",
                        display: "flex",
                        gap: 2,
                        p: 1,
                      }}
                    >
                      <Box>
                        <img
                          width={40}
                          height={40}
                          src={RSystem}
                          alt={"R_system"}
                        />
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="h4" sx={{ mb: 1 }}>
                            {experience?.designation}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            color={theme?.palette?.primary?.textBtn}
                            fontWeight={500}
                            mb={1}
                          >
                            {experience?.company_name}
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={{ mx: 1, height: "12px", width: "1px" }}
                          ></Divider>

                          <Typography
                            variant="body2"
                            color={theme?.palette?.primary?.textBtn}
                            fontWeight={400}
                            mb={1}
                          >
                            {getDuration(
                              experience?.joined_at,
                              experience?.is_current
                                ? new Date()
                                : experience?.ended_at
                            )}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            color={theme?.palette?.primary?.textMid}
                            fontWeight={400}
                          >
                            {experience?.employment_type?.value}
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={{ mx: 1, height: "12px", width: "1px" }}
                          ></Divider>
                          <Typography
                            variant="body2"
                            color={theme?.palette?.primary?.textMid}
                            fontWeight={400}
                          >
                            {formatDate(experience?.joined_at)} to{" "}
                            {experience?.is_current
                              ? "present"
                              : formatDate(experience?.ended_at)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              p: { xs: 1, sm: 2 },
            }}
          >
            Education Details
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
              p: { xs: 1, sm: 2 },
            }}
          >
            <Grid container spacing={2} sx={{ px: 0 }}>
              {details?.education?.map((study: any) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        border: "1px solid #E3E8EF",
                        borderRadius: "4px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 600,
                          color: theme?.palette?.primary?.textBtn,
                          mb: "0.5rem",
                        }}
                      >
                        {study?.degree}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 400,
                          color: theme?.palette?.primary?.textBtn,
                          mb: "0.5rem",
                        }}
                      >
                        {study?.institution_name}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            color: theme?.palette?.primary?.textMid,
                          }}
                        >
                          {study?.academic_type?.value}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          sx={{ mx: 1, height: "12px", width: "1px" }}
                        ></Divider>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            color: theme?.palette?.primary?.textMid,
                          }}
                        >
                          {getFromToYears(study?.joined_at, study?.ended_at)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              p: { xs: 1, sm: 2 },
            }}
          >
            Certificate
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
              p: { xs: 1, sm: 2 },
            }}
          >
            <Grid container spacing={2} sx={{ px: 0 }}>
              {details?.certificate?.map((certificate: any) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        border: "1px solid #E3E8EF",
                        borderRadius: "4px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 600,
                          color: theme?.palette?.primary?.textBtn,
                          mb: "0.5rem",
                        }}
                      >
                        {certificate?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 400,
                          color: theme?.palette?.primary?.textBtn,
                          mb: "0.5rem",
                        }}
                      >
                        {certificate?.institution_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 400,
                          color: theme?.palette?.primary?.textMid,
                        }}
                      >
                        {getYearOnly(certificate?.ended_at)}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              p: { xs: 1, sm: 2 },
            }}
          >
            Key skills
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              //flexDirection: { xs: "column", sm: "row" },
              flexWrap: "wrap",
              p: { xs: 1, sm: 2 },
            }}
          >
            {details?.skill?.map((skill: any) => {
              return (
                <Chip
                  label={skill.name}
                  sx={{
                    backgroundColor: theme.palette.primary.backgroundBlueLight,
                    marginRight: "0.5rem",
                    color: theme?.palette?.primary?.textBtn,
                    fontWeight: 500,
                  }}
                />
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              p: { xs: 1, sm: 2 },
            }}
          >
            Other info
          </Typography>
          <Divider></Divider>
          <Box
            sx={{
              p: { xs: 1, sm: 2 },
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color={theme?.palette?.primary?.textGrey}
                  >
                    Preferred location
                  </Typography>
                  <Box my={1}>
                    {details?.preferred_location?.map((location: any) => {
                      return (
                        <Chip
                          label={location?.city}
                          sx={{
                            backgroundColor:
                              theme.palette.primary.backgroundBlueLight,
                            marginRight: "0.5rem",
                            color: theme?.palette?.primary?.textBtn,
                            fontWeight: 500,
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color={theme?.palette?.primary?.textGrey}
                    mt={{ xs: "1rem" }}
                  >
                    Current salary
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: theme?.palette?.primary?.textBtn,
                      mt: "0.5rem",
                    }}
                  >
                    {details?.salary || "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    mt="1.5rem"
                    color={theme?.palette?.primary?.textGrey}
                  >
                    3 Key Appointments that you have served
                  </Typography>
                  <Box mt={1}>
                    {details?.appointments?.map((appointment: any) => {
                      return (
                        <Chip
                          label={appointment}
                          sx={{
                            backgroundColor:
                              theme.palette.primary.backgroundBlueLight,
                            marginRight: "0.5rem",
                            fontWeight: "500",
                            color: theme?.palette?.primary?.textBtn,
                            marginBottom: "0.5rem",
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
       
      </Box>
    </>
  );
};

export default CandiateDetails;

function getFromToYears(from: string, to: string) {
  const joinedAt = new Date(from);
  const endedAt = new Date(to);

  const joinedYear = joinedAt.getUTCFullYear();
  const endedYear = endedAt.getUTCFullYear();

  const formattedDate = `${joinedYear} - ${endedYear}`;
  return formattedDate;
}

function getYearOnly(dateSring: string) {
  const date = new Date(dateSring);
  const year = date.getUTCFullYear();
  return year;
}

function extractFileNameAndExtension(url: string) {
  const parts = url.split("/");
  const filename = parts[parts.length - 1];
  const filenameParts = filename.split(".");
  const extension = filenameParts.pop();
  const name = filenameParts.join(".");

  return { filename, extension };
}
