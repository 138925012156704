import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import PaginationComponent from "component/pagination";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import JobCard from "forms/hoc/job-card";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import NoDataFoundImage from "asset/images/no-search-results.svg";
import { useNavigate } from "react-router-dom";
import { setPostJobPreviewRedux } from "store/slice/employer.slice";
import NoJobs from "component/no-jobs";

const ActiveJobs = ({ searchText, onSearchJobs }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [jobsList, setJobsList] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const user = useAppSelector((state) => state?.user?.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getActiveJobs();
  }, [page, searchText]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getActiveJobs = () => {
    setIsLoading(true);
    let params: any = {
      page: page + 1,
      per_page: perPage,
      sort_order: "DESC",
      sort_by: "created_at",
      search_by: searchText
        ? "employer_id.organization_name,title,category.name,skills.name"
        : "",
      search: searchText,
      employerId: user?.user?.id,
    };

    const payload = {
      status: ["active"],
    };

    commonService
      .postServiceWithParams(`job/jobs-list`, payload, params)
      .then((response) => {
        setIsLoading(false);
        setJobsList(response?.data?.data?.results);
        setTotal(response?.data?.data?.pagination?.total);
        onSearchJobs(response?.data?.data?.pagination?.total);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleSelectJob = (job: any) => {
  
    navigate(`/employer/my-jobs/${job?._id}`);
  };

  const handleDeleteJob = (jobId: string) => {
    commonService
      .deleteService(`job?jobId=${jobId}`)
      .then((response: any) => {
        setIsLoading(false);
        Success(response?.data?.message);
        getActiveJobs();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleEditJob = (jobId: string) => {
    navigate(`/employer/update-job/${jobId}`);
  };

  const handleArchiveJob = (jobId: string) => {
    commonService
      .patchServiceWithParams(`job/archive`, null, { id: jobId })
      .then((response: any) => {
        setIsLoading(false);
        Success(response?.data?.message);
        getActiveJobs();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box mb={{ sm: 3 }}>
        {jobsList.length > 0 ? (
          <Stack spacing={3}>
            {jobsList.map((job: any) => {
              return (
                <JobCard
                  isHomeFeatured={true}
                  isFullWidth={true}
                  job={job}
                  isMyJob={true}
                  hasLocationChip={true}
                  handleJobClick={() => {
                    handleSelectJob(job);
                  }}
                  handleJobDelete={(jobId: string) => {
                    handleDeleteJob(jobId);
                  }}
                  handleJobEdit={(jobId: string) => {
                    handleEditJob(jobId);
                  }}
                  handleJobArchive={(jobId: string) => {
                    handleArchiveJob(jobId);
                  }}
                />
              );
            })}
            <Box display={"flex"} justifyContent={"end"}>
              <PaginationComponent
                page={page}
                rowsPerPage={perPage}
                total={total}
                onChange={($e: any, newPage: any) => {
                  handleChangePage($e, newPage);
                }}
              />
            </Box>
          </Stack>
        ) : (
          <NoJobs />
        )}
      </Box>
    </>
  );
};

export default ActiveJobs;
