import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { AboutValidation } from "forms/hooks/validateRules";
import React, { useEffect } from "react";

const AboutEdit = ({ aboutEditOpen, aboutClose, data, handleUpdate }: any) => {
  const handleUpdateAbout = () => {
    handleUpdate(values);
    aboutClose();
  };

  const socialMediaValues = {
    about: null,
  };

  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(socialMediaValues, handleUpdateAbout, AboutValidation);

  useEffect(() => {
    if (data) {
      setUpdateValue("about", data?.about || "");
    }
  }, [data, setUpdateValue]);

  return (
    <Dialog
      open={aboutEditOpen}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, fontSize: "20px", color: "#000000" }}>
        Edit About Organisation
      </DialogTitle>
      <Divider sx={{ mb: 1 }} />
      <DialogContent  sx={{p:"20px"}}>
        <form onSubmit={handleSubmit}>
          <InputField
            values={values.about || ""}
            fieldName="About"
            handleChange={handleChange}
            name="about"
            errors={errors.about}
            multiline={true}
            minRow={3}
            isRequired={false}
            id="about"
          />
          <Box display={"flex"} justifyContent={"end"} mt={3}>
            <OutlinedButton
              size={"small"}
              label={"cancel"}
              isBold={true}
              handleClick={aboutClose}
            />
            <Box sx={{ mx: 1 }} />
            <ContainedButton
              size="large"
              isBold={true}
              label={"Update"}
              type="submit"
            />
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AboutEdit;
