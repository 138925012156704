import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { OutlinedButton } from "component/button";
import Kapilbrar from "asset/images/kapilbrar.svg";
import Army from "asset/icons/army.svg";
import Award from "asset/icons/award.svg";
import Location from "asset/icons/location.svg";
import Branch from "asset/icons/branch.svg";
import Yamalpatil from "asset/images/yamalpatil.svg";
import Deepak from "asset/images/deepak.svg";
import TakshakaGala from "asset/images/takshakagala.svg";
import NeerendraBera from "asset/images/neerendraBera.svg";
import NareshGovid from "asset/images/nareshgovindchaudhry.svg";
import PranabSani from "asset/images/pranabsani.svg";
import KapilBrar from "asset/images/kapilbrarr.svg";
import React, { useEffect, useState } from "react";
import NoUserImage from "asset/images/no-user.png";

const CandidateCard = ({
  candidate,
  isActive,
  handleCandidateClick,
}: {
  candidate?: any;
  isActive?: boolean;
  handleCandidateClick?: () => void;
}) => {
  const theme = useTheme();
  const [skills, setSkills] = useState([]);
  const [skillsText, setSkillsText] = useState("");

  useEffect(() => {
    setSkills(candidate?.skills?.slice(0, 1));
    
    const candidateSkills = candidate?.skills?.map((skill: any) => {
     
      
      return skill.name;
    });
    setSkillsText(candidateSkills?.join(","));
  }, []);

  return (
    <>
      <Card
        sx={{
         

          cursor: "pointer",
          border: isActive
            ? `3px solid ${theme?.palette?.primary?.buttonOrange}`
            : `1px solid ${theme?.palette?.primary?.borderLinelight}`,
          boxShadow: isActive ? "0px 8px 32px 0px #33414D1A" : "none",
          borderRadius: "8px",
          px: 2,
          py: 1,
          "&:hover": {
            border: isActive
              ? `3px solid ${theme?.palette?.primary?.buttonOrange}`
              : `1px solid ${theme?.palette?.primary?.buttonOrange}`,
          },
        }}
        onClick={handleCandidateClick}
      >
        <CardContent sx={{ p: 1, pb: "8px !important" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
          
            {candidate?.profile ? (
              <Box
                component={"img"}
                src={candidate?.profile}
                height={80}
                width={80}
                alt={"kapilbrar"}
                sx={{ borderRadius: "50%" }}
              />
            ) : (
              <Box
                component={"img"}
                src={NoUserImage}
                height={80}
                width={80}
                alt={"No Profile"}
              />
            )}
            <Typography variant="body1" fontWeight="600" mt={1}>
              {candidate?.first_name
                ? candidate?.first_name + " " + candidate?.last_name
                : ""}
            </Typography>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{ color: theme?.palette?.primary?.textGrey }}
              marginTop={0.5}
            >
              {candidate?.work_experience} years of experience
            </Typography>
          </Box>
          <Grid container spacing={1} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  gap: "2",
                  alignItems: "center",
                  mt: "1rem",
                }}
              >
                <img
                  src={Army}
                  alt={"Army"}
                  style={{ marginRight: "0.5rem" }}
                />
                <Typography
                  variant="h6"
                  fontWeight="500"
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {candidate?.service?.name || candidate?.service?.value || "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  gap: "1",
                  alignItems: "center",
                  mt: "1rem",
                }}
              >
                <img
                  src={Award}
                  alt={"Award"}
                  style={{ marginRight: "0.5rem" }}
                />
                <Typography
                  variant="h6"
                  fontWeight="500"
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {candidate?.rank?.name || candidate?.rank?.rank || "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  gap: "2",
                  alignItems: "center",
                  mt: "1rem",
                }}
              >
                <img
                  src={Location}
                  alt={"Location"}
                  style={{ marginRight: "0.5rem" }}
                />
                <Typography
                  variant="h6"
                  fontWeight="500"
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {candidate?.current_location?.city || "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  gap: "1",
                  alignItems: "center",
                  mt: "1rem",
                }}
              >
                <img
                  src={Branch}
                  alt={"Branch"}
                  style={{ marginRight: "0.5rem" }}
                />
                <Typography
                  variant="h6"
                  fontWeight="500"
                  title={candidate?.branch?.name}
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {candidate?.branch?.name || "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: "1rem",
              height: "32px",
            }}
          >
            <Typography sx={{ mr: 2 }}>Skills:</Typography>
            {candidate?.skills?.length > 0 && (
              <Chip
                label={candidate?.skills[0]?.name}
                sx={{
                  backgroundColor: theme.palette.primary.backgroundBlueLight,
                  marginRight: "0.5rem",
                }}
              />
            )}               
            
            {candidate?.skills?.length > 1 && (
              <Typography
                variant="body2"
                fontWeight={600}
                component={"span"}
                color={theme?.palette?.primary?.iconOrange}
                title={skillsText}
              >
                +{candidate?.skills?.length - 1} more
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CandidateCard;
