import { Box, Stack, Typography, useTheme } from "@mui/material";
import Testimonial1 from "asset/images/testimonial-1.svg";

const TestimonialCard = ({ testimonial }: { testimonial?: any }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        boxShadow: "0px 0px 8px 0px #33414D1A",
        p: 4,
        borderRadius: "8px",
        mr: 2,
        mb: 4,
        mt: 1,
      }}
    >
      <Typography
        variant="body2"
        color={theme?.palette?.primary?.textMid}
        fontWeight={500}
        maxWidth={"340px"}
      >
        {testimonial?.text}
      
      </Typography>
      <Box display={"flex"} gap={2} mt={3}>
        <Box component={"img"} src={Testimonial1}></Box>
        <Box>
          <Typography variant="h4">
            {testimonial?.candidate?.first_name}
          </Typography>
          <Typography
            variant="body2"
            color={theme?.palette?.primary?.textMid}
            fontWeight={500}
          >
            Navy Retirement
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default TestimonialCard;
