import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./localstorage.helper";
import { useAppDispatch, useAppSelector } from "../store/store";
import { commonService } from "forms/helpers/common.service";
import { clearStore } from "utils/redux.utils";

export const AuthContext: any = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useLocalStorage(
    "token",
    sessionStorage.getItem("token")
  );
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  useEffect(() => {}, []);

  const login = async (data: any) => {
    setToken(data?.token);
  };

  const logout = () => {
   
    clearStore();
    setToken(null);
    setToken(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      user,               
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth: any = () => {
  return useContext(AuthContext);
};
