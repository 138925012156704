import logo from "asset/images/ekam_logo.svg";
import { FC } from "react";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
// ==============================|| LOGO SVG ||============================== //
interface logoProps {
  width?: string;
}
const Logo: FC<logoProps> = ({ width }: logoProps) => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <img
      src={logo}
      alt="Berry"
      width={width}
      style={{ padding: "10px 0 0 10px" }}
    />
  );
};
export default Logo;
