import { Box, Button, Stack, useTheme } from "@mui/material";

const FormLayout = ({
  handleSubmit,
  children,
  backButtonText,
  submitButtonText,
  backButtonClick,
  isBackButton,
  scrollable,
  disableSave = false,
  isSaveButton,
  isRegister,
  handleClick,
  isLogin,
}: FormTypes) => {
  const theme = useTheme();
  return (
    <Stack
      component={"form"}
      flexGrow={1}
     
      width={"100%"}
      noValidate
      onSubmit={handleSubmit}
      justifyContent={"space-between"}
    >
      <Box
       
        width={"100%"}
        sx={{
         
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
        }}
     
      >
        {children}
      </Box>
      
      <Box
        sx={{ pt: 2 }}
        display={"flex"}
        justifyContent={
          isBackButton
            ? "space-between"
            : isRegister || isLogin
            ? "center"
            : "flex-end"
        }
      >
        <Box>
          {isBackButton && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={backButtonClick}
            >
              {backButtonText || "Back"}
            </Button>
          )}
        </Box>
        <Box>
          {isSaveButton && (
            <Button variant="contained" disabled={disableSave} type="submit">
              {submitButtonText || "Save"}
            </Button>
          )}
          {isRegister && (
            <Button
              variant="contained"
              disabled={disableSave}
              type="submit"
              sx={{ padding: "12px 44px" }}
              onClick={handleClick}
            >
              {submitButtonText || "Save"}
            </Button>
          )}
          {isLogin && (
            <Button
              variant="contained"
              disabled={disableSave}
              type="submit"
              onClick={handleClick}
              sx={{ padding: "12px  190px" }}
            >
              {submitButtonText || "Save"}
            </Button>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

type FormTypes = {
  handleSubmit: any;
  children: any;
  submitButtonText?: string;
  backButtonText?: string;
  isBackButton?: boolean;
  isSubmitButton?: boolean;
  backButtonClick?: any;
  scrollable?: any;
  disableSave?: boolean;
  isSaveButton?: boolean;
  isRegister?: boolean;
  handleClick?: any;
  isLogin?: boolean;
};
export default FormLayout;
