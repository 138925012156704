import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./selectedTable.scss";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Switch, { SwitchProps } from "@mui/material/Switch";

import SortIcon from "../../asset/icons/sort-icon.svg";
import { hexToRgb, styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {
  capitalizeFirstLetter,
  dateFormat,
  dateTimeFormatWithoutSecond,
  parsePhoneNumberformat,
} from "forms/hooks/customHook";
import NoRecordFound from "component/tables/NoRecordFound";
import { formatLicense } from "forms/hooks/customHook";
import PaginationComponent from "../pagination";
import IosShareIcon from "@mui/icons-material/IosShare";

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RedoIcon from "@mui/icons-material/Redo";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Sort } from "@mui/icons-material";
import NoUserImage from "asset/images/no-user.png";

function EnhancedTableHead(props: EnhancedTableHeadTypes) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isSelectable,
    isSingleCheck,
  } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow>
        {isSelectable && (
          <TableCell
            padding="checkbox"
            sx={
              isSingleCheck
                ? { border: "0" }
                : {
                    backgroundColor: theme?.palette?.primary?.backgroundBlueMid,
                  }
            }
          >
            {!isSingleCheck && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            )}
          </TableCell>
        )}
        {headCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
        
            align={
              headCell.id === "action" || headCell.id === "status"
                ? "center"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="tableheading"
            sx={{ border: "0" }}
          >
            {headCell?.isSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}{" "}
                <img src={SortIcon} alt="filterimage" className="filterimage" />
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span> {headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type EnhancedTableHeadTypes = {
  numSelected: number;
  onRequestSort: (_: any, __: any) => void;
  onSelectAllClick: (_: any) => void;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headCells: any[];
  isSelectable: boolean;
  isSingleCheck?: boolean;
};

type EnhancedTableTypes = {
  page?: number;
  rowsPerPage?: number;
  handleChangePage?: (_: any, __: any) => void;
  handleChangeRowsPerPage?: (_: any) => void;
  headCells: any[];
  handleRequestSort?: (_: any, __: any) => void;
  rows: any[];
  order?: "asc" | "desc";
  orderBy?: string;
  isSelectable?: boolean;
  selected?: any;
  setSelected?: (_: any) => void;
  total?: number;
  actionFunction?: (_: string, __: string, ___?: any, ____?: any) => void;
  resendMail?: (_: any) => void;
  isEdit?: boolean;
  isView?: boolean;
  isreInvite?: boolean;
  isDelete?: boolean;
  isKyc?: boolean;
  isMail?: boolean;
  isActionButton?: boolean;
  isMoreVert?: boolean;
  setActive?: (_: string, __: boolean) => void;
  reInvite?: (_: string) => void;
  redoKyc?: (_: string) => void;
  isLoading?: boolean;
  Openmodal?: (_: string, __: string) => void;
  isPagination?: boolean;
  isNoRecordFound?: boolean;
  isSingleCheck?: boolean;
  maxHeightValue?: any;
  isCheckedRound?: boolean;
  width?: string | number;
  isExport?: boolean;
};
type TableCellType = {
  row: any;
  head: any;
  i: number;
  isMail: boolean;
  resendMail?: (_: any) => void;
  isView: boolean;
  isreInvite: boolean;
  isKyc: boolean;
  actionFunction?: (_: string, __: string, ___?: any, ____?: any) => void;
  isEdit?: boolean;
  isDelete?: boolean;
  isActionButton?: boolean;
  isMoreVert?: boolean;
  Openmodal?: (_: string, __: string) => void;
  setActive?: (_: string, __: boolean) => void;
  redoKyc?: (_: string) => void;
  reInvite?: (_: string) => void;
  isExport?: boolean;
};
const EnhancedTableCell = ({
  row,
  head,
  i,
  isMail,
  resendMail,
  isView,
  isreInvite,
  isKyc,
  actionFunction,
  isEdit,
  isDelete,
  isActionButton,
  isMoreVert,
  Openmodal,
  setActive,
  reInvite,
  redoKyc,
  isExport,
}: TableCellType) => {
  const backgroundColor = row?.color_code ? hexToRgb(row?.color_code) : "";

  const [flg_mailVerified, setFlg_mailVerified] = React.useState(false);
  const [isHover_mailVerified, setIsHover_mailVerified] = React.useState(false);

  const [flg_isReinvite, setFlg_isReinvite] = React.useState(false);
  const [isHover_isReinvite, setIsHover_isReinvite] = React.useState(false);

  const [flg_isKyc, setFlg_isKyc] = React.useState(false);
  const [isHover_isKyc, setIsHover_isKyc] = React.useState(false);

  const [flg_isView, setFlg_isView] = React.useState(false);
  const [isHover_isView, setIsHover_isView] = React.useState(false);

  const [flg_isEdit, setFlg_isEdit] = React.useState(false);
  const [isHover_isEdit, setIsHover_isEdit] = React.useState(false);

  const [flg_isDelete, setFlg_isDelete] = React.useState(false);
  const [isHover_isDelete, setIsHover_isDelete] = React.useState(false);

  const [flg_isMore, setFlg_isMore] = React.useState(false);
  const [isHover_isMore, setIsHover_isMore] = React.useState(false);

  const [flg_export, setFlg_export] = React.useState(false);
  const [isHover_export, setIsHover_export] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const IOSSwitch = styled((props: SwitchProps, checked: any) => (
    <Switch
      checked={checked}
      {...props}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      sx={{ color: "#283891" }}
    />
  ))(({ theme }) => ({
    width: 37,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#2ECA45"
              : theme.palette.primary.backgroundBlueMid,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      marginTop: 1,
      width: 15,
      height: 15,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  if (row[head]) {
    return (
      <Typography variant="subtitle1" fontWeight="bold">
        {row[head.id]}
      </Typography>
    );
  }
  switch (head.id) {
    case "name":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "company_name":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "candidate_id.first_name":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
            gap: "1rem",
          }}
        >
          <Box
            component={"img"}
            src={row["profile_image"] ? row["profile_image"] : NoUserImage}
            height={40}
            width={40}
            alt={row[head.id]}
          />
          <Box>
            <Typography
              sx={{ fontWeight: 600, fontSize: ".875rem", color: "#AB5B0B" }}
              onClick={() => {
                if (head.shouldRedirect) {
                  actionFunction &&
                    actionFunction(
                      row.candidate_db_id,
                      "candidate_details",
                      row
                    );
                }
              }}
            >
              {row[head.id]}
            </Typography>
            <Typography sx={{ fontSize: ".75rem", mt: 1 }}>
              #{row["candidate_id"]}
            </Typography>
          </Box>
        </div>
      );
    case "firstname":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "status":
      return (
        <Box display={"flex"} justifyContent="center">
          <Chip
            label={capitalizeFirstLetter(row[head.id])}
            sx={{
              background: head.styling[row[head.id]]?.background || "#F0F4FA",
              borderRadius: "32px",
              minWidth: "8rem",
              color: head.styling[row[head.id]]?.color || "#15171E",
              fontSize: "1rem",
              fontWeight: "600",
            }}
          />
        </Box>
      );
    case "vehicle_identification_number":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "action":
      return (
        <Stack flexDirection="row" justifyContent="space-evenly">
          {isMail && (
            <>
              {!row?.email_verified ? (
                <Tooltip
                  title="Resend Verification Email"
                  open={!flg_mailVerified && isHover_mailVerified}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => {
                      setFlg_mailVerified(!flg_mailVerified);
                      resendMail && resendMail(row.id);
                    }}
                    onMouseOver={() => setIsHover_mailVerified(true)}
                    onMouseLeave={() => setIsHover_mailVerified(false)}
                  ></IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip
                    title="Email Verified"
                    open={!flg_mailVerified && isHover_mailVerified}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onMouseOver={() => setIsHover_mailVerified(true)}
                      onMouseLeave={() => setIsHover_mailVerified(false)}
                    ></IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
          {isView && (
            <Tooltip title="View" open={!flg_isView && isHover_isView}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isView(!flg_isView);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "view", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isView(true)}
                onMouseLeave={() => setIsHover_isView(false)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ color: theme.palette.primary.backgroundBlueLight }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isreInvite && (
            <Tooltip
              title="Reinvite"
              open={!flg_isReinvite && isHover_isReinvite}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isReinvite(!flg_isReinvite);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "reinvite", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isReinvite(true)}
                onMouseLeave={() => setIsHover_isReinvite(false)}
              >
                <PersonAddAltIcon
                  sx={{ color: theme.palette.primary.backgroundBlueLight }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isKyc && (
            <Tooltip title="RedoKYC" open={!flg_isKyc && isHover_isKyc}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isKyc(!flg_isKyc);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "redo", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isKyc(true)}
                onMouseLeave={() => setIsHover_isKyc(false)}
              >
                <RedoIcon
                  sx={{ color: theme.palette.primary?.backgroundBlueLight }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip title="Edit" open={!flg_isEdit && isHover_isEdit}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isEdit(!flg_isEdit);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "edit", i, row);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isEdit(true)}
                onMouseLeave={() => setIsHover_isEdit(false)}
              >
                <EditOutlinedIcon
                  sx={{ color: theme.palette.primary?.backgroundBlueLight }}
                />
              </IconButton>
            </Tooltip>
          )}

          {isDelete && (
            <Tooltip title="Delete" open={!flg_isDelete && isHover_isDelete}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isDelete(!flg_isDelete);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "delete", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isDelete(true)}
                onMouseLeave={() => setIsHover_isDelete(false)}
              >
                <DeleteOutlineIcon
                  sx={{ color: theme.palette.primary?.backgroundBlueLight }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isExport && (
            <Tooltip title="Export" open={!flg_export && isHover_export}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_export(!flg_export);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "export", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_export(true)}
                onMouseLeave={() => setIsHover_export(false)}
              >
                <ExitToAppOutlinedIcon
                  sx={{ color: theme.palette.primary?.backgroundBlueLight }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isMoreVert && (
            <>
              <>
                <Tooltip title="More" open={!flg_isMore && isHover_isMore}>
                  {!row?.isApproveActionDisable ? (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={handleMenuOpen}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      onMouseOver={() => setFlg_isMore(true)}
                      onMouseLeave={() => setIsHover_isMore(false)}
                    >
                      <MoreVertIcon
                        sx={{
                          color: theme.palette.primary?.backgroundBlueLight,
                        }}
                      />
                    </IconButton>
                  ) : (
                    <div style={{ visibility: "hidden", padding: "1.3rem" }} />
                  )}
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  sx={{ margin: "2rem" }}
                >
                  <MenuItem
                    sx={{
                      minWidth: "4rem",
                      padding: "0.5rem 1.2rem",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      actionFunction && actionFunction(row.id, "Accept", i);
                    }}
                    disabled={row.isButtonDisable}
                  >
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        fontSize="medium"
                        sx={{ color: "#56BF54" }}
                      />
                    </ListItemIcon>
                    Approve{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minWidth: "4rem",
                      padding: "0.5rem 1.2rem",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      actionFunction && actionFunction(row.id, "Reject", i);
                    }}
                    disabled={row.isButtonDisable}
                  >
                    <ListItemIcon>
                      <HighlightOffIcon
                        fontSize="medium"
                        sx={{ color: "#EF415A" }}
                      />
                    </ListItemIcon>
                    Reject{" "}
                  </MenuItem>
                </Menu>
              </>
            </>
          )}
          {isActionButton && !isMoreVert && (
            <Box>
              <Button
                variant="outlined"
                size="small"
                sx={{ minWidth: "4rem", padding: "0.5rem 1rem" }}
                onClick={() => {
                  actionFunction && actionFunction(row.id, "Accept", i);
                }}
                disabled={row.isButtonDisable}
              >
                {" "}
                Accept{" "}
              </Button>
              <Button
                sx={{ minWidth: "4rem", padding: "0.5rem 1rem", ml: 1 }}
                size="small"
                variant="outlined"
                color="error"
                onClick={() => {
                  actionFunction && actionFunction(row.id, "Reject", i);
                }}
                disabled={row.isButtonDisable}
              >
                {" "}
                Reject{" "}
              </Button>
            </Box>
          )}
        </Stack>
      );
    case "dob":
      return dateFormat(row[head.id]);
    case "permit_expiration_date":
      return dateFormat(row[head.id]);
    case "driver_license_number":
      return formatLicense(row[head.id]);
    case "type":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {head?.isUserIcon && (
            <AccountCircleIcon sx={{ color: "#a5a5a5" }} fontSize="large" />
          )}
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "filesize":
      return row[head.id];
    case "mobile_number":
      return parsePhoneNumberformat(row.country_code, row?.mobile_number);
    case "icons":
      return (
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          className="showIcon"
        >
          <IconButton>
            <PersonAddIcon />
          </IconButton>
          <IconButton>
            <FileDownloadIcon />
          </IconButton>
          <IconButton>
            <BorderColorIcon />
          </IconButton>
        </Stack>
      );
    case "button":
      return (
        <>
          {" "}
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={() => {
              Openmodal && Openmodal(row.id, "open");
            }}
          >
            <IosShareIcon
              sx={{ color: theme.palette.primary?.backgroundBlueLight }}
            />
          </IconButton>
        </>
      );

    case "is_active":
      return (
        <>
          <IOSSwitch
            checked={row.is_active}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setActive && setActive(row.id, event.target.checked)
            }
          />
        </>
      );
    case "template_type":
      return row[head.id]?.name;
    case "created_at":
      return dateFormat(row[head.id]);
    case "payment_date":
      return dateFormat(row[head.id], "/");
    case "reference_id":
      return (
        <span
          style={{
            color:
              row["status"] === "paid"
                ? "#005095"
                : theme?.palette?.primary?.textBtn,
          }}
          onClick={() => {
            if (row["status"] === "paid") {
              head?.handleClick(row[head.id]);
            }
          }}
        >
          {"#" + row[head.id]}
        </span>
      );
    case "timestamp":
      return dateTimeFormatWithoutSecond(row[head.id]);
    case "path":
      return (
        <span
          onClick={() => {
            window.open(process.env.REACT_APP_IMG_URL + row?.path, "_blank");
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "#007aff",
          }}
        >
          {row?.path?.split("/")?.slice(-1)?.toString()}
        </span>
      );
    default:
      return row[head.id];
  }
};

const isEqual = (prev: TableCellType, next: TableCellType) => {
  return true;
};

const TableCellMemo = React.memo(EnhancedTableCell, isEqual);
export default function EnhancedTable(props: EnhancedTableTypes) {
  const {
    page = 0,
    rowsPerPage = 10,
    handleChangePage,
    handleChangeRowsPerPage = () => {},
    headCells = [],
    handleRequestSort = () => {},
    rows = [],
    order,
    orderBy,
    isSelectable,
    setSelected,
    selected = [],
    total = 0,
    actionFunction,
    resendMail,
    isEdit = false,
    isView = false,
    isreInvite = false,
    isKyc = false,
    isDelete = false,
    isActionButton = false,
    isMoreVert = false,
    isMail = false,
    isLoading = false,
    isPagination = true,
    isNoRecordFound = true,
    Openmodal,
    setActive,
    redoKyc,
    reInvite,
    isSingleCheck,
    maxHeightValue,
    isCheckedRound = false,
    width,
    isExport,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    ".MuiTableCell-root": {
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D9D9D9",
    },
  }));
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected && setSelected(newSelected);
      return;
    }
    setSelected && setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    name: string
  ) => {
  

  
    if (!isSingleCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: any = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected && setSelected(newSelected);
    } else {
      const value = name !== selected ? name : "";
      setSelected && setSelected(value);
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0;

  const visibleRows = React.useMemo(() => rows, [rows]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          className="table-container"
          sx={{
            maxHeight: maxHeightValue
              ? maxHeightValue
              : matchDownMD
              ? "calc(100vh - 400px)"
              : "calc(100vh - 330px)",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: width ? width : 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order || "asc"}
              orderBy={orderBy || ""}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length ?? 0}
              headCells={headCells}
              isSingleCheck={isSingleCheck}
              isSelectable={isSelectable || false}
            />
            <TableBody>
              {visibleRows.map((row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    onClick={(event) =>
                      isSelectable
                        && handleClick(event, row.id)
                        
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer", border: "1" }}
                  >
                    {isSelectable && (
                      <TableCell padding="checkbox" sx={{ border: "1" }}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        
                        />
                      </TableCell>
                    )}
                    {headCells.map((head: any, i: number) => (
                      <TableCell
                        key={`table-cell-td-${i}${row.id}`}
                        align="left"
                        sx={{ border: "1" }}
                      >
                        <TableCellMemo
                          row={row}
                          head={head}
                          i={i}
                          isMail={isMail}
                          resendMail={resendMail}
                          actionFunction={actionFunction}
                          isEdit={isEdit}
                          isView={isView}
                          isreInvite={isreInvite}
                          isKyc={isKyc}
                          isDelete={isDelete}
                          isMoreVert={isMoreVert}
                          isActionButton={isActionButton}
                          Openmodal={Openmodal}
                          setActive={setActive}
                          redoKyc={redoKyc}
                          reInvite={reInvite}
                          isExport={isExport}
                        />
                      </TableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
              {!visibleRows?.length && !isLoading && isNoRecordFound && (
                <TableRow>
                  <TableCell
                    colSpan={
                      isSelectable ? headCells.length + 1 : headCells.length
                    }
                    align="center"
                  >
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isPagination && (
          <PaginationComponent
            onChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            rowsPerPageOptions={[5, 10, 25]}
            hasShowing={true}
          />
        )}
      </Paper>
    </Box>
  );
}
