import { useLayoutEffect, useState } from "react";

// helpers
const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.substr(1);

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;

const useScrollspy = (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const positions = ids.map((id) => {
        const element = document.getElementById(id);

        if (!element) return { id, top: -1, bottom: -1 };

        const rect = element.getBoundingClientRect();
        const top = clamp(rect.top + scroll - offset);
        const bottom = clamp(rect.bottom + scroll - offset);

        return { id, top, bottom };
      });

      // Find the first element that matches the scroll position
      const activePosition = positions.find(({ top, bottom }) =>
        isBetween(scroll, top, bottom)
      );

      // If no element is in view, keep the last activeId

      if (!activePosition) return;

      setActiveId(activePosition.id);
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

export default useScrollspy;
