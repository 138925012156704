import { Box, Grid } from "@mui/material";
import { ContainedButton } from "component/button";
import PaginationComponent from "component/pagination";
import CandidateCard from "forms/hoc/candidate-card";

const CandidateList = ({
  candidatesList,
  handleSelectJob,
  handleChangePage,
  paginationData,
}: {
  candidatesList: any;
  handleSelectJob: (id: string) => void;
  handleChangePage: (event: any, newPage: any) => void;
  paginationData: any;
}) => {
  return (
    <Box my={{ sm: 3 }}>
      <Grid container spacing={3}>
        {candidatesList.map((candidate: any) => {
          return (
            <Grid item xs={12} sm={4}>
              <CandidateCard
                candidate={candidate}
                handleCandidateClick={() => {
                  handleSelectJob(candidate?._id);
                }}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} display={"flex"} justifyContent={"end"}>
          <PaginationComponent
            page={paginationData.page}
            rowsPerPage={paginationData.perPage}
            total={paginationData.total}
            onChange={($e: any, newPage: any) => {
              handleChangePage($e, newPage);
            }}
          />
        </Grid>
      </Grid>
      {/* <Box display={{ xs: "block", sm: "none" }} my={3} textAlign={"center"}>
        <ContainedButton size="large" label="Load More" />
      </Box> */}
    </Box>
  );
};

export default CandidateList;
