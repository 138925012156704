import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Modal,
  styled,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as LinkRouter } from "react-router-dom";
import FormLayout from "component/form";
import { InputField, PhoneField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { PasswordSchema } from "forms/hooks/validateRules";
import { useState } from "react";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";


const SetNewPassword = ({
  handlePasswordSubmit,
  isResetPassword,
}: {
  handlePasswordSubmit?: (password: string) => void;
  isResetPassword?: boolean;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [modalopen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const initialValues = {
    password: null,
    confirmPassword: null,
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 2,
  };

  const handleSubmitPassword = () => {
    
    if (handlePasswordSubmit) {
      handlePasswordSubmit(values.password);
    }

   
  };

  const { errors, values, handleChange, handleSubmit, setUpdateValue } =
    useForm(initialValues, handleSubmitPassword, PasswordSchema);

  const handleClose = () => setModalOpen(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ minWidth: "250px", maxWidth: "430px" }}>
      <Typography
        sx={{
          fontWeight: "600",
          display: "flex",
          justifyContent: "center",
          fontSize: { md: "20px", lg: "24px", sm: "20px", xs: "20px" },
        }}
      >
        {isResetPassword ? "Reset password" : "Set new password"}
      </Typography>

      <FormLayout
        handleSubmit={handleSubmit}
        submitButtonText={"Login"}
        isLogin={false}
      >
        <Grid
          container
          rowSpacing={1}
          sx={{
            mt: "10px",
          }}
        >
          <Grid item xs={12} lg={12}>
            <InputField
              fieldName="New password"
              values={values?.password || ""}
              name="password"
              errors={errors?.password}
              handleChange={handleChange}
              id="password"
              adorament="endAdornment"
              Icon={
                showPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )
              }
              position="end"
              type={showPassword ? "text" : "password"}
              handleIconClick={handleClickShowPassword}
              isRequired={false}
            />
            {error && (
              <Typography pt={2} color={"red"}>
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputField
              fieldName="Confirm new password"
              values={values?.confirmPassword || ""}
              name="confirmPassword"
              errors={errors?.confirmPassword}
              handleChange={handleChange}
              id="confirmPassword"
              adorament="endAdornment"
              Icon={
                showConfirmPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )
              }
              position="end"
              type={showConfirmPassword ? "text" : "password"}
              handleIconClick={() =>
                setShowConfirmPassword(!showConfirmPassword)
              }
              isRequired={false}
            />
            {error && (
              <Typography pt={2} color={"red"}>
                {error}
              </Typography>
            )}
          </Grid>
        
          <Grid item xs={12} lg={12}>
            <Typography sx={{ fontWeight: "600", mt: "10px" }}>
              Password should have at least:
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "10px" }}>
              8-15 characters
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "10px" }}>
              1 Alphabet + 1 Numeric digit
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "10px" }}>
              1 Lower + Upper case
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "10px" }}>
              One special character !@#$%^&*()_{" "}
            </Typography>
          </Grid>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "20px",
            }}
            type="submit"
          >
            Submit
          </Button>
         
        </Grid>
      </FormLayout>
    </Box>
  );
};

export default SetNewPassword;
