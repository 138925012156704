import { useEffect, useState } from "react";
import {
  Accordion,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MoreFilters = ({
  topCompanies,
  handleTopComaniesChange,
  handlePostedChange,
  selectedCompanies,
  selectedPosted,
  hasTopCompanies,
}: {
  topCompanies?: any[];
  handleTopComaniesChange: (values: any) => void;
  handlePostedChange: (values: any) => void;
  selectedCompanies: any;
  selectedPosted: any;
  hasTopCompanies: boolean;
}) => {
  const theme = useTheme();
  const [checked, setChecked] = useState<any>([]);
  const [postedChecked, setPostedChecked] = useState<any>([]);
  const [showMore, setShowMore] = useState(false);
  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    if (selectedCompanies?.length > 0) {
      setChecked([...selectedCompanies]);
    }
    if (selectedPosted?.length > 0) {
      setPostedChecked([...selectedPosted]);
    }
    setCompanies(topCompanies?.slice(0, 5));
  }, []);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    handleTopComaniesChange(newChecked);
  };
  const handlePostedToggle = (value: any) => () => {
    const currentIndex = postedChecked.indexOf(value);
    const newChecked = [...postedChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setPostedChecked(newChecked);
    handlePostedChange(newChecked);
  };
  return (
    <Box p={1} minWidth={230} maxWidth={350}>
      <Box p={1} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h4">
          Filter ({checked?.length + postedChecked?.length})
        </Typography>
        <Typography
          variant="h5"
          onClick={() => {
            setChecked([]);
            setPostedChecked([]);
            handleTopComaniesChange([]);
            handlePostedChange([]);
          }}
          sx={{ color: theme?.palette?.primary?.textOrange, cursor: "pointer" }}
        >
          Clear
        </Typography>
      </Box>
      <Divider />
      <Box mt={1} sx={{ maxHeight: "200px", overflowY: "auto" }}>
        {hasTopCompanies && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#AB5B0B" }} />}
              sx={{
                display: "flex",
                alignItems: "center",
                variant: "h5",
                fontWeight: 600,
                color: theme.palette.primary.textBtn,
                "&.Mui-expanded": {
                  minHeight: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "8px 0",
                },
              }}
            >
              Top Companies
            </AccordionSummary>
            <AccordionDetails
              sx={{
                py: 0,
                variant: "h4",
                fontWeight: 500,
                color: theme.palette.primary.textMid,
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  py: 0,
                }}
              >
                {companies?.map((company: any) => {
                  const labelId = `checkbox-list-label-${company?.value}`;

                  return (
                    <ListItem key={company?.value} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(company?.value)}
                        dense
                        sx={{ py: 0.5 }}
                      >
                        <ListItemIcon sx={{ minWidth: "20px" }}>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(company?.value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                            sx={{ p: 0 }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography
                              sx={{
                                fontSize: ".875rem",
                                fontWeight: 500,
                                color: "#15171E",
                              }}
                            >
                              {company?.name}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {!showMore && (
                <Box
                  sx={{
                    cursor: "pointer",
                    color: theme?.palette?.primary?.textOrange,
                    fontSize: ".875rem",
                    fontWeight: 500,
                    my: 2,
                  }}
                  onClick={() => {
                    setShowMore(true);
                    setCompanies([...topCompanies!]);
                  }}
                >
                  Show more
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          defaultExpanded
          sx={{
            "&.MuiAccordion-root::before": {
              opacity: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#AB5B0B" }} />}
            sx={{
              display: "flex",
              alignItems: "center",
              variant: "h5",
              fontWeight: 600,
              color: theme.palette.primary.textBtn,
              "&.Mui-expanded": {
                minHeight: 0,
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "8px 0",
              },
            }}
          >
            Date Posted
          </AccordionSummary>
          <AccordionDetails
            sx={{
              py: 0,
              variant: "h4",
              fontWeight: 500,
              color: theme.palette.primary.textMid,
            }}
          >
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                py: 0,
              }}
            >
              <ListItem key={"last-24-hours"} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handlePostedToggle("last-24-hours")}
                  dense
                  sx={{ py: 0.5 }}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <Checkbox
                      edge="start"
                      checked={postedChecked.indexOf("last-24-hours") !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": "last-24-hours" }}
                      sx={{ p: 0 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={"last-24-hours"}
                    primary={
                      <Typography
                        sx={{
                          fontSize: ".875rem",
                          fontWeight: 500,
                          color: "#15171E",
                        }}
                      >
                        Last 24 hours
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem key={"last-7-days"} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handlePostedToggle("last-7-days")}
                  dense
                  sx={{ py: 0.5 }}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <Checkbox
                      edge="start"
                      checked={postedChecked.indexOf("last-7-days") !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": "last-7-days" }}
                      sx={{ p: 0 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={"last-7-days"}
                    primary={
                      <Typography
                        sx={{
                          fontSize: ".875rem",
                          fontWeight: 500,
                          color: "#15171E",
                        }}
                      >
                        Last 7 days
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default MoreFilters;
