import { useState, useEffect, useCallback } from "react";
let thisError: any = {};
interface setSTateType {
  [name: string]: any;
}
const useForm = (
  initialValues: setSTateType = {},
  callback: Function,
  validate: any
) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(thisError);
  let [isSubmitting, setIsSubmitting] = useState(false);
  let [isSubmit, setIsSubmit] = useState(false);
  let [isFocus, setIsFocus] = useState(false);
  
  useEffect(() => {
    if (isSubmit) {
      if (Object.keys(errors).length === 0 && isSubmitting) callback();
    }
    setIsSubmit(false);
  
  }, [errors]);

  useEffect(() => {
    setIsSubmit(false);
    onChangeValidation();
  }, [values]);

  const onChangeValidation = () => {
    if (isSubmitting) {
      setErrors(false);
      if (validate === undefined) setErrors({});
      if (validate === null) setErrors({});
      if (validate !== undefined && validate) setErrors(validate(values));
    }
  };

  const handleSubmit = useCallback(
    (e: any) => {
      if (e) e.preventDefault();

      setValues((values) => ({
        ...values,
      }));
      setIsSubmit(true);
      setIsSubmitting(true);
      setErrors(false);
      setIsFocus(true);
      if (validate === undefined) setErrors({});
      if (validate === null) setErrors({});
      if (validate !== undefined && validate) setErrors(validate(values));
    },
    [values]
  );

  const resetForm = useCallback(() => {
    setIsSubmit(false);
    setIsSubmitting(false);
    setErrors({});
    setValues((values) => ({ ...initialValues }));
  }, []);

  const setUpdateValue = useCallback((field: string, value: any) => {
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
  }, []);

  const setPatchValue = useCallback((value: any) => {
    setValues((values) => ({
      ...values,
      ...value,
    }));
  }, []);

  const handleChange = useCallback((e: any) => {
  
    let text = e.target.value;
    if (e.target.name === "description")
      setValues((values) => ({
        ...values,
        [e.target.name]:
          text?.length <= 500 ? text : text.toString().slice(0, 500),
      }));
    else if (e.target.name === "title")
      setValues((values) => ({
        ...values,
        [e.target.name]:
          text?.length <= 100 ? text : text.toString().slice(0, 100),
      }));
    else
      setValues((values) => ({
        ...values,
        [e.target.name]: text,
      }));
  }, []);

  const handlePercentageNumberChange = useCallback((e: any) => {
    e.persist();
    if (isNaN(e.target.value)) return;

    if (e.target.value === "")
      setValues((values) => ({
        ...values,
        [e.target.name]: null,
      }));
    else
      setValues((values) => ({
        ...values,
        [e.target.name]:
          e.target.value?.length <= 5
            ? e.target.value
            : e.target.value.toString().slice(0, 5),
      }));
  }, []);

  const handleChangeReview = useCallback(
    (i: number, input: any) => (e: any) => {
 
      let all_involved = [...values.all_involved];
      if (input === "injury_level") {
        all_involved[i][input][e.target.name] = e.target.checked;
      } else {
        all_involved[i][input] = e.target.value;
      }
      setValues((values) => ({ ...values, all_involved: [...all_involved] }));
    },
    []
  );

  const handleChangeUpdateArray = useCallback(
    (i: number, input: any, date: any) => {
  
      let all_involved = [...values.all_involved];
      all_involved[i][input] = date;
      setValues((values) => ({ ...values, all_involved: [...all_involved] }));
    },
    []
  );

  const handlePushInvolved = useCallback((data: any) => {
    try {
      let all_involved = values.all_involved;
      all_involved.push(data);
      setValues((values) => ({ ...values, all_involved: [...all_involved] }));
    } catch (err) {
    
    }
  }, []);

  const handleRemoveInvolved = useCallback((i: number) => {
    try {
      let all_involved = values.all_involved;
      all_involved.splice(i, 1);
      setValues((values) => ({ ...values, all_involved: [...all_involved] }));
    } catch (err) {
    
    }
  }, []);

  const handleChangeSetEmptyVehicle = useCallback(() => {
    setValues((values) => (values = { involvedVehicle: [] }));
  }, []);

  const handleChangePerson = useCallback(
    (i: number, input: any) => (e: any) => {
  
      let val = values?.involvedVehicle;
      val[i][input] = e.target.value;
      setValues((values) => ({ involvedVehicle: [...val] }));
    },
    []
  );

  const handleChangeUpdatePerson = useCallback(
    (i: number, input: any, val: any) => {
   
      let involvedVehicle = [...values.involvedVehicle];
      involvedVehicle[i][input] = val;
      setValues((values) => ({
        ...values,
        involvedVehicle: [...involvedVehicle],
      }));
    },
    []
  );
  

  const handleChangePushVehicle = useCallback((value: any) => {
    let involvedVehicle = values.involvedVehicle;
    involvedVehicle.push(value);
    setValues((values) => ({
      ...values,
      involvedVehicle: [...involvedVehicle],
    }));
  }, []);

  const handleRemoveInvolvedVehicle = useCallback((i: number) => {
    try {
      let involvedVehicle = values.involvedVehicle;
      involvedVehicle.splice(i, 1);
      setValues((values) => ({
        ...values,
        involvedVehicle: [...involvedVehicle],
      }));
    } catch (err) {
    
    }
  }, []);

  const handleChangeParty = useCallback((i: number) => {
    let involvedVehicle = [...values.involvedVehicle];
    involvedVehicle[i].vehicle_involved_license_number = "";
    involvedVehicle[i].vehicle_involved_license_state = null;
    involvedVehicle[i].vehicle_involved_driver_name = "";
    involvedVehicle[i].vehicle_involved_driver_dob = "";
    involvedVehicle[i].vehicle_involved_driver_gender = "";
    involvedVehicle[i].vehicle_involved_driver_address = "";
    involvedVehicle[i].vehicle_involved_driver_apt = "";
    involvedVehicle[i].vehicle_involved_driver_city = "";
    involvedVehicle[i].vehicle_involved_driver_state = null;
    involvedVehicle[i].vehicle_involved_driver_zipcode = "";
    involvedVehicle[i].vehicle_involved_number_of_people_in_vehicle = "";
    involvedVehicle[i].vehicle_involved_public_property_damaged = false;
    involvedVehicle[i].vehicle2_name = "";
    involvedVehicle[i].vehicle2_dob = "";
    involvedVehicle[i].vehicle2_gender = "";
    involvedVehicle[i].vehicle2_address = "";
    involvedVehicle[i].vehicle2_apt_number = "";
    involvedVehicle[i].vehicle2_city = "";
    involvedVehicle[i].vehicle2_state = null;
    involvedVehicle[i].vehicle2_zip_code = "";
    involvedVehicle[i].vehicle2_ins_code = "";
    involvedVehicle[i].vehicle2_plate_number = "";
    involvedVehicle[i].vehicle2_state_of_reg = null;
    involvedVehicle[i].vehicle2_vehicle_year_make = "";
    involvedVehicle[i].vehicle2_vehicle_type = "";
    involvedVehicle[i].vehicle_involved_driver_dob_month = "";
    involvedVehicle[i].vehicle_involved_driver_dob_day = "";
    involvedVehicle[i].vehicle_involved_driver_dob_year = "";
    involvedVehicle[i].vehicle2_dob_date = "";
    involvedVehicle[i].vehicle2_dob_month = "";
    involvedVehicle[i].vehicle2_dob_year = "";

    involvedVehicle[i].accident_location = null;
    involvedVehicle[i].accident_action = null;
    involvedVehicle[i].vehicle2_second_event = null;
    involvedVehicle[i].vehicle2_estimation = "";
    involvedVehicle[i].vehicle2_damage_description = "";
    involvedVehicle[i].vehicle2_travel_direction = null;
    involvedVehicle[i].vehicle2_action = null;

    setValues((values) => ({
      ...values,
      involvedVehicle: [...involvedVehicle],
    }));
  }, []);

  return {
    setUpdateValue,
    setPatchValue,
    handlePercentageNumberChange,
    handleChangeReview,
    handlePushInvolved,
    handleRemoveInvolved,
    handleRemoveInvolvedVehicle,
    handleChangeSetEmptyVehicle,
    handleChangeUpdateArray,
    handleChangePushVehicle,
    handleChangeUpdatePerson,
    handleChangePerson,
    handleChangeParty,
    handleSubmit,
    handleChange,
    setIsFocus,
    resetForm,
    isFocus,
    values,
    isSubmitting,
    errors,
  };
};

export default useForm;
