import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import LogoutIcon from "@mui/icons-material/Logout";
import Groups2Icon from '@mui/icons-material/Groups2';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { IconSteeringWheel, IconIdBadge } from '@tabler/icons';
import LockResetIcon from '@mui/icons-material/LockReset';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// header menu icons 
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
// driver
import HistoryIcon from '@mui/icons-material/History';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const   Assets: any = {
  people: PeopleOutlineIcon,
  description: DescriptionOutlinedIcon,
  draft: DraftsOutlinedIcon,
  gridView: GridViewIcon,
  manageSearch: ManageSearchIcon,
  settings: LockResetIcon,
  profile: AccountCircleOutlinedIcon,
  person: PersonOutlineIcon,
  group: Groups2Icon,
  directionsCar:DirectionsCarIcon,
  logout: LogoutIcon,
  wheel: IconSteeringWheel,
  chat:ChatOutlinedIcon,
  accidentform: ListAltIcon,

  dashboard: DashboardOutlinedIcon,
  userPerson:PeopleOutlineOutlinedIcon,
  masterPerson:Person4OutlinedIcon,
  userReport:TextSnippetOutlinedIcon,

  representative:GroupAddOutlinedIcon,
  location:PinDropOutlinedIcon,
  ticketType:ConfirmationNumberOutlinedIcon,
  service:SettingsOutlinedIcon,

  history: HistoryIcon,
  stickyNote2:StickyNote2Icon,
  helpOutline: HelpOutlineIcon,
  EditNote: EditNoteIcon,
  ListBulleted: FormatListBulletedIcon,
  PersonOutlined: PersonOutlineOutlinedIcon,
  driversIcon:GroupOutlinedIcon,
  vehicleowner:IconIdBadge
};

export default Assets;