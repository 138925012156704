import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { InputField } from "forms/hoc/formfield";
import { ContainedButton, OutlinedButton } from "component/button";
import { DeactivateAccountValidation } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";

const DeactivateAccount = ({
  deactivateAccountOpen,
  handleDeactivateAccountClose,
  handleDeactivateAccountSubmit,
}: {
  deactivateAccountOpen: boolean;
  handleDeactivateAccountClose: () => void;
  handleDeactivateAccountSubmit: (id: string) => void;
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    reason: null,
    feedback_content: null,
  };
  const handleSubmitReason = () => {
    const payload = {
      ...values,
      action: "deactive",
    };
    commonService
      .postService(`candidate/deactivate`, payload)
      .then(async (response) => {
        setIsLoading(false);
        resetForm();
      
        handleDeactivateAccountSubmit(response?.data?.data?._id);
        handleDeactivateAccountClose();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    handleSubmit,
    setPatchValue,
    resetForm,
  } = useForm(initialValues, handleSubmitReason, DeactivateAccountValidation);


  return (
    <Box sx={{ mt: 2 }}>
      <Dialog open={deactivateAccountOpen}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle
            variant="h3"
            sx={{
              textAlign: "center",
              flexGrow: 1,
              color: theme.palette.primary.textBtn,
            }}
          >
            De-activate account
          </DialogTitle>
          <IconButton
            onClick={handleDeactivateAccountClose}
            sx={{
              fontSize: "20px",
              color: theme.palette.primary.iconOrange,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="h5" color={theme.palette.primary.textMid}>
              Everyone needs a break once in a while. De-activating account is
              the best option if you’d like to temporarily hide your account
              from ekam profile.
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.primary.textBtn}
              marginTop={2}
            >
              Tell us why you want to De-activate your account
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <FormControl>
                <RadioGroup
                  name="reason"
                  value={values?.reason}
                  onChange={($e) => {
                    setUpdateValue("reason", $e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="I need a break"
                    control={<Radio />}
                    label="I need a break"
                  />
                  <FormControlLabel
                    value="I got a job through Ekam"
                    control={<Radio />}
                    label="I got a job through Ekam"
                  />
                  <FormControlLabel
                    value="I got a job from other sources"
                    control={<Radio />}
                    label="I got a job from other sources"
                  />
                  <FormControlLabel
                    value="I'm not getting the right jobs"
                    control={<Radio />}
                    label="I'm not getting the right jobs"
                  />
                  <FormControlLabel
                    value="others"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {errors?.reason}
                </FormHelperText>
              </FormControl>
            </Box>
            <Typography
              variant="h4"
              color={theme.palette.primary.textBtn}
              marginTop={1}
              marginBottom={2}
            >
              We value your feed back, please write us below if you have any
              queries
            </Typography>
            <InputField
              values={values?.feedback_content}
              handleChange={handleChange}
              name="feedback_content"
              errors={errors?.feedback_content}
              multiline={true}
              minRow={3}
              helperText={`${values?.feedback_content?.length || "0"}/150`}
              length={150}
              isRequired={false}
              id="feedback_content"
            />
          </DialogContent>
          <DialogActions>
            <Box display={"flex"} justifyContent={"end"} mb={2} marginRight={2}>
              <OutlinedButton
                size={"small"}
                label={"cancel"}
                isBold={true}
                handleClick={() => {
                  resetForm();
                  handleDeactivateAccountClose();
                }}
              />
              <Box sx={{ mx: 1 }} />
              <ContainedButton
                size="large"
                isBold={true}
                label={"De-activate"}
                type="submit"
              />
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default DeactivateAccount;
