import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import BlogCalendar from "asset/images/blog-calendar.svg";
import BlogEye from "asset/images/blog-eye.svg";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import parse from "html-react-parser";
import Loader from "ui-component/Loader";
import useScrollspy from "forms/hooks/useScrollspy";
import PlaceholderImage from "asset/images/no-image-available.png";

const BlogDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [blogDetails, setBlogDetails] = useState<any>(null);
  const [activeSection, setActiveSection] = useState<string>(
    "Overview of technical Interview"
  );
  const [ids, setIds] = useState([]);
  const [sections, setSections] = useState([]);
  const [parsedContent, setParsedConent] = useState();
  const activeId = useScrollspy(ids, 150);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getBlogDetails();
    }
  }, []);

  const getBlogDetails = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}posts/${id}?_embed`)
      .then((res) => res.json())
      .then((response: any) => {
        setIsLoading(false);
        setBlogDetails(response);
        const htmlContent: any = parse(response?.content?.rendered || "");
        const newContent = htmlContent?.map((elem: any) => {
          if (elem?.type === "h1") {
            return {
              ...elem,
              props: {
                ...elem?.props,
                id: elem?.props?.children?.split(" ")?.join("-"),
              },
            };
          }
          return elem;
        });

        setParsedConent(newContent);
        const titles: any = htmlContent?.filter((element: any) => {
          return element?.type === "h1";
        });
        const ids = titles?.map((title: any) =>
          title?.props?.children?.split(" ")?.join("-")
        );
       
        const sec = titles?.map((title: any) => {
          return {
            id: title?.props?.children?.split(" ")?.join("-"),
            label: title?.props?.children,
          };
        });
        setIds(ids);
        setSections(sec);
     
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

 

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ pt: 2, display: { sm: "block", xs: "none" } }}
      >
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/blogs");
          }}
        >
          Blogs
        </Link>
        <Typography variant="h5" color={theme.palette.primary.textLight}>
          {parse(blogDetails?.title?.rendered || "")}
        </Typography>
      </Breadcrumbs>
      <Typography
        variant="h2"
        color={theme.palette.primary.textBtn}
        marginTop={2}
      >
        {parse(blogDetails?.title?.rendered || "")}
      </Typography>
      <Box display="flex" alignItems="center" mt={1} flexWrap="wrap">
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: { xs: "100%", sm: "auto" } }}
        >
          <Typography>
            By <b>{blogDetails?._embedded?.author[0]?.name}</b>
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "none", sm: "block" },
              marginLeft: 1,
              marginRight: 1,
            }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          mt={{ xs: 1, sm: 0 }}
          sx={{ width: { xs: "100%", sm: "auto" } }}
        >
          <Box display="flex" alignItems="center">
            <img src={BlogCalendar} alt="Calendar" />
            <Typography
              variant="h4"
              color={theme.palette.primary.textGrey}
              marginLeft={1}
            >
              {formatDateSring(blogDetails?.date)}
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "block", sm: "block" },
              marginLeft: 2,
              marginRight: 1,
            }}
          />

          <Box display="flex" alignItems="center" marginLeft={{ xs: 2, sm: 0 }}>
            <img src={BlogEye} alt="Eye" />
            <Typography
              variant="h4"
              color={theme.palette.primary.textGrey}
              marginLeft={1}
            >
              {blogDetails?.reading_time}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxHeight: "400px",
          overflowY: "hidden",
          borderRadius: "10px",
          marginTop: 3,
        }}
      >
        <img
          src={blogDetails?.fimg_url || PlaceholderImage}
          alt="BlogDetails"
          style={{ width: "100%" }}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        {sections?.length > 0 && (
          <Box
            display={{ xs: "none", sm: "block" }}
            sx={{
              width: "384px",
              height: "100%",
              position: "sticky",
              top: "120px",
              mb: 3,
            }}
          >
            <Box sx={{ display: "flex", mt: 3 }}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "#d3d7df",
                  minHeight: "150px",

                  borderRightWidth: 2,
                  xs: "none",
                  sm: "block",
                }}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                width={"300px"}
              >
                {sections?.map((sec: any) => {
                  return (
                    <Box
                      component={"a"}
                      href={`#${sec?.id}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                    >
                      <Box
                        sx={{
                          height: "4px",
                          width: "24px",
                          backgroundColor: "#AB5B0B",
                          mr: "1rem",
                          visibility:
                            activeSection === sec?.id || activeId === sec?.id
                              ? "visible"
                              : "hidden",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontSize:
                            activeSection === sec?.id || activeId === sec?.id
                              ? "18px"
                              : "14px",
                          fontWeight: "600",
                          color:
                            activeSection === sec?.id || activeId === sec?.id
                              ? "#e07d24"
                              : "#616B73",
                          xs: "none",
                          sm: "block",
                        }}
                      
                      >
                        {sec?.label}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}

        <Box
          flex={1}
          sx={{
            mt: 2,
          }}
        >
          <Box
            sx={{
              pre: {
                width: "300px",
                whiteSpace: "pre-wrap !important",
                wordWrap: "break-word !important",
                textAlign: "justify !important",
              },
            }}
          >
            {parsedContent || ""}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogDetails;

const formatDateSring = (dateString: any) => {
  const date = new Date(dateString);

  // Options for toLocaleString
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit", // Include day with 2 digits
  };

  return date.toLocaleString("en-US", options);
};
