import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import ProfileImage from "asset/images/accounts.svg";
import { InputField } from "forms/hoc/formfield";
import { ContainedButton, OutlinedButton } from "component/button";
import useForm from "forms/hooks/useForm";
import { ChangePasswordValidation } from "forms/hooks/validateRules";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";

const ChangePassword = ({
  passwordOpen,
  handlePasswordClose,
  handlePasswordChange,
}: {
  passwordOpen: boolean;
  handlePasswordClose: () => void;
  handlePasswordChange: () => void;
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);
  const user = useAppSelector((state) => state?.user?.user);

  const initialValues = {
    currentPassword: null,
    newPassword: null,
    reEnterPassword: null,
  };

  const handleSubmitPassword = () => {
    setIsLoading(true);
    const param = {
      currentPassword: values?.currentPassword,
      newPassword: values?.newPassword,
    };
    commonService
      .putService(`candidate/password`, param)
      .then(async (response) => {
        setIsLoading(false);
        Success(response?.data?.message);
        resetForm();
        handlePasswordClose();
        handlePasswordChange();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowReEnterPassword = () => {
    setShowReEnterPassword(!showReEnterPassword);
  };

  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    handleSubmit,
    setPatchValue,
    resetForm,
  } = useForm(initialValues, handleSubmitPassword, ChangePasswordValidation);

  return (
    <>
      {isLoading && <Loader />}
      <Box sx={{ mt: 2 }}>
        <Dialog
          open={passwordOpen}
          onClose={() => {
            setShowCurrentPassword(false);
            setShowReEnterPassword(false);
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <DialogTitle
              variant="h3"
              sx={{
                textAlign: "center",
                flexGrow: 1,
                color: theme.palette.primary.textBtn,
              }}
            >
              Change Password
            </DialogTitle>
            <IconButton
              onClick={() => {
                resetForm();
                setShowCurrentPassword(false);
                setShowReEnterPassword(false);
                handlePasswordClose();
              }}
              sx={{
                fontSize: "20px",
                color: theme.palette.primary.iconOrange,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Box sx={{ mt: 1.25 }}>
                <InputField
                  fieldName="Current Password"
                  values={values?.currentPassword || ""}
                  name="currentPassword"
                  errors={errors?.currentPassword}
                  handleChange={handleChange}
                  id="currentPassword"
                  adorament="endAdornment"
                  Icon={
                    showCurrentPassword ? <Visibility /> : <VisibilityOff />
                  }
                  position="end"
                  type={showCurrentPassword ? "text" : "password"}
                  handleIconClick={handleClickShowCurrentPassword}
                  isRequired={false}
                />
              </Box>
              <Box sx={{ mt: 1.25 }}>
                <InputField
                  fieldName="New Password"
                  values={values?.newPassword}
                  handleChange={handleChange}
                  name="newPassword"
                  errors={errors?.newPassword}
                  isRequired={false}
                  id="newPassword"
                />
              </Box>
              <Box sx={{ mt: 1.25 }}>
                <InputField
                  fieldName="Re-enter password"
                  values={values?.reEnterPassword || ""}
                  name="reEnterPassword"
                  errors={errors?.reEnterPassword}
                  handleChange={handleChange}
                  id="reEnterPassword"
                  adorament="endAdornment"
                  Icon={
                    showReEnterPassword ? <Visibility /> : <VisibilityOff />
                  }
                  position="end"
                  type={showReEnterPassword ? "text" : "password"}
                  handleIconClick={handleClickShowReEnterPassword}
                  isRequired={false}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mb={2}
                marginRight={2}
              >
                <OutlinedButton
                  size={"small"}
                  label={"cancel"}
                  isBold={true}
                  handleClick={() => {
                    setShowCurrentPassword(false);
                    setShowReEnterPassword(false);
                    resetForm();
                    handlePasswordClose();
                  }}
                />
                <Box sx={{ mx: 1 }} />
                <ContainedButton
                  size="small"
                  isBold={true}
                  label={"Update"}
                  type="submit"
                />
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default ChangePassword;
