import axios from "axios";
import SweetAlertComponent from "component/HOC/Alert";
import { Failed } from "forms/helpers/toast.helper";

import { clearStore } from "utils/redux.utils";

const mainAxios = process.env.REACT_APP_END_URL;
const wordpressAxios = process.env.WORDPRESS_APP_URL;


axios.defaults.baseURL = mainAxios;
export const IsBaseURL = (setCommonCode = true, URLName: any = "dw") => {
  axios.defaults.baseURL = mainAxios;
};

export const changeBaseUrl = (isChange: boolean) => {
  if (isChange) {
    axios.defaults.baseURL = process.env.REACT_APP_WORDPRESS_URL;
  }
};

export const SetAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

const onRequestFulfilled = async (config: any) => {
  let token = localStorage.getItem("ekam-token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return Promise.resolve(config);
};

const onRequestRejected = (error: any) => {
  return Promise.reject(error);
};

const onResponseFulfilled = (config: any) => {
  return Promise.resolve(config);
};

const onResponseRejected = (error: any) => {
  if (error?.response?.status === 401) {
    let token = localStorage.getItem("ekam-token");

    if (token) {
      sessionStorage.clear();
      localStorage.clear();
      clearStore();
      Failed("Session Expired!");
    } else {
      Failed("Unauthorized User!");
    }

    setTimeout(() => {
      window.location.href = "/candidate/home";
    }, 500);
  }

  if (error?.response?.status === 701) {
    // Maximum plan limit reached status
    SweetAlertComponent({
      status: 400,
      title: "Maximum plan limit reached!",
      message: error?.response?.data?.message,
      allowOutsideClick: true,
      // confirmButtonText: "Subscribe Now",
      // onSuccess: () => {
      //   window.location.href = "/employer/subscription-plans";
      // },
    });
  }
  return Promise.reject(error);
};

export const resetSession = () => {
  sessionStorage.removeItem("ekam-auth");
};
