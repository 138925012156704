import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import ProfileImage from "asset/images/accounts.svg";
import { InputField } from "forms/hoc/formfield";
import { ContainedButton, OutlinedButton } from "component/button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { clearStore } from "utils/redux.utils";
import { useNavigate } from "react-router-dom";

const AccountConfirmation = ({
  type,
  actionId,
  accountConfirmationOpen,
  handleAccountConfirmationClose,
}: {
  type: string;
  accountConfirmationOpen: boolean;
  actionId: string;
  handleAccountConfirmationClose: () => void;
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const user = useAppSelector((state) => state?.user?.user);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleChange = () => {};
  const logout = () => {
    clearStore();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/candidate/login");
  };

  const handleDeactivationSubmit = () => {
    setIsSubmit(true);
    if (password) {
      commonService
        .postService(`candidate/deactivation/${actionId}`, {
          password: password,
        })
        .then(async (response) => {
          setIsLoading(false);
          setPassword("");
          setIsSubmit(false);
          handleAccountConfirmationClose();
          logout();
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(
            errorMessage(error, "Something went wrong, Please try again later")
          );
        });
    }
  };

  const handleRevertDeactivation = () => {
    commonService
      .deleteService(`candidate/deactivation/${actionId}`)
      .then(async (response) => {
        setIsLoading(false);
        setPassword("");
        handleAccountConfirmationClose();
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Dialog open={accountConfirmationOpen}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle
            variant="h3"
            sx={{
              textAlign: "center",
              flexGrow: 1,
              color: theme.palette.primary.textBtn,
            }}
          >
            {type === "delete" ? "Delete" : "De-activate"} account
          </DialogTitle>
          <IconButton
            onClick={handleRevertDeactivation}
            sx={{
              fontSize: "20px",
              color: theme.palette.primary.iconOrange,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography variant="h5" color={theme.palette.primary.textMid}>
            {type === "delete" ? "Deleting" : "De-activating"} your account
            means you'll not have access to your profile and any mentions of you
            will not be present on our site.
          </Typography>
          <Box display="flex" alignItems="center" marginTop={2}>
            <img
              src={ProfileImage}
              alt="Account image"
              height={"48px"}
              width={"48px"}
              style={{ marginRight: "1rem" }}
            />
            <Typography
              variant="subtitle2"
              color={theme.palette.primary.textBtn}
            >
              {user?.name}
            </Typography>
          </Box>
          <Typography
            variant="h4"
            color={theme.palette.primary.textBtn}
            marginTop={2}
          >
            For security please enter your password
          </Typography>

          <InputField
            fieldName="Password"
            values={password}
            name="password"
            errors={!password && isSubmit ? "Password is required" : ""}
            handleChange={($e) => {
              setPassword($e.target.value);
            }}
            id="password"
            adorament="endAdornment"
            placeholder="Enter password"
            Icon={showPassword ? <Visibility /> : <VisibilityOff />}
            position="end"
            type={showPassword ? "text" : "password"}
            handleIconClick={() => {
              setShowPassword(!showPassword);
            }}
            isRequired={false}
          />
        </DialogContent>
        <DialogActions>
          <Box display={"flex"} justifyContent={"end"} mb={2} marginRight={2}>
            <OutlinedButton
              size={"small"}
              label={"cancel"}
              isBold={true}
              handleClick={handleRevertDeactivation}
            />
            <Box sx={{ mx: 1 }} />
            <ContainedButton
              size="large"
              isBold={true}
              label={type === "delete" ? "Delete" : "De-activate"}
              type="submit"
              handleClick={() => {
                handleDeactivationSubmit();
              }}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountConfirmation;
