import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as FaceSad } from "asset/icons/face-sad.svg";
import { ReactComponent as FaceFrown } from "asset/icons/face-frown.svg";
import { ReactComponent as FaceNeutral } from "asset/icons/face-neutral.svg";
import { ReactComponent as FaceSmile } from "asset/icons/face-smile.svg";
import { ReactComponent as FaceHappy } from "asset/icons/face-happy.svg";
import { InputField } from "forms/hoc/formfield";
import CloseIcon from "@mui/icons-material/Close";
import { FeedbackValidation } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { useLocation } from "react-router-dom";

const Feedback = ({
  open,
  userType,
  handleClose,
}: {
  open: boolean;
  userType: string;
  handleClose: () => void;
}) => {
  const [modalopen, setModalOpen] = useState(false);
  const [selectedFace, setSelectedFace] = useState<number | null>(null);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingError, setError] = useState<any>();
  const path = useLocation();
  let pathname = path?.pathname?.split("/")[1];
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleFaceClick = (index: number) => {
    setSelectedFace(index);
    setUpdateValue("rating", index + 1);
  };

  const handleNumberClick = (number: number) => {
    setSelectedNumber(number);
    setUpdateValue("recommend", number.toString());
  };
  const faces = [
    { src: FaceSad, label: "Poor" },
    { src: FaceFrown, label: "Unhappy" },
    { src: FaceNeutral, label: "Apathy" },
    { src: FaceSmile, label: "Happy" },
    { src: FaceHappy, label: "Love" },
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: matchDownXS ? 300 : matchDownMD ? 320 : 500,
    maxWidth: matchDownMD ? "80%" : "100%",
    paddingright: "14px",
    overflow: "hidden",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    maxHeight: "95vh",
    overflowY: "auto",
  };

  const feedbackValues = {
    rating: null,
    comment: null,
    recommend: null,
  };
  const handleSubmitFeedback = () => {
    setIsLoading(true);

    const payload: any = {
      rating: values.rating,
      comment: values.comment,
      recommend: values.recommend,
    };
    commonService
      .postService(
        userType === "employer" ? `recruiter/feedback` : `candidate/feedback`,
        payload
      )
      .then((response) => {
        setIsLoading(false);
        Success(response?.data?.message);
        resetForm();
        setSelectedFace(null);
        setSelectedNumber(null);
        handleClose();
      })
      .catch((error) => {
        setIsLoading(false);
      
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    resetForm,
    handleSubmit,
  } = useForm(feedbackValues, handleSubmitFeedback, FeedbackValidation);
 

  const resetFormState = () => {
    resetForm();
  };
  
  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <IconButton
            sx={{
              position: "absolute",
              right: "2px",
              top: "1px",
            }}
            onClick={() => {
              resetForm();
              setSelectedFace(null);
              setSelectedNumber(null);
              handleClose();
            }}
          >
            <CloseIcon
              sx={{
                fontSize: "20px",
                color: theme.palette.primary.iconOrange,
              }}
            />
          </IconButton>
          <Box>
            <Box
              sx={{
                marginTop: "1rem",
                backgroundColor: " #F2F2F2",
                padding: 0,
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  Your Feedback is important to us
                </Typography>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  color: theme?.palette?.primary?.textBtn,
                  padding: "0 1rem 1rem 1rem",
                }}
              >
                Your feedback is important to us. We carefully review and take
                every piece of feedback into account
              </Typography>
            </Box>
            <form onSubmit={handleSubmitFeedback}>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    color: theme?.palette?.primary?.textBtn,
                    marginTop: "3rem",
                  }}
                >
                  How would you rate your overall experience with us?
                </Typography>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <RadioGroup
                    name="rating"
                    value={selectedFace}
                    onChange={(e) => handleFaceClick(parseInt(e.target.value))}
                    row
                  >
                    {faces.map((face, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={
                          <Radio
                            icon={<face.src />}
                            checkedIcon={
                              <face.src style={{ fill: "#FFD700" }} />
                            }
                            checked={selectedFace === index}
                          />
                        }
                        label={face.label}
                        labelPlacement="bottom"
                        onClick={() => handleFaceClick(index)}
                      />
                    ))}
                  </RadioGroup>
                </Box>
                <FormHelperText sx={{ color: "red" }}>
                  {errors.rating}
                </FormHelperText>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 500, marginTop: "2rem" }}
                >
                  Would you like to share something with us?
                </Typography>
                <Box mt={1}>
                  <InputField
                    values={values?.comment}
                    name="comment"
                    errors={errors?.comment}
                    handleChange={handleChange}
                    multiline={true}
                    minRow={3}
                    helperText={`${values?.comment?.length || "0"}/200`}
                    length={150}
                    isRequired={false}
                    id="comment"
                  />
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 500, marginTop: "2rem" }}
                >
                  How likely are you to recommend Ekam to other{" "}
                  {userType === "candidate" ? "veterans" : "companies"}?
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    mt: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {Array.from({ length: 11 }, (_, number) => (
                    <Typography
                      key={number}
                      sx={{
                        borderRadius: "100px",
                        border: "1px solid #D0D5DD",
                        padding: "10px 15px",
                        cursor: "pointer",
                        backgroundColor:
                          selectedNumber === number ? "#FFD700" : "transparent",
                      }}
                      onClick={() => handleNumberClick(number)}
                    >
                      {number}
                    </Typography>
                  ))}
                </Box>
                <FormHelperText sx={{ color: "red" }}>
                  {errors.recommend}
                </FormHelperText>
              </Box>

              <Box
                sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}
              >
                <Typography sx={{ color: theme?.palette?.primary?.textBtn }}>
                  0 - Extremly Unlikely
                </Typography>
                <Typography sx={{ color: theme?.palette?.primary?.textBtn }}>
                  10 - Extremly likely
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "100%" }} />
              <Box
                sx={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 3,
                  mb: "0.65rem",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  type="button"
                  onClick={() => {
                    resetForm();
                    setSelectedFace(null);
                    setSelectedNumber(null);
                    handleClose();
                  }}
                  sx={{
                    background: "transparent",
                    border: "1px solid #FF7E00 ",
                    padding: "14px 20px 14px 20px",
                    width: "180px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{ padding: "14px 20px 14px 20px", width: "180px" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Feedback;
