import { createTheme, ThemeOptions } from '@mui/material/styles';

// assets
import colors from '../scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization: any) => {
	const color = colors;

	const themeOption = {
		colors: color,
		heading: color.grey900,
		paper: color.paper,
		main_div:color.main_div,
		backgroundDefault: color.paper,
		background: color.primaryLight,
		darkTextPrimary: color.grey700,
		darkTextSecondary: color.grey500,
		textDark: color.grey900,
		menuSelected: color.sideMenuSelectIconColor,
		menuSelectedBack: color.sideMenuSelectIconColorBack,
		inputBoxBgColor: color.inputBoxBgColor,
		divider: color.grey200,
		customization,
		iconColor:color.primary700,
		headingTextColor:color.headingText
	};

	const themeOptions = {
		direction: 'ltr',
		palette: themePalette(themeOption),
		mixins: {
			toolbar: {
				minHeight: '0px',
				padding: '16px',
				'@media (min-width: 600px)': {
					minHeight: '0px'
				}
			}
		},
		typography: themeTypography(themeOption)
	} as ThemeOptions;

	const themes = createTheme(themeOptions);
	themes.components = componentStyleOverrides(themeOption);

	return themes;
};


export default theme;
