import JobsIcon from "asset/images/all-jobs.svg";
import ExploreEmployerIcon from "asset/images/explore-employer.svg";
import DashboardIcon from "asset/images/candidate-dashboard.svg";
import AccountIcon from "asset/icons/account_active.svg";


import MessagingIcon from "asset/icons/messaging.svg";
import SavedJobsIcon from "asset/icons/saved.svg";
import AppliedJobsIcon from "asset/icons/experience-color.svg";
import JobAlertIcon from "asset/icons/job-alert.svg";
import SettingsIcon from "asset/icons/settings.svg";
import ReferIcon from "asset/icons/refer.svg";
import LogoutIcon from "asset/icons/logout-circle.svg";
import Feedback from "asset/icons/feedback.svg";

const Menu = [
  {
    id: `dashboard`,
    title: `Dashboard`,
    type: `group`,
    children: [
      {
        id: `home`,
        title: `Home`,
        type: `item`,
        url: `/candidate/home`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [`/candidate/home`],
      },
      {
        id: `candidate`,
        title: `Candidate`,
        type: `item`,
        url: ``,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [
          "/candidate/all-jobs",
          "/candidate/explore-employers",
          "/candidate/dashboard",
          "/candidate/job-details",
        ],
        children: [
          {
            id: "all-jobs",
            title: "All Jobs",
            type: "child",
            url: "/candidate/all-jobs",
            icon: JobsIcon,
            breadcrumbs: false,
          },
          {
            id: "explore-employer",
            title: "Explore Employer",
            type: "child",
            url: "/candidate/explore-employers",
            icon: ExploreEmployerIcon,
            breadcrumbs: false,
          },
          {
            id: "candidate-dashboard",
            title: "Candidate Dashboard",
            type: "child",
            url: "/candidate/dashboard",
            icon: DashboardIcon,
            breadcrumbs: false,
          },
        ],
      },
      {
        id: `contactus`,
        title: `Contact us`,
        type: `item`,
        url: `/contact-us`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [],
      },
    ],
  },
];

export default Menu;

export const CandidateProfileMenu = [
  {
    id: "my-profile",
    title: "My profile",
    type: "child",
    url: "/candidate/profile",
    icon: AccountIcon,
    breadcrumbs: false,
  },
  {
    id: "messaging",
    title: "Messaging",
    type: "child",
    url: "/candidate/chat",
    icon: MessagingIcon,
    breadcrumbs: false,
  },
  {
    id: "saved-jobs",
    title: "Saved jobs",
    type: "child",
    url: "/candidate/saved-jobs",
    icon: SavedJobsIcon,
    breadcrumbs: false,
  },
  {
    id: "applied-jobs",
    title: "Applied jobs",
    type: "child",
    url: "/candidate/applied-jobs",
    icon: AppliedJobsIcon,
    breadcrumbs: false,
  },
  // {
  //   id: "job-alert",
  //   title: "Job alert",
  //   type: "child",
  //   url: "/candidate/profile",
  //   icon: JobAlertIcon,
  //   breadcrumbs: false,
  //   hasDivider: true,
  // },
  {
    id: "settings",
    title: "Settings",
    type: "child",
    url: "/candidate/settings",
    icon: SettingsIcon,
    breadcrumbs: false,
  },
  {
    id: "feedback",
    title: "Feedback",
    type: "child",
    url: "/feedback",
    icon: Feedback,
    breadcrumbs: false,
  },
  {
    id: "refer-veteran",
    title: "Refer a veteran",
    type: "child",
    url: "/candidate/refer-veteran",
    icon: ReferIcon,
    breadcrumbs: false,
    hasDivider: true,
  },

  {
    id: "logout",
    title: "Logout",
    type: "child",
    url: "",
    icon: LogoutIcon,
    breadcrumbs: false,
  },
];
