import { Box, Typography, useTheme } from "@mui/material";
import NoDataFoundImage from "asset/images/no-search-results.svg";

const NoJobs = () => {
  const theme = useTheme();
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" my={3}>
        <img src={NoDataFoundImage} alt="No Data Found" />
        <Typography
          variant="subtitle1"
          fontWeight={600}
          color={theme?.palette?.primary?.textBtn}
        >
          Oh! No jobs available
        </Typography>
      
      </Box>
    </>
  );
};

export default NoJobs;
