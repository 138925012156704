import {
  Box,
  Pagination,
  PaginationItem,
  TablePagination,
  useTheme,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

interface IPagination {
  page?: number;
  onChange?: any;
  rowsPerPage?: number;
  onRowsPerPageChange?: any;
  total?: number;
  rowsPerPageOptions?: any[];
  hasShowing?: boolean;
}

const PaginationComponent = (props: IPagination) => {
  const theme = useTheme();
  const {
    onChange,
    onRowsPerPageChange,
    page = 1,
    rowsPerPage = 10,
    total = 0,
    rowsPerPageOptions = [10, 20, 35, 50],
    hasShowing,
  } = props;
  const handleChange = (event: any, newPage: number) => {
    onChange(event, newPage - 1);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={{ md: "space-between" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      {hasShowing && (
        <TablePagination
          component="div"
          nextIconButtonProps={{ style: { display: "none" } }}
          backIconButtonProps={{ style: { display: "none" } }}
          count={total}
          page={page}
          labelRowsPerPage=""
          sx={{
            ".MuiTablePagination-toolbar": {
             
              pl: 0,
              pt: 1,
              pb: 1,
              color: "#191B1E",
              ".MuiTablePagination-displayedRows": {
                fontSize: "1rem !important",
              },
            },
            ".MuiTablePagination-input": {
              display: "none",
            },
          }}
          onPageChange={onChange}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={(paginationInfo) => {
            if (paginationInfo.count === 1) {
              return `Showing ${paginationInfo.to} out of ${paginationInfo.count} entities`;
            }
            return `Showing ${paginationInfo.to} out of ${paginationInfo.count} entities`;
          }}
        />
      )}

      <Pagination
        count={Math.ceil(total / rowsPerPage)}
        page={page === 0 ? 1 : page + 1}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: ArrowBackRoundedIcon,
              next: ArrowForwardRoundedIcon,
            }}
            {...item}
            sx={{
              px: 2,
              height: "42px",
              borderRadius: "32px",
              minWidth: "42px",
            }}
          />
        )}
        sx={{
          pt: 1,
          "& .MuiPaginationItem-root": {
            color: theme.palette.primary.textLight,
          },
          "& .MuiPaginationItem-root.Mui-selected": {
            color: theme.palette.primary.textBtn,
            backgroundColor: theme.palette.primary.backgroundOrangeMid,
            fontWeight: 500,
            fontSize: ".875rem",
           
          },
          "& .MuiPaginationItem-root.MuiPaginationItem-previousNext": {
            color: theme.palette.primary.iconOrange,
            boxShadow: "0px 8px 20px 0px #33414D1A",
          },
        }}
      />
    </Box>
  );
};

export default PaginationComponent;
