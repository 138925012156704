
import {
  Box,
  Divider,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const Sort = ({
  handleSortSelect,
  selectedSort,
}: {
  handleSortSelect: (type: string, value: string) => void;
  selectedSort: any;
}) => {

  return (
    <Box p={1} minWidth={150} maxWidth={350}>
      <Box p={1} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h4">Sort by</Typography>
      </Box>
      <Divider />
      <Box>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            cursor: "pointer",
          }}
        >
          <ListItem
            onClick={() => {
              handleSortSelect("sort", "relevant");
            }}
            sx={{ mt: 1, pl: 1 }}
            key={"relevant"}
            disablePadding
            selected={selectedSort === "relevant" ? true : false}
          >
            <ListItemText
              id={"relevant"}
              primary={
                <Typography variant="body2" fontWeight={500}>
                  Relevance
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            onClick={() => {
              handleSortSelect("sort", "created_at");
            }}
            sx={{ mt: 1, pl: 1 }}
            key={"created_at"}
            disablePadding
            selected={selectedSort === "created_at" ? true : false}
          >
            <ListItemText
              id={"created_at"}
              primary={
                <Typography variant="body2" fontWeight={500}>
                  Newly posted
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Sort;
